/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumotoDownloadManualJobResultVm } from '../../models/documoto-download-manual-job-result-vm';
import { ListJobsForOperationsPageRequestVm } from '../../models/list-jobs-for-operations-page-request-vm';

export interface ListDownloadManualsJob$Params {
      body?: ListJobsForOperationsPageRequestVm
}

export function listDownloadManualsJob(http: HttpClient, rootUrl: string, params?: ListDownloadManualsJob$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoDownloadManualJobResultVm>> {
  const rb = new RequestBuilder(rootUrl, listDownloadManualsJob.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumotoDownloadManualJobResultVm>;
    })
  );
}

listDownloadManualsJob.PATH = '/media/documoto/jobs/downloaded-manuals/search';
