<div>

  <mibp-loader *ngIf="isLoading"></mibp-loader>
  <mibp-system-message class="mt-5" #msg [messageType]="msg.Types.Error" *ngIf="hasLoadingError">
    <h2><mibp-resource-string key="General_Error_InlineTitle"></mibp-resource-string></h2>
  </mibp-system-message>
  <div class="no-items" *ngIf="!currentSearchResult?.totalCount && !isLoading && !hasLoadingError">
    <mibp-resource-string key="Global_NoHits"></mibp-resource-string>
  </div>

  <div class="items" *ngIf="!isLoading">
    @for (media of pageItems; track media.identifier) {
    <div class="item">
      <div class="item__image">
        <span translate="no" class="material-icon">library_books</span>
      </div>
      <div class="item__details">
        <h4>{{ media.name }}</h4>
        <table>
          <tbody>
            <tr>
              <th><mibp-resource-string key="Global_Type"></mibp-resource-string>:</th>
              <td>{{media.type}}</td>
            </tr>
            <tr>
              <th><mibp-resource-string key="Global_MachineSerialNumber"></mibp-resource-string>:</th>
              <td><a [routerLink]="media.equipmentRoute">{{media.equipmentName}}</a></td>
            </tr>
            <tr>
              <th><mibp-resource-string key="ElectronicManuals_MachineModel"></mibp-resource-string>:</th>
              <td>{{media.modelName}}</td>
            </tr>
            <tr>
              <th><mibp-resource-string key="MyFleet_ProductGroup"></mibp-resource-string>:</th>
              <td>{{media.groupName}}</td>
            </tr>
            <tr>
              <th><mibp-resource-string key="MyFleet_ProductSubGroup"></mibp-resource-string>:</th>
              <td>{{media.subGroupName}}</td>
            </tr>
            <tr>
              <th><mibp-resource-string key="Global_ManualCategory"></mibp-resource-string>:</th>
              <td>
                @for (category of media.categories; track index; let i = $index) {
                {{category}}<br *ngIf="i < media.categories.length - 1">
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="item__actions">
        <button class="my-button my-button--secondary" (click)="viewManual(media.equipmentId,media.identifier)">View manual</button>
        <mibp-manual-download-button [equipmentId]="media.equipmentId" [downloadErrorCode]="media.downloadErrorCode"
          [downloadStatus]="media.downloadStatus" [downloadedManualId]="media.downloadedManualId"
          [fileSize]="media.fileSize" [fileType]="media.fileType" [mediaId]="media.mediaId" [mediaName]="media.name"
          [userDownloadedManualId]="media.userDownloadedManualId"></mibp-manual-download-button>

      </div>
    </div>
    }
  </div>
  <div class="pagination">
    <mibp-paginator *ngIf="currentSearchResult?.totalCount > 0" class="mibp-table-pagination"
      [startIndex]="currentSkipOption" [totalItems]="currentSearchResult.totalCount" [pageSize]="currentTakeOption"
      (changePage)="onChangePage($event)"> </mibp-paginator>
  </div>
