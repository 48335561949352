import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { BillOfMaterialApiController, MediaApiController } from 'root/mibp-openapi-gen/controllers';
import { MediaNavigationFolderVm, PartsCatalogueFolderType } from "root/mibp-openapi-gen/models";
import { MediaFolderVm } from './../../../mibp-openapi-gen/models/media-folder-vm';
import { ApiErrorHandlerService } from 'root/services/api-error-handler/api-error-handler';
import { GlobalConfigService, LogService, MibpLogger, NoticebarService } from "root/services";
import { NoticeType } from "root/components/noticebar/noticebar.enum";
import { firstValueFrom } from 'rxjs';
import { PartsManualsService } from "../parts-manual.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MibpNoWhitespaceValidator } from "root/validators/no-whitespace.validator";


interface ExtendedMediaNavigationFolderVm extends MediaNavigationFolderVm {
  isExpanded?: boolean;
  isVisible?: boolean;
  childFolders?: ExtendedMediaNavigationFolderVm[];
  isSearchHit?: boolean;
}

export interface PartsManualSearchData {
  query: string;
  foldersWithSearchHits: number[];
}

@Component({
  selector: 'mibp-parts-manual-treeview',
  styleUrls: ['./parts-manual-treeview.component.scss'],
  templateUrl: './parts-manual-treeview.component.html',
  providers: [PartsManualsService]
})
export class MibpPartsManualTreeviewComponent implements OnInit, OnChanges {

  @Input() mediaIdentifier?: string;
  @Input() selectedFolderId?: number;
  @Output() pageSelected = new EventEmitter<ExtendedMediaNavigationFolderVm[]>();
  @Input() initialFolderId?: number;
  @Output() searchCompleted = new EventEmitter<PartsManualSearchData>();

  folderTypes = PartsCatalogueFolderType;

  tree?: ExtendedMediaNavigationFolderVm[];
  public selectedPage: ExtendedMediaNavigationFolderVm;
  loadingSelectedFolder = false;
  isLoadingTree = false;
  searchPartsManualForm: FormGroup;  
  isSearchLoading = false;
  foldersWithSearchHits?: number[];
  locationCountMacro = 0;  
  searchError = false;
  mibpLogger: MibpLogger;
  enablePartsManualSearch: boolean;

  constructor(private bomApi: BillOfMaterialApiController,
    private mediaApi: MediaApiController,
    private errorHandler: ApiErrorHandlerService,
    private noticebarService: NoticebarService,
    private partsManualService: PartsManualsService,
    private router: Router, 
    private route: ActivatedRoute,
    private globalConfig: GlobalConfigService,
    logService: LogService) {
      this.mibpLogger = logService.withPrefix('parts-manual-treeview');
    }
    
  async ngOnInit(): Promise<void> {    
    this.enablePartsManualSearch = this.globalConfig.enablePartsManualDetailsSearch;

    this.searchPartsManualForm = new FormGroup({
      query: new FormControl<string>(null, {validators: [Validators.required, Validators.maxLength(1000), Validators.minLength(3), MibpNoWhitespaceValidator()]}),
    });

    await this.loadTree();

    if (this.enablePartsManualSearch && this.route.snapshot?.queryParams?.detailsSearchQuery 
      && this.route.snapshot?.queryParams?.detailsSearchQuery != '' && this.route.snapshot?.queryParams?.detailsSearchQuery.length > 2) {
      await this.executeSearch(this.route.snapshot.queryParams.detailsSearchQuery);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasInitialFolderIdChanged = !!changes.initialFolderId;
    const isFirstInitialFolderIdChange = hasInitialFolderIdChanged && changes.initialFolderId.firstChange;

    if (isFirstInitialFolderIdChange) {
      if (!changes.initialFolderId.currentValue && changes.initialFolderId.previousValue) {
        this.selectedPage = null; // Had a value, but it was cleared
      } else {
        if (changes.initialFolderId.currentValue != changes.initialFolderId.previousValue) {
          this.expandInitialFolder(); // Value changed and is not empty
        }
      }
    }
  }

  public async loadTree(): Promise<void> {
    this.isLoadingTree = true;

    try {
      const folders = await firstValueFrom(this.mediaApi.getMediaNavigationTree({
        mediaIdentifier: this.mediaIdentifier
      }));
      this.isLoadingTree = false;
      this.partsManualService.prepareFolders(folders);
      this.tree = folders;      
    } catch (err) {
      this.errorHandler
        .parseError(err)
        .else(() => this.noticebarService.showText('Could not load page', NoticeType.Error, false) );
    }
  }

   /***
   * Expand the initial folder so the treeview is in the correct state
   * when the page is first loading
   */
   private async expandInitialFolder(): Promise<void> {
    if (this.initialFolderId) {
      this.loadingSelectedFolder = true;

      const existsInTree = this.findFolderById(this.initialFolderId);

      const folderPath = existsInTree ? this.getFullFolderPathFromLoadedTree(existsInTree) : await this.getFolderPath();
      let foldersToLookIn = this.tree || [];

      for(const folder of folderPath) {
        const folderToExpand = foldersToLookIn.find(f => f.id == folder.id);
        if (folderToExpand) {
          if (folderToExpand.type == PartsCatalogueFolderType.Chapter && folder != folderPath[folderPath.length - 1]) {
            await this.toggleFolder(folderToExpand, true);
            foldersToLookIn = folderToExpand.childFolders;
          } else {
            this.selectedPage = folderToExpand;
          }
        }
      }

      this.loadingSelectedFolder = false;
    }
  }

  expandAll() {
    this.tree?.forEach(f => {      
      this.expandFolderAndSubFolders(f);
    });
  }

  collapseAll() {
    this.tree?.forEach(f => {
      this.collapseFolderAndSubFolders(f);
    });
  }

  expandFolderAndSubFolders(folder: ExtendedMediaNavigationFolderVm) {
    if (folder.childCount < 1) {
      return;
    }

    this.toggleFolder(folder, true);

    folder.childFolders.forEach(f => {
      if (f.childCount > 0) {
        this.expandFolderAndSubFolders(f);
      }
    });
  }

  collapseFolderAndSubFolders(folder: ExtendedMediaNavigationFolderVm, resetSearchHits = false) {
    if (resetSearchHits && folder.isSearchHit) {
      folder.isSearchHit = false;
    }

    if (folder.childCount < 1) {
      return;
    }

    folder.isExpanded = false;

    folder.childFolders.forEach(f => {
      f.isVisible = false;
      if (f.childCount > 0) {        
        this.collapseFolderAndSubFolders(f, resetSearchHits);
      } else if (resetSearchHits && f.isSearchHit) {
        f.isSearchHit = false;
      }
    });
  }

  resetSearchResult() {
    this.tree?.forEach(f => {
      this.collapseFolderAndSubFolders(f, true);
    });
  }

  ResetSearchResultRecursive(folder: ExtendedMediaNavigationFolderVm) {
    folder.isSearchHit = false;

    if (folder.childCount < 1 && folder.parentFolderId != null) {      
      return;
    }

    this.collapseFolder(folder);

    folder.childFolders.forEach(f => {
      if (folder.childCount > 0) {
        this.ResetSearchResultRecursive(f);
      }
    });
  }

  private async getFolderPath(): Promise<MediaFolderVm[]> {
    return new Promise((resolve, reject) => {
      this.bomApi.getFolderPath({
        folderId: this.initialFolderId,
        mediaIdentifier: this.mediaIdentifier
      }).subscribe({
        next: folderPath => {
          resolve(folderPath);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  private getFullFolderPathFromLoadedTree(folder: ExtendedMediaNavigationFolderVm): ExtendedMediaNavigationFolderVm[] {
    if (!folder) {
      return [];
    }

    const folders: ExtendedMediaNavigationFolderVm[] = [];
    
    do {
      folders.push( Object.assign({}, folder));

      if (folder.parentFolderId != null) {
        folder = Object.assign({}, this.findFolderById(folder.parentFolderId));
        //delete folder.children; // We don't need all the children for every node
      } else {
        folder = null;
      }

    } while (folder);

    folders.reverse();
    return folders;
  }

  // TODO: This function can probably be optimized
  private findFolderById(folderId: number, haystack: ExtendedMediaNavigationFolderVm[] = null, expandSearchHitPath = false): ExtendedMediaNavigationFolderVm {
    haystack = haystack || this.tree || [];

    for (const folder of haystack) {
      if (folder.id == folderId ) {
        if (expandSearchHitPath && folder.childCount > 0) {
          this.toggleFolder(folder, true);         
        }

        return folder;
      }
    }

    for (const folder of haystack) {
      if (folder.childFolders) {
        for (const child of folder.childFolders) {
          if (child.id == folderId) {
            if (expandSearchHitPath) {
              this.toggleFolder(folder, true);
              this.toggleFolder(child, true);
            }

            return child;
          }

          const matchingChild = this.findFolderById(folderId, child.childFolders || [], true);
          
          if (matchingChild) {
            if (expandSearchHitPath) {
              this.toggleFolder(folder, true);
              this.toggleFolder(child, true);              
            }

            return matchingChild;
          }
        }
      }
    }

    return null;
  }

  selectFolder(folder: ExtendedMediaNavigationFolderVm): void {
    this.selectedPage = folder;
    this.pageSelected.emit(this.getFullFolderPathFromLoadedTree(folder));
    this.toggleFolder(folder);
  }

  collapseFolder(folder: ExtendedMediaNavigationFolderVm) {
    folder.isExpanded = false;
    folder.childFolders?.every(cf => cf.isVisible = false);
  }

  toggleFolder(folder: ExtendedMediaNavigationFolderVm, doNotCollapse = false): Promise<ExtendedMediaNavigationFolderVm[]> {
    if (folder.isExpanded && !doNotCollapse) {
      folder.isExpanded = false;
      folder.childFolders?.every(cf => cf.isVisible = false);
    } else {
      folder.isExpanded = true;
      folder.childFolders?.every(cf => cf.isVisible = true);
    }

    return;
  }

  async submitSearchPartsManual() {
    if (!this.searchPartsManualForm.valid) {
      return;
    }

    await this.executeSearch(this.searchPartsManualForm.controls.query.value);
  }

  async executeSearch(query: string) {
    this.isSearchLoading = true;
    this.searchError = false;

    try {
      const result = await firstValueFrom(this.mediaApi.searchPartsManual(
        { 
          mediaIdentifier: this.mediaIdentifier, 
          query: query
        }));
          
        this.foldersWithSearchHits = result.foldersWithHits;
  
        this.resetSearchResult();
        this.foldersWithSearchHits.forEach(folderId => {
          let foundFolder = this.findFolderById(folderId, null, true);
          
          if (foundFolder) {
            foundFolder.isSearchHit = true;
          }
        });
  
        this.locationCountMacro = this.foldersWithSearchHits.length;
        this.searchCompleted.emit({query: query, foldersWithSearchHits: this.foldersWithSearchHits});
    } catch (error) {
      this.searchError = true;      
      this.mibpLogger.error('Error when searching parts manual', error);
    } finally {
      this.isSearchLoading = false;
    }
  }
}
