import { Component, OnDestroy, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BroadcastService, FrontendContextService, GlobalConfigService, LocalizationService, LogService, MibpLogger } from "root/services";
import { Subscription, firstValueFrom, skip } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MediaApiController } from "root/mibp-openapi-gen/controllers";
import { GetRelatedMediaResponseVm, GetRelatedMediaResponseVmPagedResponse} from "root/mibp-openapi-gen/models";
import { Page } from "root/components/paginator/page";

interface GetRelatedMediaResponseVmExtended extends GetRelatedMediaResponseVm {
  categoryString: string;
  equipmentRoute: string[];
}

@Component({
  selector: 'mibp-electronic-manual-related',
  templateUrl: './electronic-manual-related.component.html',
  styleUrl: './electronic-manual-related.component.scss',

})

export class MibpMyFleetElectronicManualRelatedComponent implements OnInit, OnDestroy, OnChanges {

  @Input() mediaId: number;

  [x: string]: any;
  private log: MibpLogger;
  public isLoading = false;
  private actAsSub: Subscription;

  protected currentTakeOption = 10;
  protected currentSkipOption = 0;

  hasLoadingError: boolean;
  currentSearchResult: GetRelatedMediaResponseVmPagedResponse;
  resultCountMacros: { totalCount: number; firstItemIndex: number; lastItemIndex: number; };
  pageItems: GetRelatedMediaResponseVmExtended[];

  constructor(
    protected globalConfig: GlobalConfigService,
    private mediaApi: MediaApiController,
    private router: Router,
    private broadcast: BroadcastService,  
    private route: ActivatedRoute,
    private localizationService: LocalizationService,
    private frontendContext : FrontendContextService,
    log: LogService) {
      this.log = log.withPrefix('electronic-manual-related-page');
    }

  ngOnInit(): void {
    this.actAsSub = this.broadcast.deliverySequence.pipe(skip(1)).subscribe(() => {
      this.currentSkipOption = 0;
      this.executeSearch('acting as');
    });

    this.executeSearch('initial');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mediaId && !changes.mediaId.firstChange && changes.mediaId.previousValue != changes.mediaId.currentValue) {
      this.currentSkipOption = 0;
      this.executeSearch('mediaId changed');
    }
  }

  ngOnDestroy(): void {
    this.actAsSub?.unsubscribe();
  }

  private async executeSearch(caller: string): Promise<void> {
    this.isLoading = true;
    this.hasLoadingError = false;

    this.log.info('execute related media Search', caller);

    const request = {
      skip: this.currentSkipOption,
      take: this.currentTakeOption,
      mediaId: this.mediaId
    };

    try {

      this.currentSearchResult = await firstValueFrom(this.mediaApi.getRelatedMedia({
        body: request
      }));
      this.isLoading = false;

      this.resultCountMacros = {
        totalCount: this.currentSearchResult.totalCount,
        firstItemIndex: this.currentSkipOption + 1,
        lastItemIndex: this.currentSkipOption + this.currentTakeOption > this.currentSearchResult.totalCount ? this.currentSearchResult.totalCount : this.currentSkipOption + this.currentTakeOption
      };

      this.pageItems = this.currentSearchResult.items.map<GetRelatedMediaResponseVmExtended>(media => {
        return {
         ...media,
          equipmentRoute: media.equipmentId ? ['/', this.localizationService.getLang(), 'myfleet', 'machines', media.equipmentId.toString()] : null,
          categoryString: media.categories.join(', ')
        };
      });
    } catch (e) {
      this.hasLoadingError = true;
      this.isLoading = false;
      this.log.error(`Error calling endpoint`, e);
    } finally {
      this.isLoading = false;
    }

  }

  viewManual(equipmentId: number, identifier: string) {
    this.router.navigateByUrl(this.frontendContext.Navigation.ensureUrlLanguage(`/myfleet/electronic-manuals/`+ equipmentId +`/`+ identifier));
  }

  protected onChangePage(page: Page): void {
    if (page.currentPage >= 0) {
      if (this.currentSkipOption != page.startIndex) {
        this.currentSkipOption = page.startIndex;
        this.router.navigate(['.'], {
          queryParams: {
            skip: this.currentSkipOption
          },
          queryParamsHandling: 'merge',
          relativeTo: this.route
        });
        this.executeSearch('changepage');
      }
    }
  }
}
