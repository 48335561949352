import { LoaderService } from './../../../../../../services/loader/loader.service';
import { GlobalConfigService } from './../../../../../../services/global-config/global-config.service';
import { InlineEditingOverlayService } from './../../../../../../services/inline-editing-overlay/inline-editing-overlay.service';
import { ActivatedRoute } from '@angular/router';
import { EventEmitter, OnDestroy, Output } from '@angular/core';
import {
  FrontendContextService, MibpLogger, LogService,BroadcastService,
  ApiService, NoticebarService, FormattingService, DialogService, LocalizationService,ClientSideCacheService, SignalR_ErrorInfo, CartUpdatedEvent, CartService
} from 'root/services';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NoticeType } from 'root/components/noticebar/noticebar.enum';
import { MibpNoWhitespaceValidator } from 'root/validators/no-whitespace.validator';
import { GoogleTagManagerService } from 'root/services/google-tag-manager-service/google-tag-manager.service';
import { allPermissionPolicies } from 'root/all-permission-policies';
import { NavigationService } from 'root/services';
import { ButtonColors } from 'root/components/button/button.enum';
import { PriceAndAvailabilityApiController } from './../../../../../../mibp-openapi-gen/services/price-and-availability-api-controller';
import { BusinessRelationsApiController, CartsApiController } from 'root/mibp-openapi-gen/controllers';
import { ActiveCartInformationVm, Cart, CartTotalPriceDataVm, Item, SessionDeliverySequenceViewModel } from 'root/mibp-openapi-gen/models';
import { Guid } from 'guid-typescript';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { MySandvikFeatures } from 'root/services/permission';
import { MibpPaymentService } from 'root/services/mibp-payment/mibp-payment-service';

export interface CartTotalsLoadedEvent {
  hasErrors: boolean;
  value: CartTotalPriceDataVm;
}

@Component({
  selector: 'mibp-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss'],
})
export class CartDetailsComponent implements OnInit, OnDestroy {

  @Input() cart: ActiveCartInformationVm;
  @Input() properties= true;
  @Input() iscXMLAddressPunchout= false;
  @Input() disableCheckoutButton = false;
  @Output() showErrorsClick = new EventEmitter<boolean>(false);
  @Output() cartTotalsLoaded = new EventEmitter<CartTotalsLoadedEvent>();
  @Output() cartTotalsLoading = new EventEmitter();
  @Output() cartDeletedEvent = new EventEmitter<boolean>(true);
  @ViewChild("cartNameElement", { read: ElementRef }) cartNameElement: ElementRef;
  //@Input() validateBeforeSubmit: () => void;
  nameForm: UntypedFormGroup;
  log: MibpLogger;
  totalPrice: string;
  totalWeight: number;
  priceAndAvailabilityDone = false;
  isEditing = false;
  unchangedCartName: string = null;
  itemCount?: number;
  hasCreateCartPermission: boolean;
  hasPunchOutPermission: boolean;
  hasPriceEnquiryPermission: boolean;
  hasExportCartPermission: boolean;
  hasTemplatesPermission: boolean;
  priceIsDone = false;
  availabilityIsDone = false;
  exportUrl: string;
  punchOutHtml: string;
  priceMissing = true;
  availabilityMissing = true;
  ReviewAndApproveWishList= false;
  priceAndAvailabilityPercent = 0;
  saveInProgress = false;
  activeDeliverySequence: SessionDeliverySequenceViewModel;
  nonAuroraExportCart = false;
  disableShowErrorsButton: boolean;
  errorProducts: Item[];
  errorCodes: number[];
  isLoading: boolean;
  CreditRemainingCount = 0;
  hasError = false;
  color=null;
  punchoutTransaction = false;
  hideCustomerCredit=false;
  showCustomerCreditForCompany= false;
  dlvSeqSub: Subscription;
  canCheckout: boolean;
  isLoadingTotalPrice = true;
  isLoadingTotalWeigth = true;
  hasCartTotalError = false;     // Can be that items has no prices etc
  unableToRetrievePrice = false;
  hasCartTotalException = false; // Backend returns exception
  productsWithoutPrices: string[];
  hasDecimalQuantityException = false;
  cartWeightAndPriceSub: Subscription;
  cartDeletedSuccessfully = false;

  protected isDeliverySequenceRequired = true;
  protected isAuroraCompany = false;
  constructor(private api: ApiService,
    private fb: UntypedFormBuilder,
    private formatService: FormattingService,
    private frontendContext: FrontendContextService,
    private noticeBarService: NoticebarService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private localizationService: LocalizationService,
    private inlineEditingOverlayService: InlineEditingOverlayService,
    private globalConfig: GlobalConfigService,
    private loader: LoaderService,
    private broadcast: BroadcastService,
    private googleTagManagerService: GoogleTagManagerService,
    private navService: NavigationService,
    private cacheService: ClientSideCacheService,
    private cartService : CartService,
    private priceAndAvailabilityApiController: PriceAndAvailabilityApiController,
    logger: LogService,
    private sessionService: MibpSessionService,
    private cartsController: CartsApiController,
    private businessRelationController: BusinessRelationsApiController,
    private mibpPaymentService: MibpPaymentService
  ) {
    this.log = logger.withPrefix('active-cart-sidebar');
    this.activeDeliverySequence = this.sessionService.activeDeliverySequence;
  }

  get isActingAs(): boolean {
    return !!this.activeDeliverySequence;
  }


  onDeleteClick(): void {
    this.dialogService.promptWithButtons('Carts_ConfirmDeleteCart_Text', [
      { resourceKey: 'Global_Dialog_No', id: 'cancel', color: ButtonColors.Secondary, automatedTestId: 'reject-delete-cart' },
      { resourceKey: 'Global_Dialog_Yes', id: 'del', color: ButtonColors.Primary, automatedTestId: 'confirm-delete-cart' }
    ], null, true).then(button => {
      if (button === 'del') {
        firstValueFrom(this.cartsController.deleteCart({id:this.cart.id.toString()})).then(() => {
          this.cartDeletedSuccessfully = true;
          this.cartDeletedEvent.emit(true);
          this.broadcast.setCartEvent({cartId: Guid.parse(this.cart.id), count: 0});
          this.noticeBarService.show('Carts_DeleteCart_Success', NoticeType.Success);
        }).catch(error => {
          this.log.error('Error deleting cart', error);
          this.cartDeletedSuccessfully = false;
          this.noticeBarService.show('Carts_DeleteCart_Error', NoticeType.Error);
        });
      }
    });
  }

  onShowErrors():void{
    this.showErrorsClick.emit(true);
  }

  onPunchOutClick(e: Event): void {


    if (!this.isDeliverySequenceRequired) {
      this.notImplementForPersistantCart();
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (!this.hasMissingPrices()) {

      this.isLoading = true;
      this.loader.showFullScreenLoader();
      this.punchoutTransaction = true;
      this.iscXMLAddressPunchout = this.cacheService.get(`iscXMLAddressPunchout`)?this.cacheService.get(`iscXMLAddressPunchout`):false;
      firstValueFrom(this.cartsController.punchout({iscXMLAddress: this.iscXMLAddressPunchout})).then((html) => {
        this.log.debug("PunchOut: Got FORM HTML");
        document.getElementById('punchOutHtml').innerHTML = html;
        this.log.debug("PunchOut: Added FORM HTML in DOM");
        this.postPunchOutForm().then(() => {
          // Are we supposed to do something after we have submitted the form?
          this.log.debug(`PunchOut: Form promise was resolved successfully but we are still in My Sandvik`);
          this.loader.hideFullScreenLoader();
        }, () => {
          this.loader.hideFullScreenLoader(); // comment out...
          this.punchoutTransaction = false;
          this.dialogService.prompt(`Carts_PunchOut_ErrorMissingForm`, 'Global_Dialog_Close');
        });
      },
      (err) => {
        this.isLoading = false;
        this.punchoutTransaction = false;
        this.loader.hideFullScreenLoader();
        this.log.error(`PunchOut: Unable to place punchout order`, err);
        if (err?.error?.errors?.find((e: SignalR_ErrorInfo) => e.code === 'ERRDPUN008')) {
          this.dialogService.prompt(`Carts_PunchOut_ErrorUserMissingConfig`, 'Global_Dialog_Close');
        } else if (err?.error?.errors?.find((e: SignalR_ErrorInfo) => e.code === 'ERRDPUN009')) {
          this.dialogService.prompt(`Carts_PunchOut_ErrorUserMissingCart`, 'Global_Dialog_Close');
        } else if (err?.error?.errors?.find((e: SignalR_ErrorInfo) => e.code === 'ERRDPUN010')) {
          this.dialogService.prompt(`text://Unable to place punchout order: Missing Delivery Sequence`, 'Global_Dialog_Close');
        } else if (err?.error?.errors?.find((e: SignalR_ErrorInfo) => e.code === 'ERRDPUN011')) {
          this.dialogService.prompt(`text://Unable to place punchout order: Missing User object`, 'Global_Dialog_Close');
        } else if (err?.error?.errors?.find((e: SignalR_ErrorInfo) => e.code === 'ERRDPUN012')) {
          this.dialogService.prompt(`text://Unable to place punchout order: Missing Connection object`, 'Global_Dialog_Close');
        } else if (err?.error?.errors?.find((e: SignalR_ErrorInfo) => e.code === 'ERRDPUN013')) {
          this.dialogService.prompt(`text://Unable to place punchout order: Missing Backend Context  object`, 'Global_Dialog_Close');
        }
        else if (err?.error?.error === 'General_Error_OrderQuantity_NoDecimalSupport') {
          this.dialogService.prompt('text://Some products does not support decimal values for Quantity', 'Global_Dialog_Close');
        } else {
          this.noticeBarService.showText("Unable to place punchout order", NoticeType.Error, false);
        }
      });
    }
  }

  private postPunchOutForm(): Promise<void> {
    const id = "punchoutform"; // punchout form id
    return new Promise((resolve, reject) => {

      this.log.debug(`[PunchOut] Waiting for generated form element`);
      this.waitForElement(`#${id}`).then((element: HTMLElement) => {
        this.log.debug(`[PunchOut] Submitting generated form`, element.outerHTML);
        const form = element as HTMLFormElement;
        form.submit();
        resolve();
      }, err => {
        reject(err);
      });
    });

  }

  private async waitForElement(selector: string, timeoutMs = 5000): Promise<HTMLElement> {

    return new Promise<HTMLElement>((resolve, reject) => {

      let element: HTMLElement;

      if (element != null) {
        return resolve(element as HTMLElement);
      }

      const tick = () => {

        element = document.querySelector(selector);

        if (element != null) {
          return resolve(element as HTMLElement);
        }

        // Element not found. Start timer again.
        const currentTime = new Date();
        if (currentTime.getTime() - started.getTime() >= timeoutMs) {
          reject();
        } else {
          setTimeout(tick, 150);
        }
      };

      // Start timer to find element
      const started = new Date();
      setTimeout(tick, 150);

    });

  }

  hasMissingPrices(): boolean {
    if(this.productsWithoutPrices&&this.productsWithoutPrices.length!==0){
      let htmlNotice = '';
      const max = 4;
      let take = this.productsWithoutPrices.length;
      let andMoreMessage = '';
      if (this.productsWithoutPrices.length >= (max + 3)) {
        take = max;
        andMoreMessage = 'and ' + (this.productsWithoutPrices.length - max) + ' more...';
      }
      htmlNotice += `<p>${this.localizationService.get('Cart_Warning_ErrorProducts')}</p><ul>`+
      // Not using resource strings here?
      this.productsWithoutPrices.map(item=> `<li>Item Code: ${item}</li>`).slice(0, take).join('') + andMoreMessage + '</ul></div>';
      this.noticeBarService.showText(`<div class="my-body mt-2">${htmlNotice}</div>`, NoticeType.Warning, true);
      return true;
    }
    else
    {
      return false;
    }
  }

  private notImplementForPersistantCart(): void {
    alert("Checkout not implemented for persistent card");
  }



  async onCheckoutClick():Promise<void>{

    if (!this.isDeliverySequenceRequired) {
      this.notImplementForPersistantCart();
      return;
    }

    //#region Ensure user has at least one payment method
    const isAllowedForInvoicePayment = await this.mibpPaymentService.isUserAllowedForInvoicePayment();
    const isAllowedForCardPayment = await this.mibpPaymentService.isUserAllowedForCardPayment();
    const isAllowedForRequestQuotation = this.frontendContext.testPermission({ features: [MySandvikFeatures.ShopRequestQuotation] });


    if (!isAllowedForInvoicePayment && !isAllowedForCardPayment && !isAllowedForRequestQuotation ) {
      this.noticeBarService.show('Checkout_Error_NoPaymentOptionDefined', NoticeType.Error);
      return;
    }
    //#endregion

    if (!this.hasMissingPrices()) {
      const hasPromotionProducts = await this.hasPromotionProductExceedingQuantity();
      if(!hasPromotionProducts){
        this.isLoading=false;
        //this.validateBeforeSubmit();
        //setTimeout(() => {
        //if(!this.hasDecimalQuantityException)
        //{
          this.navService.navigateTo('cart/checkout');
          this.googleTagManagerService.pushCustomEvent('Cart_Checkout');
        }
        //}, 2000);
      }
    }

  async hasPromotionProductExceedingQuantity() : Promise<boolean> {
    try {
      const products = await firstValueFrom(this.cartsController.getPromotionProductForActiveCartExceedingQuantity());
      if(products.length === 0){
        return false;
      }
      else{
        const max = 4;
        let take = products.length;
        let andMoreMessage = '';
        if (products.length >= (max + 3)) {
          take = max;
          andMoreMessage = 'and ' + (products.length - max) + ' more...';
        }
        const htmlNotice = `<p>${this.localizationService.get('Cart_Warning_QuantityExceeded')}</p><ul>`+
        // Not using resource strings here?
        products.map(item=> `<li>Item Code: ${item}</li>`).slice(0, take).join('') + andMoreMessage + '</ul></div>';
        this.noticeBarService.showText(`<div class="my-body mt-2">${htmlNotice}</div>`, NoticeType.Warning, true);
        return true;
      }
    }
    catch(error) {
      this.log.error('setAllFilterData', error);
      // Could not get statuses.
      return true;
    }
  }

  onCartUpdatedEvent(cartUpdated: CartUpdatedEvent): void {
    if (!cartUpdated) {
      return;
    }
    if(!this.cartDeletedSuccessfully)
      {
        this.refreshCartTotalWeight();
      }
    this.nonAuroraExportCart = (cartUpdated.count > 0 && !this.isAuroraCompany);
    if (this.isActingAs) {
      this.refreshCartTotalPrice();
    }
    this.itemCount = cartUpdated.count;

  }

  refreshCartTotalWeight(): void {
    this.isLoadingTotalWeigth = true;
    firstValueFrom(this.cartsController.getTotalCartWeight({ cartId: this.cart.id }))
      .then(totalWeight => {
        this.totalWeight = totalWeight;
      })
      .catch(error => {
        this.log.error("Total cart weight could not be calculated", error);
      }).finally(() => this.isLoadingTotalWeigth = false);

  }

  refreshCartTotalPrice(): void {

    if (!this.hasPriceEnquiryPermission) {
      this.cartTotalsLoaded.emit({
        value: null,
        hasErrors: false
      });
      return;
    }

    this.cartTotalsLoading.emit();

    this.isLoadingTotalPrice = true;
    this.hasCartTotalError = false;
    this.unableToRetrievePrice = false;



    if (this.frontendContext.testPermission(allPermissionPolicies.canSeePrices)) {
      firstValueFrom(this.priceAndAvailabilityApiController.getTotalCartPriceData({cartId: this.cart.id.toString()}))
        .then(cartTotals => {
          if(this.sessionService.activeDeliverySequence !== null){
            const currencyCode = this.sessionService.activeDeliverySequence.currencyCode || '';
            this.hasCartTotalError = cartTotals.errorCount > 0 ? true : false;
            this.unableToRetrievePrice = cartTotals.productsWithoutPrice.length > 0 ? true : false;
            this.productsWithoutPrices = cartTotals.productsWithoutPrice || [];
            this.totalPrice = `${(this.formatService.formatPrice(cartTotals.subtotal) || '0.0')} ${currencyCode}`; //this.formatService.formatPrice(x)  === '' ? '0.0' : this.formatService.formatPrice(x.) + ' ' + currencyCode;
            this.isLoadingTotalPrice = false;
            this.hasCartTotalException = false;
            this.cartTotalsLoaded.emit({
              value: cartTotals,
              hasErrors: cartTotals.errorCount > 0
            });
          }
        }).catch(e => {
          this.isLoadingTotalPrice = false;
          this.hasCartTotalError = true;
          this.hasCartTotalException = true;

          this.log.error(`Error fetching total cart price`, e);
          this.cartTotalsLoaded.emit({
            value: null,
            hasErrors: true
          });
        });
    }

  }

  ngOnInit(): void {

    this.isAuroraCompany = this.sessionService.isAuroraCompany();
    this.hasCreateCartPermission = this.sessionService.hasFeature(MySandvikFeatures.ShopCart);
    this.hasPunchOutPermission = this.isAuroraCompany && this.frontendContext.testPermission(allPermissionPolicies.canPunchOut);
    this.hasPriceEnquiryPermission = this.isAuroraCompany && this.frontendContext.testPermission(allPermissionPolicies.canSeePrices);
    const canCheckoutOrder = this.isAuroraCompany && this.frontendContext.testPermission(allPermissionPolicies.canCheckoutOrder);
    const canCheckoutQutation = this.isAuroraCompany && this.frontendContext.testPermission(allPermissionPolicies.canRequestQuote);
    this.canCheckout = canCheckoutOrder || canCheckoutQutation;
    this.hasExportCartPermission = this.frontendContext.testPermission(allPermissionPolicies.canExportCarts);
    this.hasTemplatesPermission = this.frontendContext.testPermission(allPermissionPolicies.canUseTemplates);
    this.isDeliverySequenceRequired = !this.sessionService.hasFeature(MySandvikFeatures.ShopPersistentCart);


    this.disableShowErrorsButton = this.globalConfig.disableShowProductError;
    this.hideCustomerCredit= this.globalConfig.hideCustomerCredit;
    this.dlvSeqSub = this.broadcast.deliverySequence.subscribe(d => {
      this.isDeliverySequenceRequired = !this.sessionService.hasFeature(MySandvikFeatures.ShopPersistentCart);
      const companycode=  this.globalConfig.showCustomerCreditForCompanies;
      companycode.find(x=>{
        if(x === d?.companyCode){
          return   this.showCustomerCreditForCompany = true;}
        else if(x !== d?.companyCode){
          return  this.showCustomerCreditForCompany = false;
        }});
    });

    this.nameForm = this.fb.group({
      name: [this.cart?.name, [Validators.required, MibpNoWhitespaceValidator()]]
    });


    this.totalPrice = '0'; // Here we will never have prices. It's loaded separatly
    this.broadcast.cartEvent.subscribe({
      next: cartUpdated => this.onCartUpdatedEvent(cartUpdated)
    });

    this.cartWeightAndPriceSub = this.cartService.refreshCartWeightAndPrice$.subscribe({
      next: isRefreshed => {
        if(isRefreshed)
        {
          this.refreshCartTotalWeight();
          this.refreshCartTotalPrice();
        }
      }
    });

    //creditlimit
    this.hasError = false;
    this.CreditRemainingCount = 0;

    firstValueFrom(this.businessRelationController.getCreditLimitCount()).then(creditLimitCount => {

      if(creditLimitCount.creditRemainingCount < 0){

        this.CreditRemainingCount = creditLimitCount.creditRemainingCount;
        this.color = '#616A6B';
      }

      else{

        if (creditLimitCount.creditUsedCount >= 0 && creditLimitCount.creditUsedCount <= 50 ) {
          this.color ='#008000';
          this.CreditRemainingCount = creditLimitCount.creditRemainingCount;
        }
        else if(creditLimitCount.creditUsedCount > 50 && creditLimitCount.creditUsedCount <= 75 )
        {
          this.color ='#FF6600';
          this.CreditRemainingCount = creditLimitCount.creditRemainingCount;

        }
        else if(creditLimitCount.creditUsedCount > 75 && creditLimitCount.creditUsedCount <= 100 )
        {
          this.color = '#F00000';
          this.CreditRemainingCount = creditLimitCount.creditRemainingCount;

        }
      }


    }).catch( error => {
      this.log.error('error fetching credit limit count', error);
      this.hasError = true;
    });
  }

  startEditName(): void {
    if (!this.hasCreateCartPermission) {
      return;
    }

    this.isEditing = true;
    setTimeout( () => {
      this.cartNameElement.nativeElement.focus();
      this.cartNameElement.nativeElement.select();
      this.inlineEditingOverlayService.show();
    }, 50);
  }

  nameElementFocus(): void {
    this.inlineEditingOverlayService.show();
    this.noticeBarService.hide();
  }

  nameElementBlur(): void {

    if (this.nameForm.value.name === this.unchangedCartName) {
      this.inlineEditingOverlayService.hide();
      this.isEditing = false;
      return;
    }

    this.inlineEditingOverlayService.hide();

    if (!this.nameForm.invalid) {
      this.saveInProgress = true;
      this.nameForm.controls.name.disable();
      const newName = this.nameForm.value.name;
      firstValueFrom(this.cartsController.rename({cartId:this.cart.id.toString(),name: newName}))
        .then(
          () => {
            this.nameForm.controls.name.enable();
            this.isEditing = false;
            this.cart.name = newName;
            this.unchangedCartName = newName;
            this.saveInProgress = false;
          }).catch(errorResponse => {
          this.log.error('error renaming cart', errorResponse);
          this.saveInProgress = false;
          if (errorResponse.stack.errorCode === "ERRBCRT002") {
            this.noticeBarService.showText(`"${newName}" ${this.localizationService.get('Carts_NameNotUnique')}`, NoticeType.Error, false);
            this.nameForm.controls.name.enable();
            this.nameForm.controls.name.setErrors({
              ERRBCRT002 : true
            });
          }
          if (errorResponse.stack.errorCode === "ERRBCRT000") {
            this.nameForm.controls.name.enable();
            this.nameForm.controls.name.setErrors({
              ERRBCRT000 : true
            });
          }
        });


    } else if ( (this.nameForm.value.name as string).trim().length === 0 ) {
      this.nameForm.controls.name.markAsUntouched();
      this.nameForm.controls.name.markAsPristine();
      this.nameForm.updateValueAndValidity();
      this.nameForm.controls.name.setValue(this.cart.name);
      this.isEditing = false;
    }
  }

  nameElementKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Tab' || e.key === 'Enter') {
      // This is to prevent the page from scrolling down
      // since we currently do not control tabIndex for elements
      e.preventDefault();
      e.stopPropagation();
      (e.target as HTMLInputElement).blur();
    } else if (e.key === 'Escape') {
      this.isEditing = false;
      this.nameForm.setValue({
        name: this.unchangedCartName
      });
    }
    // if (!this.nameForm.invalid) {
    //   this.isEditing = false;
    // }
  }


  ngOnDestroy(): void {
    this.dlvSeqSub?.unsubscribe();
    this.cartWeightAndPriceSub?.unsubscribe();
  }

  onDeleteCartHandler(): void {
    // this.cartService.deleteCart();
  }

  retryFetchingPrices(){
    this.cartService.refreshCartProductPrices();
  }

}
