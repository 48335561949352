
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention */
/************************************************************
 * 
 * THIS FILE IS AUTOMATICALLY GENERATED. DO NOT MODIFY.
 * 
 **************************************************************/

import { Injectable } from '@angular/core';
import { SignalRService } from '../signalr-service/signalr.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { toDate, formatISO, parseISO } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { format, addMinutes } from 'date-fns';
import { Guid } from 'guid-typescript';
import { TimeSpan } from 'root/types/timeSpan';
import * as generatedTypes from './_mibp-api-generated.service.types';
class ApiTypeMapper {
  private typeMapperData: any;
  private isArray: boolean;
  constructor(data: any, isArray: boolean) {
    this.typeMapperData = data;
    this.isArray = isArray;
  }
  static create(obj: any, type: string) {
    const isArray = obj instanceof Array;
    const mapperData = {type: type, obj: obj, properties: null, T: null, tuple: null};
    if (type.indexOf('<') !== -1) {
      let genericType = type.substr(type.indexOf('<') + 1);
      genericType = genericType.substr(0, genericType.length - 1);
      type = type.substring(0, type.indexOf('<'));
      mapperData.T = genericType;
    }
    if (type === 'ApiResponse') {
      mapperData.properties = [
        {"name": "errors", "type": "ErrorInfo", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'Sample') {
      mapperData.properties = [
        {"name": "date", "type": "Date", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'ApiResponseGeneric') {
      mapperData.properties = [
        {"name": "data", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "errors", "type": "ErrorInfo", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ApiResponseGeneric') {
      mapperData.properties = [
        {"name": "data", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "errors", "type": "ErrorInfo", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ApiResponseGeneric') {
      mapperData.properties = [
        {"name": "data", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "errors", "type": "ErrorInfo", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'SampleFindResult') {
      mapperData.properties = [
        {"name": "items", "type": "Sample", "isOptional": true, "isSpecial": false}
      ];
      mapperData.tuple = { "item1" : "totalCount", "item2" : "items" };
    }
    if (type === 'ApiResponseGeneric') {
      mapperData.properties = [
        {"name": "data", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "errors", "type": "ErrorInfo", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'CustomerLiveChatConfig') {
      mapperData.properties = [
        {"name": "scriptUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "mySiteUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "mySalesforceUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "organizationId", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "deploymentId", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "buttonId", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "liveAgentUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "liveAgentContentUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "liveAgentName", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "liveAgentDeveloperName", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfigValue') {
      mapperData.properties = [
        {"name": "configurationItem", "type": "ConfigurationItem", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfigValueGeneric') {
      mapperData.properties = [
        {"name": "value", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "configurationItem", "type": "ConfigurationItem", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfig') {
      mapperData.properties = [
        {"name": "warrantyPortal", "type": "GlobalWarrantyPortalConfig", "isOptional": false, "isSpecial": false},
        {"name": "customerLiveChat", "type": "CustomerLiveChatConfig", "isOptional": false, "isSpecial": false},
        {"name": "signalR", "type": "SignalRGlobalConfig", "isOptional": false, "isSpecial": false},
        {"name": "newProductSearch", "type": "NewProductsGlobalConfig", "isOptional": false, "isSpecial": false},
        {"name": "enablePartsManuals", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enablePartsManualsUserIds", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "enableChangeLog", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "useNewExceptionDialog", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "loadInternalUserPhoto", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableQuickAddOnCartPage", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableResourceServerSideCache", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableApiDocs", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "maxCartSize", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "disableShowProductError", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "activeEquipmentStatuses", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "inActiveEquipmentStatuses", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "onOrderEquipmentStatuses", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "decommisionedEquipmentStatuses", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "useB2CDocumoto", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "auroraBatchSize", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "useNewAddToCartDialog", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableCompressionOfLogFilterParameter", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "idleShowPopupAfterSeconds", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "idlePopupLogoutAfterSeconds", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "hideCustomerCredit", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "showCustomerCreditForCompanies", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "checkboxFacetRedIndicator", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "disableOnboardingTour", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableFleetDocuments", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableStockroom", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableStockroomCompanies", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "disableUpSell", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableUpSellOnCartItems", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableCrossSell", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableCrossSellOnCartItems", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "maxNumberOfTargetProducts", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "maxNumberOfRelatedProducts", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "disableBulletinsOld", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableBulletinsNew", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableToggleSideBar", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disablePromotions", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableMachineUpdateRequest", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableFeatureTourAfterDays", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "lastDeploymentDate", "type": "GlobalConfigValue<Date>", "isOptional": false, "isSpecial": false},
        {"name": "systemMessageDefaultImageId", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "dynatraceUserEmails", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "disableNewFeatureTour", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "mySandvikFeaturesEnabled", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableFeatureTestNotice", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "activateDynamicDashboard", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableDashboardDragDrop", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableSupportQueueTile", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableNewHelpSection", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableNewTermsAndConditions", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableDeployEvents", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableWarrantyApplication", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableMyFleetDetailsGeoLocation", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableMyFleetLocation", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableEngineHoursManualUpdate", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableProductDetails", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableAuroraOrderSyncProcess", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "syncAuroraOrderSkipInterval_Minutes", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "auroraSyncEnabledCompanies", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "enableSalesforceLiveChat", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "mailFileSizeLimitInBytes", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "disableKitSuggestions", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableTopEngineHoursTile", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "disableEngineHoursStatusTile", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableEquipmentRemoteSupport", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "showCustomerRemoteSupportForCompanies", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "hideMySandvikBulletinAddEdit", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "machineFiltersEnabled", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "richTextEditorImageUploadMaxSize", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "richTextEditorAllowedImageMimeTypes", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "supportedImageFileExtensions", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "mibpPaymentAdyenEnvironment", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "mibpPaymentAdyenCardHasHolderName", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "mibpPaymentAdyenCardHolderNameRequired", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "mibpPaymentAdyenCardBillingAddressRequired", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableClaimSoftDeleteMySandvik", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "googleMapsScriptKey", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "enableClaimsPrimeCausalPartValidation", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "environment", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "enableMyRockToolsMenu", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "myRockToolsMenuUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "smartMateMenuUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "enableEditAddressForCountries", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "enableEditAddressMapsLookup", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "systemMessagePollIntervalMinutes", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "maxNumberOfPromotionProducts", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "maxNumberOfPromotionResponsibilities", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "enableReportPartsManualSupportCase", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enablePopoutHelpPages", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "checkoutEnableContactDetails", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "sidebarHoverEnabled", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableNewContactUs", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "availabilityCacheDuration", "type": "GlobalConfigValue<TimeSpan>", "isOptional": false, "isSpecial": false},
        {"name": "enablePartsManualDescription", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "activeAddKitItemsToCartTypes", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "enableSupersededDataSyncStatus", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "supersededDataSyncStatusVisbleForUsers", "type": "GlobalConfigValue<string[]>", "isOptional": false, "isSpecial": false},
        {"name": "useProductBlobUrlsForProducts", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "documotoWidgetScriptUrl", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "enablePartsManualDownload", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "useOrderStatusFromExpectedDeliveryDate", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "productNotificationMaxNumberOfProductCodes", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "enablePartsManualDetailsSearch", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "ignoreProductAreaForMaintenancekitCalendar", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalWarrantyPortalConfig') {
      mapperData.properties = [
        {"name": "maxUploadBytes", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "disabled", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfigValueGeneric') {
      mapperData.properties = [
        {"name": "value", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "configurationItem", "type": "ConfigurationItem", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfigValueGeneric') {
      mapperData.properties = [
        {"name": "value", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "configurationItem", "type": "ConfigurationItem", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'SignalRGlobalConfig') {
      mapperData.properties = [
        {"name": "hubName", "type": "GlobalConfigValue<string>", "isOptional": false, "isSpecial": false},
        {"name": "reconnectAttempts", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "reconnectDelayMs", "type": "GlobalConfigValue<number>", "isOptional": false, "isSpecial": false},
        {"name": "skipNegotiation", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'NewProductsGlobalConfig') {
      mapperData.properties = [
        {"name": "replaceSearchPage", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableEquipmentFilter", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false},
        {"name": "enableActiveFilterList", "type": "GlobalConfigValue<boolean>", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfigValueGeneric') {
      mapperData.properties = [
        {"name": "value", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "configurationItem", "type": "ConfigurationItem", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfigValueGeneric') {
      mapperData.properties = [
        {"name": "value", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "configurationItem", "type": "ConfigurationItem", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'GlobalConfigValueGeneric') {
      mapperData.properties = [
        {"name": "value", "type": "T", "isOptional": false, "isSpecial": false},
        {"name": "configurationItem", "type": "ConfigurationItem", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'CartExpectedDateUpdate') {
      mapperData.properties = [
        {"name": "cartId", "type": "Guid", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'CartPriceAndAvailabilityUpdate') {
      mapperData.properties = [
        {"name": "cartId", "type": "Guid", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'CartUpdatedEvent') {
      mapperData.properties = [
        {"name": "cartId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "addProductsResponse", "type": "CartAddProductsResponseViewModel", "isOptional": false, "isSpecial": false},
        {"name": "updatedItems", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "source", "type": "AddToCartSource", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'CartAddProductsResponseViewModel') {
      mapperData.properties = [
        {"name": "cartId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "productErrors", "type": "CartAddProductErrorViewModel", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'CartAddProductErrorViewModel') {
      mapperData.properties = [
        {"name": "reason", "type": "CartAddProductErrorReason", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DashboardApiEvent') {
      mapperData.properties = [
        {"name": "items", "type": "DashBoardTile", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'DashBoardTile') {
      mapperData.properties = [
        {"name": "requiredRolePermissions", "type": "RoleEcommercePermission", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DeploymentEvent') {
      mapperData.properties = [
        {"name": "eventType", "type": "DeploymentEventType", "isOptional": false, "isSpecial": false},
        {"name": "logoutTime", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'ManualDownloadCompletedUserEvent') {
      mapperData.properties = [
        {"name": "status", "type": "DownloadedManualStatus", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'ManualDownloadedPackageStatusEvent') {
      mapperData.properties = [
        {"name": "status", "type": "DownloadedManualStatus", "isOptional": false, "isSpecial": false},
        {"name": "downloadErrorCode", "type": "DownloadedManualErrorCode", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ManualDownloadStatusUpdateEvent') {
      mapperData.properties = [
        {"name": "status", "type": "DownloadedManualStatus", "isOptional": false, "isSpecial": false},
        {"name": "downloadErrorCode", "type": "DownloadedManualErrorCode", "isOptional": true, "isSpecial": false},
        {"name": "fileType", "type": "DownloadedManualFileType", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'ManualPackageCompletedUserEvent') {
      mapperData.properties = [
        {"name": "status", "type": "DownloadedManualStatus", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'NavigationApiEvent') {
      mapperData.properties = [
        {"name": "items", "type": "NavigationLink", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'NavigationLink') {
      mapperData.properties = [
        {"name": "parent", "type": "NavigationLink", "isOptional": false, "isSpecial": false},
        {"name": "children", "type": "NavigationLink", "isOptional": true, "isSpecial": false},
        {"name": "nameResource", "type": "Resource", "isOptional": false, "isSpecial": false},
        {"name": "requiredRoleEcommercePermissions", "type": "RoleEcommercePermission", "isOptional": false, "isSpecial": false},
        {"name": "requiredRoleAdminPermissions", "type": "RoleAdministrationPermission", "isOptional": false, "isSpecial": false},
        {"name": "requiredBusinessRelationPermissions", "type": "BusinessRelationPermission", "isOptional": false, "isSpecial": false},
        {"name": "icon", "type": "NavigationLinkIcon", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'Resource') {
      mapperData.properties = [
        {"name": "type", "type": "ResourceType", "isOptional": false, "isSpecial": false},
        {"name": "translations", "type": "ResourceTranslation", "isOptional": true, "isSpecial": false},
        {"name": "modifiedDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "systemMessageTitles", "type": "SystemMessage", "isOptional": true, "isSpecial": false},
        {"name": "systemMessageDescriptions", "type": "SystemMessage", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ResourceTranslation') {
      mapperData.properties = [
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false},
        {"name": "resource", "type": "Resource", "isOptional": false, "isSpecial": false},
        {"name": "modifiedDate", "type": "Date", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'Language') {
      mapperData.properties = [
        {"name": "languageMappings", "type": "LanguageMapping", "isOptional": true, "isSpecial": false},
        {"name": "translations", "type": "ResourceTranslation", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'LanguageMapping') {
      mapperData.properties = [
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'AuditableEntity') {
      mapperData.properties = [
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'SystemMessage') {
      mapperData.properties = [
        {"name": "userType", "type": "UserType", "isOptional": false, "isSpecial": false},
        {"name": "publishFromDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "publishToDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "disruptionFromDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "disruptionToDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "createdByUserId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "createdByUser", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "modifiedByUserId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "modifiedByUser", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "systemMessageResponsibilities", "type": "SystemMessageResponsibilities", "isOptional": true, "isSpecial": false},
        {"name": "changeTracking", "type": "ChangeTracking", "isOptional": false, "isSpecial": false},
        {"name": "titleResource", "type": "Resource", "isOptional": false, "isSpecial": false},
        {"name": "descriptionResource", "type": "Resource", "isOptional": false, "isSpecial": false},
        {"name": "systemMessageImages", "type": "SystemMessageImage", "isOptional": true, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'User') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "identityObjectId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "type", "type": "UserType", "isOptional": false, "isSpecial": false},
        {"name": "status", "type": "UserStatus", "isOptional": false, "isSpecial": false},
        {"name": "defaultDeliverySequence", "type": "DeliverySequence", "isOptional": false, "isSpecial": false},
        {"name": "role", "type": "Role", "isOptional": false, "isSpecial": false},
        {"name": "acceptedTermsAndConditionsDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "acceptedDataPrivacyStatementDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "lastSigninDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "expireDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "permissions", "type": "UserPermission", "isOptional": false, "isSpecial": false},
        {"name": "accessGroup", "type": "AccessGroup", "isOptional": false, "isSpecial": false},
        {"name": "accessGroupId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "country", "type": "Country", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "preferredDateFormat", "type": "UserDateFormat", "isOptional": true, "isSpecial": false},
        {"name": "emailRecipients", "type": "EmailRecipient", "isOptional": true, "isSpecial": false},
        {"name": "userFeatures", "type": "UserFeature", "isOptional": true, "isSpecial": false},
        {"name": "disabledUserNotifications", "type": "DisabledUserNotificationSetting", "isOptional": true, "isSpecial": false},
        {"name": "approvalDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "updatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false},
        {"name": "punchoutProfileParentUserId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "punchoutProfileParentUser", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "punchoutProfileUsers", "type": "User", "isOptional": true, "isSpecial": false},
        {"name": "changeTracking", "type": "ChangeTracking", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DeliverySequence') {
      mapperData.properties = [
        {"name": "country", "type": "Country", "isOptional": false, "isSpecial": false},
        {"name": "addressLines", "type": "AddressLine", "isOptional": true, "isSpecial": false},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "equipment", "type": "Equipment", "isOptional": true, "isSpecial": false},
        {"name": "businessRelation", "type": "BusinessRelation", "isOptional": false, "isSpecial": false},
        {"name": "deliverySequenceOperationSites", "type": "DeliverySequenceOperationSite", "isOptional": true, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Country') {
      mapperData.properties = [
        {"name": "company", "type": "Company", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'Company') {
      mapperData.properties = [
        {"name": "countries", "type": "Country", "isOptional": true, "isSpecial": false},
        {"name": "businessRelations", "type": "BusinessRelation", "isOptional": true, "isSpecial": false},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "newProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false},
        {"name": "salesArea", "type": "SalesArea", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'BusinessRelation') {
      mapperData.properties = [
        {"name": "company", "type": "Company", "isOptional": false, "isSpecial": false},
        {"name": "customer", "type": "Customer", "isOptional": false, "isSpecial": false},
        {"name": "deliverySequences", "type": "DeliverySequence", "isOptional": true, "isSpecial": false},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "orderReferenceType", "type": "OrderReferenceType", "isOptional": false, "isSpecial": false},
        {"name": "permissions", "type": "BusinessRelationPermission", "isOptional": false, "isSpecial": false},
        {"name": "customerClassification", "type": "CustomerClassification", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Customer') {
      mapperData.properties = [
        {"name": "businessRelations", "type": "BusinessRelation", "isOptional": true, "isSpecial": false},
        {"name": "status", "type": "CustomerStatus", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Content') {
      mapperData.properties = [
        {"name": "contentAccessGroups", "type": "ContentAccessGroup", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ContentAccessGroup') {
      mapperData.properties = [
        {"name": "accessGroupId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "accessGroup", "type": "AccessGroup", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'AccessGroup') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "accessGroupScopes", "type": "AccessGroupScope", "isOptional": true, "isSpecial": false},
        {"name": "contentAccessGroups", "type": "ContentAccessGroup", "isOptional": true, "isSpecial": false},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "type", "type": "AccessGroupType", "isOptional": false, "isSpecial": false},
        {"name": "permissions", "type": "AccessGroupPermission", "isOptional": false, "isSpecial": false},
        {"name": "users", "type": "User", "isOptional": true, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "updatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "changeTracking", "type": "ChangeTracking", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'AccessGroupScope') {
      mapperData.properties = [
        {"name": "accessGroupId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "accessGroup", "type": "AccessGroup", "isOptional": false, "isSpecial": false},
        {"name": "scope", "type": "Scope", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'Scope') {
      mapperData.properties = [
        {"name": "company", "type": "Company", "isOptional": false, "isSpecial": false},
        {"name": "deliverySequence", "type": "DeliverySequence", "isOptional": false, "isSpecial": false},
        {"name": "productArea", "type": "ProductArea", "isOptional": false, "isSpecial": false},
        {"name": "productGroup", "type": "ProductGroup", "isOptional": false, "isSpecial": false},
        {"name": "productSubGroup", "type": "ProductSubGroup", "isOptional": false, "isSpecial": false},
        {"name": "productModel", "type": "ProductModel", "isOptional": false, "isSpecial": false},
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "businessRelation", "type": "BusinessRelation", "isOptional": false, "isSpecial": false},
        {"name": "accessGroupScopes", "type": "AccessGroupScope", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductArea') {
      mapperData.properties = [
        {"name": "groups", "type": "ProductGroup", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductGroup') {
      mapperData.properties = [
        {"name": "productArea", "type": "ProductArea", "isOptional": false, "isSpecial": false},
        {"name": "subGroups", "type": "ProductSubGroup", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductSubGroup') {
      mapperData.properties = [
        {"name": "productGroup", "type": "ProductGroup", "isOptional": false, "isSpecial": false},
        {"name": "productModels", "type": "ProductModel", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductModel') {
      mapperData.properties = [
        {"name": "productSubGroup", "type": "ProductSubGroup", "isOptional": false, "isSpecial": false},
        {"name": "equipment", "type": "Equipment", "isOptional": true, "isSpecial": false},
        {"name": "modelImageLastModifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Equipment') {
      mapperData.properties = [
        {"name": "deliverySequence", "type": "DeliverySequence", "isOptional": false, "isSpecial": false},
        {"name": "productModel", "type": "ProductModel", "isOptional": false, "isSpecial": false},
        {"name": "installationDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "lastServiceDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "nextServiceDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "rebuildDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "updatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "warrantyExpiryDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "equipmentKits", "type": "EquipmentKit", "isOptional": true, "isSpecial": false},
        {"name": "equipmentSignals", "type": "EquipmentSignal", "isOptional": true, "isSpecial": false},
        {"name": "bulletinEquipments", "type": "BulletinEquipment", "isOptional": true, "isSpecial": false},
        {"name": "equipmentMedias", "type": "EquipmentMedia", "isOptional": true, "isSpecial": false},
        {"name": "operationSite", "type": "OperationSite", "isOptional": false, "isSpecial": false},
        {"name": "equipmentProducts", "type": "EquipmentProduct", "isOptional": true, "isSpecial": false},
        {"name": "equipmentCalenders", "type": "EquipmentCalendar", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'EquipmentKit') {
      mapperData.properties = [
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "component", "type": "Component", "isOptional": false, "isSpecial": false},
        {"name": "type", "type": "KitType", "isOptional": false, "isSpecial": false},
        {"name": "newProductId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "newProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'Component') {
      mapperData.properties = [
        {"name": "type", "type": "ComponentType", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'AuditableEntityGuid') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'NewProduct') {
      mapperData.properties = [
        {"name": "company", "type": "Company", "isOptional": false, "isSpecial": false},
        {"name": "type", "type": "ProductType", "isOptional": false, "isSpecial": false},
        {"name": "unitOfMeasure", "type": "UnitsOfMeasure", "isOptional": false, "isSpecial": false},
        {"name": "globalProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "globalProductId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "localProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false},
        {"name": "productItemClass", "type": "ProductItemClass", "isOptional": false, "isSpecial": false},
        {"name": "majorProductItemGroup", "type": "ProductItemGroup", "isOptional": false, "isSpecial": false},
        {"name": "minorProductItemGroup", "type": "ProductItemGroup", "isOptional": false, "isSpecial": false},
        {"name": "productItemType", "type": "ProductItemType", "isOptional": false, "isSpecial": false},
        {"name": "productImage", "type": "ProductImage", "isOptional": false, "isSpecial": false},
        {"name": "superseededProducts", "type": "SuperseededProduct", "isOptional": true, "isSpecial": false},
        {"name": "productTranslations", "type": "ProductTranslation", "isOptional": true, "isSpecial": false},
        {"name": "equipmentKits", "type": "EquipmentKit", "isOptional": true, "isSpecial": false},
        {"name": "aliases", "type": "ProductAlias", "isOptional": true, "isSpecial": false},
        {"name": "productNotificationProducts", "type": "ProductNotificationProduct", "isOptional": true, "isSpecial": false},
        {"name": "kitProducts", "type": "KitProduct", "isOptional": true, "isSpecial": false},
        {"name": "equipmentProducts", "type": "EquipmentProduct", "isOptional": true, "isSpecial": false},
        {"name": "promotionProducts", "type": "PromotionProduct", "isOptional": true, "isSpecial": false},
        {"name": "currentlySupersededByNewProductId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "currentlySupersededByNewProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "nextSuperseededDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "productCode", "type": "ProductCode", "isOptional": false, "isSpecial": false},
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'UnitsOfMeasure') {
      mapperData.properties = [
        {"name": "newProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductItemClass') {
      mapperData.properties = [
        {"name": "newProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductItemGroup') {
      mapperData.properties = [
        {"name": "minorItemGroupNewProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false},
        {"name": "majorItemGroupNewProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductItemType') {
      mapperData.properties = [
        {"name": "newProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'ProductImage') {
      mapperData.properties = [
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'SuperseededProduct') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "newProductId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "newProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "superSeededProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "superSeededProductId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "startDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "endDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'ProductTranslation') {
      mapperData.properties = [
        {"name": "productId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "product", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'ProductAlias') {
      mapperData.properties = [
        {"name": "newProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "newProductId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "businessRelation", "type": "BusinessRelation", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'ProductNotificationProduct') {
      mapperData.properties = [
        {"name": "productNotification", "type": "ProductNotification", "isOptional": false, "isSpecial": false},
        {"name": "newProductId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "newProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'ProductNotification') {
      mapperData.properties = [
        {"name": "productNotificationTargets", "type": "ProductNotificationTarget", "isOptional": true, "isSpecial": false},
        {"name": "productNotificationProducts", "type": "ProductNotificationProduct", "isOptional": true, "isSpecial": false},
        {"name": "productNotificationTranslations", "type": "ProductNotificationTranslation", "isOptional": true, "isSpecial": false},
        {"name": "changeTracking", "type": "ChangeTracking", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'ProductNotificationTarget') {
      mapperData.properties = [
        {"name": "company", "type": "Company", "isOptional": false, "isSpecial": false},
        {"name": "businessRelation", "type": "BusinessRelation", "isOptional": false, "isSpecial": false},
        {"name": "productNotification", "type": "ProductNotification", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'ProductNotificationTranslation') {
      mapperData.properties = [
        {"name": "productNotification", "type": "ProductNotification", "isOptional": false, "isSpecial": false},
        {"name": "messageUserContentShort", "type": "UserContentShort", "isOptional": false, "isSpecial": false},
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'UserContentShort') {
      mapperData.properties = [
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false},
        {"name": "modifiedById", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'KitProduct') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "kitNewProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "kitParentNewProductId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "newProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "newProductId", "type": "Guid", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'EquipmentProduct') {
      mapperData.properties = [
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "newProductId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "newProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false},
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Media') {
      mapperData.properties = [
        {"name": "latestSyncDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "tags", "type": "Tag", "isOptional": true, "isSpecial": false},
        {"name": "mediaShelves", "type": "MediaShelf", "isOptional": true, "isSpecial": false},
        {"name": "latestCalculatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "equipmentMedias", "type": "EquipmentMedia", "isOptional": true, "isSpecial": false},
        {"name": "updatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "billOfMaterialUpdatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "downloadedManual", "type": "DownloadedManual", "isOptional": false, "isSpecial": false},
        {"name": "downloadedManualJobs", "type": "DownloadManualJob", "isOptional": true, "isSpecial": false},
        {"name": "relatedMediaIdentifiers", "type": "RelatedMediaIdentifier", "isOptional": true, "isSpecial": false},
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false},
        {"name": "downloadedManualPackageMedias", "type": "DownloadedManualPackageMedia", "isOptional": true, "isSpecial": false},
        {"name": "mediaAsJson", "type": "MediaAsJson", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'Tag') {
      mapperData.properties = [
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "tagHeader", "type": "TagHeader", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'MediaShelf') {
      mapperData.properties = [
        {"name": "shelf", "type": "Shelf", "isOptional": false, "isSpecial": false},
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'Shelf') {
      mapperData.properties = [
        {"name": "createDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "updateDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "enabledForUserType", "type": "UserType", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'EquipmentMedia') {
      mapperData.properties = [
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "recentlyViewedMedias", "type": "RecentlyViewedMedia", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'RecentlyViewedMedia') {
      mapperData.properties = [
        {"name": "viewDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "userId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "equipmentMedia", "type": "EquipmentMedia", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DownloadedManual') {
      mapperData.properties = [
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "latestDocumotoDownloadDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "status", "type": "DownloadedManualStatus", "isOptional": false, "isSpecial": false},
        {"name": "fileType", "type": "DownloadedManualFileType", "isOptional": false, "isSpecial": false},
        {"name": "errorCode", "type": "DownloadedManualErrorCode", "isOptional": false, "isSpecial": false},
        {"name": "userDownloadedManuals", "type": "UserDownloadedManual", "isOptional": true, "isSpecial": false},
        {"name": "lastDownloadedByUserDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "documotoFetchMediaContentJob", "type": "DocumotoFetchMediaContentJob", "isOptional": false, "isSpecial": false},
        {"name": "downloadManualJob", "type": "DownloadManualJob", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'UserDownloadedManual') {
      mapperData.properties = [
        {"name": "lastDownloadDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "downloadRequestedDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "downloadedManual", "type": "DownloadedManual", "isOptional": false, "isSpecial": false},
        {"name": "downloadedManualPackage", "type": "DownloadedManualPackage", "isOptional": false, "isSpecial": false},
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "userId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'DownloadedManualPackage') {
      mapperData.properties = [
        {"name": "latestPackageUpdateDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "status", "type": "DownloadedManualStatus", "isOptional": false, "isSpecial": false},
        {"name": "errorCode", "type": "DownloadedManualErrorCode", "isOptional": false, "isSpecial": false},
        {"name": "userDownloadedManuals", "type": "UserDownloadedManual", "isOptional": true, "isSpecial": false},
        {"name": "media", "type": "DownloadedManualPackageMedia", "isOptional": true, "isSpecial": false},
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "isAllManualsForUserType", "type": "UserType", "isOptional": true, "isSpecial": false},
        {"name": "lastDownloadedByUserDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'DownloadedManualPackageMedia') {
      mapperData.properties = [
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "documotoDownloadedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "downloadedManualPackage", "type": "DownloadedManualPackage", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'DocumotoFetchMediaContentJob') {
      mapperData.properties = [
        {"name": "jobStatus", "type": "JobStatus", "isOptional": false, "isSpecial": false},
        {"name": "jobAddedTime", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "jobStartTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "jobEndTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "durableFunctionInstanceId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "priority", "type": "MediaContentJobPriority", "isOptional": false, "isSpecial": false},
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "trigger", "type": "MediaContentJobTrigger", "isOptional": false, "isSpecial": false},
        {"name": "documotoMediaSyncJob", "type": "DocumotoMediaSyncJob", "isOptional": false, "isSpecial": false},
        {"name": "documotoFetchMediaContentJobFiles", "type": "DocumotoFetchMediaContentJobFile", "isOptional": true, "isSpecial": false},
        {"name": "documotoProcessBillOfMaterialContentJobs", "type": "DocumotoProcessBillOfMaterialContentJob", "isOptional": true, "isSpecial": false},
        {"name": "downloadManualJobs", "type": "DownloadManualJob", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'DocumotoMediaSyncJob') {
      mapperData.properties = [
        {"name": "jobStatus", "type": "JobStatus", "isOptional": false, "isSpecial": false},
        {"name": "jobAddedTime", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "jobStartTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "jobEndTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "durableFunctionInstanceId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "documotoFetchMediaContentJobs", "type": "DocumotoFetchMediaContentJob", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'DocumotoFetchMediaContentJobFile') {
      mapperData.properties = [
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "status", "type": "MediaContentFileStatus", "isOptional": false, "isSpecial": false},
        {"name": "downloadType", "type": "MediaContentDownloadType", "isOptional": false, "isSpecial": false},
        {"name": "fileType", "type": "FileType", "isOptional": false, "isSpecial": false},
        {"name": "downloadStartDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "blobDeletedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "downloadEndDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "documotoExportStatusDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "blobSavedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "documotoFetchMediaContentJob", "type": "DocumotoFetchMediaContentJob", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DocumotoProcessBillOfMaterialContentJob') {
      mapperData.properties = [
        {"name": "jobStatus", "type": "JobStatus", "isOptional": false, "isSpecial": false},
        {"name": "jobAddedTime", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "jobsStartTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "jobEndTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "durableFunctionInstanceId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "documotoFetchMediaContentJob", "type": "DocumotoFetchMediaContentJob", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DownloadManualJob') {
      mapperData.properties = [
        {"name": "jobStatus", "type": "JobStatus", "isOptional": false, "isSpecial": false},
        {"name": "jobAddedTime", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "jobStartTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "jobEndTime", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "durableFunctionInstanceId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "documotoFetchMediaContentJob", "type": "DocumotoFetchMediaContentJob", "isOptional": false, "isSpecial": false},
        {"name": "pdfMergeIssues", "type": "DocumotoMdzPdfIssueDto", "isOptional": true, "isSpecial": false},
        {"name": "customStatus", "type": "DownloadManualJobCustomStatusDto", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DownloadManualJobCustomStatusDto') {
      mapperData.properties = [
        {"name": "instanceId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "fileType", "type": "DownloadedManualFileType", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'RelatedMediaIdentifier') {
      mapperData.properties = [
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'MediaAsJson') {
      mapperData.properties = [
        {"name": "media", "type": "Media", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'PromotionProduct') {
      mapperData.properties = [
        {"name": "productCompany", "type": "Company", "isOptional": false, "isSpecial": false},
        {"name": "promotion", "type": "Promotion", "isOptional": false, "isSpecial": false},
        {"name": "newProductId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "newProduct", "type": "NewProduct", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'Promotion') {
      mapperData.properties = [
        {"name": "type", "type": "PromotionType", "isOptional": false, "isSpecial": false},
        {"name": "placement", "type": "PromotionPlacement", "isOptional": false, "isSpecial": false},
        {"name": "publishFromDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "publishToDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "createdBy", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "modifiedBy", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "userFileId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "userFile", "type": "UserFile", "isOptional": false, "isSpecial": false},
        {"name": "promotionTargets", "type": "PromotionTarget", "isOptional": true, "isSpecial": false},
        {"name": "promotionProducts", "type": "PromotionProduct", "isOptional": true, "isSpecial": false},
        {"name": "promotionProductQuantities", "type": "PromotionProductQuantity", "isOptional": true, "isSpecial": false},
        {"name": "content", "type": "Content", "isOptional": false, "isSpecial": false},
        {"name": "changeTracking", "type": "ChangeTracking", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'UserFile') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "expirationDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "userId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "mimeType", "type": "MimeType", "isOptional": false, "isSpecial": false},
        {"name": "renditionOriginal", "type": "UserFile", "isOptional": false, "isSpecial": false},
        {"name": "rendition", "type": "Rendition", "isOptional": true, "isSpecial": false},
        {"name": "renditions", "type": "UserFile", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'PromotionTarget') {
      mapperData.properties = [
        {"name": "company", "type": "Company", "isOptional": false, "isSpecial": false},
        {"name": "businessRelation", "type": "BusinessRelation", "isOptional": false, "isSpecial": false},
        {"name": "productModel", "type": "ProductModel", "isOptional": false, "isSpecial": false},
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "promotion", "type": "Promotion", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'PromotionProductQuantity') {
      mapperData.properties = [
        {"name": "promotion", "type": "Promotion", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'ProductCode') {
      mapperData.properties = [
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "newProducts", "type": "NewProduct", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'EquipmentSignal') {
      mapperData.properties = [
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "signalType", "type": "EquipmentSignalType", "isOptional": false, "isSpecial": false},
        {"name": "captureDate", "type": "Date", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'BulletinEquipment') {
      mapperData.properties = [
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "bulletinId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "bulletin", "type": "IntegrationBulletin", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'IntegrationBulletin') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "endDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "lastModifiedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "content", "type": "Content", "isOptional": true, "isSpecial": false},
        {"name": "bulletinUserFiles", "type": "IntegrationBulletinUserFile", "isOptional": true, "isSpecial": false},
        {"name": "bulletinCreatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "bulletinLastModifiedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "iB_UserStatus", "type": "IntegrationBulletinUserStatus", "isOptional": true, "isSpecial": false},
        {"name": "iB_SerialNumbers", "type": "IntegrationBulletinSerialNumbers", "isOptional": true, "isSpecial": false},
        {"name": "iB_ProductModels", "type": "IntegrationBulletinProductModels", "isOptional": true, "isSpecial": false},
        {"name": "bulletinEquipments", "type": "BulletinEquipment", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'IntegrationBulletinUserFile') {
      mapperData.properties = [
        {"name": "integrationBulletinId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "integrationBulletin", "type": "IntegrationBulletin", "isOptional": false, "isSpecial": false},
        {"name": "userFileId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "userFile", "type": "UserFile", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'IntegrationBulletinUserStatus') {
      mapperData.properties = [
        {"name": "readDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "bulletin", "type": "IntegrationBulletin", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'IntegrationBulletinSerialNumbers') {
      mapperData.properties = [
        {"name": "bulletin", "type": "IntegrationBulletin", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'IntegrationBulletinProductModels') {
      mapperData.properties = [
        {"name": "bulletin", "type": "IntegrationBulletin", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'OperationSite') {
      mapperData.properties = [
        {"name": "country", "type": "Country", "isOptional": false, "isSpecial": false},
        {"name": "operationSiteOperators", "type": "OperationSiteOperator", "isOptional": true, "isSpecial": false},
        {"name": "operationSiteOwners", "type": "OperationSiteOwner", "isOptional": true, "isSpecial": false},
        {"name": "equipments", "type": "Equipment", "isOptional": true, "isSpecial": false},
        {"name": "deliverySequenceOperationSites", "type": "DeliverySequenceOperationSite", "isOptional": true, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'OperationSiteOperator') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "operationSite", "type": "OperationSite", "isOptional": false, "isSpecial": false},
        {"name": "customer", "type": "Customer", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'OperationSiteOwner') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "operationSite", "type": "OperationSite", "isOptional": false, "isSpecial": false},
        {"name": "customer", "type": "Customer", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'DeliverySequenceOperationSite') {
      mapperData.properties = [
        {"name": "deliverySequence", "type": "DeliverySequence", "isOptional": false, "isSpecial": false},
        {"name": "operationSite", "type": "OperationSite", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'EquipmentCalendar') {
      mapperData.properties = [
        {"name": "createdByUser", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "createdByUserId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "equipment", "type": "Equipment", "isOptional": false, "isSpecial": false},
        {"name": "startDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "endDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "maintenanceStatuses", "type": "EquipmentMaintenanceStatus", "isOptional": true, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'EquipmentMaintenanceStatus') {
      mapperData.properties = [
        {"name": "equipmentCalendar", "type": "EquipmentCalendar", "isOptional": false, "isSpecial": false},
        {"name": "createdByUser", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "createdByUserId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "status", "type": "MaintenanceCalendarWeekStatus", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Role') {
      mapperData.properties = [
        {"name": "type", "type": "RoleType", "isOptional": false, "isSpecial": false},
        {"name": "ecommercePermissions", "type": "RoleEcommercePermission", "isOptional": false, "isSpecial": false},
        {"name": "adminPermissions", "type": "RoleAdministrationPermission", "isOptional": false, "isSpecial": false},
        {"name": "users", "type": "User", "isOptional": true, "isSpecial": false},
        {"name": "features", "type": "RoleFeature", "isOptional": true, "isSpecial": false},
        {"name": "updatedDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "level", "type": "RoleLevel", "isOptional": false, "isSpecial": false},
        {"name": "changeTracking", "type": "ChangeTracking", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'RoleFeature') {
      mapperData.properties = [
        {"name": "feature", "type": "Feature", "isOptional": false, "isSpecial": false},
        {"name": "role", "type": "Role", "isOptional": false, "isSpecial": false},
        {"name": "setting", "type": "FeatureSetting", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Feature') {
      mapperData.properties = [
        {"name": "parentFeature", "type": "Feature", "isOptional": false, "isSpecial": false},
        {"name": "changeTracking", "type": "ChangeTracking", "isOptional": false, "isSpecial": false},
        {"name": "roleFeatures", "type": "RoleFeature", "isOptional": true, "isSpecial": false},
        {"name": "userFeatures", "type": "UserFeature", "isOptional": true, "isSpecial": false},
        {"name": "globalFeatures", "type": "GlobalFeature", "isOptional": true, "isSpecial": false},
        {"name": "featureResponsibilities", "type": "FeatureResponsibilities", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'UserFeature') {
      mapperData.properties = [
        {"name": "feature", "type": "Feature", "isOptional": false, "isSpecial": false},
        {"name": "userId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "setting", "type": "FeatureSetting", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'GlobalFeature') {
      mapperData.properties = [
        {"name": "feature", "type": "Feature", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "modifiedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'FeatureResponsibilities') {
      mapperData.properties = [
        {"name": "feature", "type": "Feature", "isOptional": false, "isSpecial": false},
        {"name": "scope", "type": "Scope", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'EmailRecipient') {
      mapperData.properties = [
        {"name": "email", "type": "Email", "isOptional": false, "isSpecial": false},
        {"name": "userId", "type": "Guid", "isOptional": true, "isSpecial": true},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "sendGridStatus", "type": "SendGridStatus", "isOptional": false, "isSpecial": false},
        {"name": "sendGridStatusUpdatedDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'Email') {
      mapperData.properties = [
        {"name": "category", "type": "EmailCategory", "isOptional": false, "isSpecial": false},
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "requestSentDate", "type": "Date", "isOptional": true, "isSpecial": true},
        {"name": "emailRecipients", "type": "EmailRecipient", "isOptional": true, "isSpecial": false},
        {"name": "emailFiles", "type": "EmailFile", "isOptional": true, "isSpecial": false},
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'EmailFile') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "email", "type": "Email", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'DisabledUserNotificationSetting') {
      mapperData.properties = [
        {"name": "userId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "userNotificationSettingsType", "type": "UserNotificationSettingsType", "isOptional": false, "isSpecial": false},
        {"name": "userNotificationSettingsFeatureType", "type": "UserNotificationSettingsFeatureType", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'SystemMessageResponsibilities') {
      mapperData.properties = [
        {"name": "company", "type": "Company", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'SystemMessageImage') {
      mapperData.properties = [
        {"name": "userFile", "type": "UserFile", "isOptional": false, "isSpecial": false},
        {"name": "userFileId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "systemMessage", "type": "SystemMessage", "isOptional": false, "isSpecial": false},
        {"name": "language", "type": "Language", "isOptional": false, "isSpecial": false}
      ];
    }
    if (type === 'NotificationEvent') {
      mapperData.properties = [
        {"name": "notifications", "type": "Notification", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'Notification') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "type", "type": "NotificationType", "isOptional": false, "isSpecial": false},
        {"name": "resourceTitle", "type": "Resource", "isOptional": false, "isSpecial": false},
        {"name": "resourceMessage", "type": "Resource", "isOptional": false, "isSpecial": false},
        {"name": "user", "type": "User", "isOptional": false, "isSpecial": false},
        {"name": "userId", "type": "Guid", "isOptional": false, "isSpecial": true},
        {"name": "createdDate", "type": "Date", "isOptional": false, "isSpecial": true},
        {"name": "readDate", "type": "Date", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'ProfileLoggedInEvent') {
      mapperData.properties = [
        {"name": "loggedInProfiles", "type": "Guid", "isOptional": true, "isSpecial": true}
      ];
    }
    if (type === 'SelectProfileEvent') {
      mapperData.properties = [
        {"name": "profiles", "type": "SelectProfileUserViewModel", "isOptional": true, "isSpecial": false}
      ];
    }
    if (type === 'SelectProfileUserViewModel') {
      mapperData.properties = [
        {"name": "id", "type": "Guid", "isOptional": false, "isSpecial": true}
      ];
    }
    if (type === 'UserMessageEvent') {
      mapperData.properties = [
        {"name": "type", "type": "UserMessageEventType", "isOptional": false, "isSpecial": false}
      ];
    }
    return new ApiTypeMapper(mapperData, isArray);
  }
  // convert the object for sending to backend
  public toBackend() {
    return this.convert(true);
  }
  // convert the object for sending to backend
  public toFrontend() {
    return this.convert(false);
  }
  public convert(toBackend: boolean) {
    if (this.typeMapperData.T) {
      for (let index = 0; index < this.typeMapperData.properties.length; index++) {
        if (this.typeMapperData.properties[index].type === 'T') {
          this.typeMapperData.properties[index].type = this.typeMapperData.T;
        }
      }
    }
    // convert the object for sending to backend or frontend
    if (typeof this.typeMapperData.obj === 'undefined' || this.typeMapperData.obj === null) {
      return null;
    }
    // map tuple items
    if (this.typeMapperData.tuple) {
      const newObject = {};
      const propertyKeys = Object.keys(this.typeMapperData.tuple);
      for (let index = 0; index < propertyKeys.length; index++) {
        if (!toBackend) {
          // item1 => name
          newObject[this.typeMapperData.tuple[propertyKeys[index]]] = this.typeMapperData.obj[propertyKeys[index]];
        } else {
          // name => Item1
          newObject[propertyKeys[index]] = this.typeMapperData.obj[this.typeMapperData.tuple[propertyKeys[index]]];
        }
      }
      this.typeMapperData.obj = newObject;
    }
    if (this.typeMapperData.properties === null) {
      // mapping of special types
      if (this.typeMapperData.type === 'Date') {
        if (toBackend) {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.MomentSpecialType'.ToBackend
          const theDate: Date = this.typeMapperData.obj;
          const formattedDate = format(addMinutes(theDate, theDate.getTimezoneOffset()), `yyyy-MM-dd'T'HH:mm:ss'Z'`);
          // console.warn("X -> FE => BE", formattedDate, ' - was - ', theDate);
          return formattedDate;
        } else {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.MomentSpecialType'.ToFrontend
          let dateString = this.typeMapperData.obj;
          if (dateString.match(/\.\d+$/)) {
            dateString = dateString.replace(/\.\d+$/, '');
          }
          if (dateString.indexOf('Z') === -1) {
            // SignalR sends format without respecting serialization settings sometimes. Assume it's UTC date
            dateString += 'Z';
          }
          // console.warn("X -> BE => FE", dateString, ' - was - ', this.typeMapperData.obj);
          return parseISO(dateString);
        }
      }
      if (this.typeMapperData.type === 'Guid') {
        if (toBackend) {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.GuidSpecialType'.ToBackend
          if (this.typeMapperData.obj instanceof Guid) {
            return this.typeMapperData.obj.value.toString();
          }
        } else {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.GuidSpecialType'.ToFrontend
          return Guid.parse(this.typeMapperData.obj);
        }
      }
      if (this.typeMapperData.type === 'ByteArray') {
        if (toBackend) {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.ByteArraySpecialType'.ToBackend
          if (this.typeMapperData.obj) {
            const data = [];
            const view = new DataView(this.typeMapperData.obj);
            for (let i = 0; i < view.byteLength; i++) {
              data.push(view.getUint8(i));
            }
            return data;
          }
          return null;
        } else {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.ByteArraySpecialType'.ToFrontend
          // eslint-disable-next-line no-console
          console.error('ByteArraySpecialType toFrontend is not implemented');
          return this.typeMapperData.obj;
        }
      }
      if (this.typeMapperData.type === 'TimeSpan') {
        if (toBackend) {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.TimeSpanSpecialType'.ToBackend
          if (this.typeMapperData.obj instanceof TimeSpan) {
            return this.typeMapperData.obj.toString();
          }
        } else {
          // 'Mibp.Frontend.ApiServiceGenerator.Generator.SpecialTypes.TimeSpanSpecialType'.ToFrontend
          return TimeSpan.parse(this.typeMapperData.obj);
        }
      }
    } else {
      // this is a Type. We should loop through its properties!
      for (let i = 0; i < this.typeMapperData.properties.length; i++) {
        const propertyName = this.typeMapperData.properties[i].name;
        const propertyType = this.typeMapperData.properties[i].type;
        const isOptional = this.typeMapperData.properties[i].isOptional;
        if (typeof this.typeMapperData.obj[propertyName] !== 'undefined' && this.typeMapperData.obj[propertyName] !== null) {
          if (propertyType.indexOf('[]') !== -1 || this.typeMapperData.obj[propertyName] instanceof Array) {
            const typeInArray = propertyType.replace(/\[\]$/, '');
            for (let j = 0; j < this.typeMapperData.obj[propertyName].length; j++) {
              this.typeMapperData.obj[propertyName][j] = ApiTypeMapper
                .create(this.typeMapperData.obj[propertyName][j], typeInArray)
                .convert(toBackend);
            }
          } else {
            if (isOptional && (typeof this.typeMapperData.obj[propertyName] === 'undefined' || this.typeMapperData.obj[propertyName] === null)) {
              this.typeMapperData.obj[propertyName] = null;
            } else {
              this.typeMapperData.obj[propertyName] = ApiTypeMapper
                .create(this.typeMapperData.obj[propertyName], propertyType).convert(toBackend);
            }
          }
        }
      }
    }
    return this.typeMapperData.obj;
  }
}
class MibpApiConnectionChildService {

  constructor(private signalrService: SignalRService) {}

  public ChangeDeliverySequence(previousDeliverySequenceId?: number, newDeliverySequenceId?: number): Observable<boolean> {
    const args: (number|number)[] = [previousDeliverySequenceId, newDeliverySequenceId];
    const subject = new Subject<boolean>();
    this.signalrService.stream<any>('Connection_ChangeDeliverySequence', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponse').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(result.success);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public UpdatedToken(token: string): Observable<boolean> {
    const args: (string)[] = [token];
    const subject = new Subject<boolean>();
    this.signalrService.stream<any>('Connection_UpdatedToken', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponse').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(result.success);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public ChangeUser(userId: Guid): Observable<boolean> {
    const args: (Guid)[] = [userId];
    args[0] = ApiTypeMapper.create(args[0], 'Guid').toBackend();
    const subject = new Subject<boolean>();
    this.signalrService.stream<any>('Connection_ChangeUser', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponse').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(result.success);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public ChangeEncodedDeliverySequence(deliverySequenceId: string): Observable<boolean> {
    const args: (string)[] = [deliverySequenceId];
    const subject = new Subject<boolean>();
    this.signalrService.stream<any>('Connection_ChangeEncodedDeliverySequence', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponse').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(result.success);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
}

class MibpApiSampleChildService {

  constructor(private signalrService: SignalRService) {}

  public GetSample(): Observable<generatedTypes.SignalR_Sample> {
    const args: [] = [];
    const subject = new Subject<generatedTypes.SignalR_Sample>();
    this.signalrService.invoke<any>('Sample_GetSample', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'Sample').toFrontend();
      subject.next(mappedResult);
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public GetSamples(delay = 5000): Observable<generatedTypes.SignalR_Sample[]> {
    const args: (number)[] = [delay];
    const subject = new Subject<generatedTypes.SignalR_Sample[]>();
    this.signalrService.stream<any>('Sample_GetSamples', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponseGeneric<Sample[]>').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(mappedResult.data);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public ThrowMeAnError(): Observable<boolean> {
    const args: [] = [];
    const subject = new Subject<boolean>();
    this.signalrService.stream<any>('Sample_ThrowMeAnError', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponse').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(result.success);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public DictionaryStringInt(): Observable<{ [key: string]: number  }> {
    const args: [] = [];
    const subject = new Subject<{ [key: string]: number  }>();
    this.signalrService.stream<any>('Sample_DictionaryStringInt', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponseGeneric<{ [key: string]: number  }>').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(mappedResult.data);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public DictionaryStringString(): Observable<{ [key: string]: string  }> {
    const args: [] = [];
    const subject = new Subject<{ [key: string]: string  }>();
    this.signalrService.stream<any>('Sample_DictionaryStringString', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponseGeneric<{ [key: string]: string  }>').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(mappedResult.data);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
  public Find(query: string, startIndex: number, limit: number): Observable<generatedTypes.SignalR_SampleFindResult> {
    const args: (string|number|number)[] = [query, startIndex, limit];
    const subject = new Subject<generatedTypes.SignalR_SampleFindResult>();
    this.signalrService.stream<any>('Sample_Find', args).then(result => {
      const mappedResult = ApiTypeMapper.create(result, 'ApiResponseGeneric<SampleFindResult>').toFrontend();
      if (typeof result.success !== 'undefined') {
        if (result.success === true) {
          subject.next(mappedResult.data);
        } else {
          subject.error(mappedResult);
        }
      }
      subject.complete();
    }).catch(err => subject.error(err));
    return subject.asObservable();
  }
  
}

class MibpApiEventHub {

  private cartExpectedDateUpdateSubject = new BehaviorSubject<generatedTypes.SignalR_CartExpectedDateUpdate>(null);
  private cartPriceAndAvailabilityUpdateSubject = new BehaviorSubject<generatedTypes.SignalR_CartPriceAndAvailabilityUpdate>(null);
  private cartUpdatedEventSubject = new BehaviorSubject<generatedTypes.SignalR_CartUpdatedEvent>(null);
  private dashboardApiEventSubject = new BehaviorSubject<generatedTypes.SignalR_DashboardApiEvent>(null);
  private deploymentEventSubject = new BehaviorSubject<generatedTypes.SignalR_DeploymentEvent>(null);
  private errorEventSubject = new BehaviorSubject<generatedTypes.SignalR_ErrorEvent>(null);
  private manualDownloadCompletedUserEventSubject = new BehaviorSubject<generatedTypes.SignalR_ManualDownloadCompletedUserEvent>(null);
  private manualDownloadedPackageStatusEventSubject = new BehaviorSubject<generatedTypes.SignalR_ManualDownloadedPackageStatusEvent>(null);
  private manualDownloadStatusUpdateEventSubject = new BehaviorSubject<generatedTypes.SignalR_ManualDownloadStatusUpdateEvent>(null);
  private manualPackageCompletedUserEventSubject = new BehaviorSubject<generatedTypes.SignalR_ManualPackageCompletedUserEvent>(null);
  private navigationApiEventSubject = new BehaviorSubject<generatedTypes.SignalR_NavigationApiEvent>(null);
  private notificationEventSubject = new BehaviorSubject<generatedTypes.SignalR_NotificationEvent>(null);
  private profileLoggedInEventSubject = new BehaviorSubject<generatedTypes.SignalR_ProfileLoggedInEvent>(null);
  private punchoutProfileUpdatedEventSubject = new BehaviorSubject<generatedTypes.SignalR_PunchoutProfileUpdatedEvent>(null);
  private refreshSystemMessagesEventSubject = new BehaviorSubject<generatedTypes.SignalR_RefreshSystemMessagesEvent>(null);
  private selectProfileEventSubject = new BehaviorSubject<generatedTypes.SignalR_SelectProfileEvent>(null);
  private sendOrderUpdateEventSubject = new BehaviorSubject<generatedTypes.SignalR_SendOrderUpdateEvent>(null);
  private tdmServedFileEventSubject = new BehaviorSubject<generatedTypes.SignalR_TDMServedFileEvent>(null);
  private triggerMibpSessionUpdateEventSubject = new BehaviorSubject<generatedTypes.SignalR_TriggerMibpSessionUpdateEvent>(null);
  private userMessageEventSubject = new BehaviorSubject<generatedTypes.SignalR_UserMessageEvent>(null);
  constructor(private signalrService: SignalRService) {
    this.signalrService.on('CartExpectedDateUpdate', data => {
      const mappedData = ApiTypeMapper.create(data, 'CartExpectedDateUpdate').toFrontend();
      this.cartExpectedDateUpdateSubject.next(mappedData);
    });
    this.signalrService.on('CartPriceAndAvailabilityUpdate', data => {
      const mappedData = ApiTypeMapper.create(data, 'CartPriceAndAvailabilityUpdate').toFrontend();
      this.cartPriceAndAvailabilityUpdateSubject.next(mappedData);
    });
    this.signalrService.on('CartUpdatedEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'CartUpdatedEvent').toFrontend();
      this.cartUpdatedEventSubject.next(mappedData);
    });
    this.signalrService.on('DashboardApiEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'DashboardApiEvent').toFrontend();
      this.dashboardApiEventSubject.next(mappedData);
    });
    this.signalrService.on('DeploymentEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'DeploymentEvent').toFrontend();
      this.deploymentEventSubject.next(mappedData);
    });
    this.signalrService.on('ErrorEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'ErrorEvent').toFrontend();
      this.errorEventSubject.next(mappedData);
    });
    this.signalrService.on('ManualDownloadCompletedUserEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'ManualDownloadCompletedUserEvent').toFrontend();
      this.manualDownloadCompletedUserEventSubject.next(mappedData);
    });
    this.signalrService.on('ManualDownloadedPackageStatusEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'ManualDownloadedPackageStatusEvent').toFrontend();
      this.manualDownloadedPackageStatusEventSubject.next(mappedData);
    });
    this.signalrService.on('ManualDownloadStatusUpdateEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'ManualDownloadStatusUpdateEvent').toFrontend();
      this.manualDownloadStatusUpdateEventSubject.next(mappedData);
    });
    this.signalrService.on('ManualPackageCompletedUserEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'ManualPackageCompletedUserEvent').toFrontend();
      this.manualPackageCompletedUserEventSubject.next(mappedData);
    });
    this.signalrService.on('NavigationApiEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'NavigationApiEvent').toFrontend();
      this.navigationApiEventSubject.next(mappedData);
    });
    this.signalrService.on('NotificationEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'NotificationEvent').toFrontend();
      this.notificationEventSubject.next(mappedData);
    });
    this.signalrService.on('ProfileLoggedInEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'ProfileLoggedInEvent').toFrontend();
      this.profileLoggedInEventSubject.next(mappedData);
    });
    this.signalrService.on('PunchoutProfileUpdatedEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'PunchoutProfileUpdatedEvent').toFrontend();
      this.punchoutProfileUpdatedEventSubject.next(mappedData);
    });
    this.signalrService.on('RefreshSystemMessagesEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'RefreshSystemMessagesEvent').toFrontend();
      this.refreshSystemMessagesEventSubject.next(mappedData);
    });
    this.signalrService.on('SelectProfileEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'SelectProfileEvent').toFrontend();
      this.selectProfileEventSubject.next(mappedData);
    });
    this.signalrService.on('SendOrderUpdateEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'SendOrderUpdateEvent').toFrontend();
      this.sendOrderUpdateEventSubject.next(mappedData);
    });
    this.signalrService.on('TDMServedFileEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'TDMServedFileEvent').toFrontend();
      this.tdmServedFileEventSubject.next(mappedData);
    });
    this.signalrService.on('TriggerMibpSessionUpdateEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'TriggerMibpSessionUpdateEvent').toFrontend();
      this.triggerMibpSessionUpdateEventSubject.next(mappedData);
    });
    this.signalrService.on('UserMessageEvent', data => {
      const mappedData = ApiTypeMapper.create(data, 'UserMessageEvent').toFrontend();
      this.userMessageEventSubject.next(mappedData);
    });
  }
  public get CartExpectedDateUpdate() {
    return this.cartExpectedDateUpdateSubject.asObservable();
  }
  public get CartPriceAndAvailabilityUpdate() {
    return this.cartPriceAndAvailabilityUpdateSubject.asObservable();
  }
  public get CartUpdated() {
    return this.cartUpdatedEventSubject.asObservable();
  }
  public get DashboardApi() {
    return this.dashboardApiEventSubject.asObservable();
  }
  public get Deployment() {
    return this.deploymentEventSubject.asObservable();
  }
  public get Error() {
    return this.errorEventSubject.asObservable();
  }
  public get ManualDownloadCompletedUser() {
    return this.manualDownloadCompletedUserEventSubject.asObservable();
  }
  public get ManualDownloadedPackageStatus() {
    return this.manualDownloadedPackageStatusEventSubject.asObservable();
  }
  public get ManualDownloadStatusUpdate() {
    return this.manualDownloadStatusUpdateEventSubject.asObservable();
  }
  public get ManualPackageCompletedUser() {
    return this.manualPackageCompletedUserEventSubject.asObservable();
  }
  public get NavigationApi() {
    return this.navigationApiEventSubject.asObservable();
  }
  public get Notification() {
    return this.notificationEventSubject.asObservable();
  }
  public get ProfileLoggedIn() {
    return this.profileLoggedInEventSubject.asObservable();
  }
  public get PunchoutProfileUpdated() {
    return this.punchoutProfileUpdatedEventSubject.asObservable();
  }
  public get RefreshSystemMessages() {
    return this.refreshSystemMessagesEventSubject.asObservable();
  }
  public get SelectProfile() {
    return this.selectProfileEventSubject.asObservable();
  }
  public get SendOrderUpdate() {
    return this.sendOrderUpdateEventSubject.asObservable();
  }
  public get TDMServedFile() {
    return this.tdmServedFileEventSubject.asObservable();
  }
  public get TriggerMibpSessionUpdate() {
    return this.triggerMibpSessionUpdateEventSubject.asObservable();
  }
  public get UserMessage() {
    return this.userMessageEventSubject.asObservable();
  }
}


@Injectable()
export class ApiService {
  private connectionChildService: MibpApiConnectionChildService;
  private sampleChildService: MibpApiSampleChildService;
  private eventHubChildService: MibpApiEventHub;
  constructor(signalrService: SignalRService) {
    this.connectionChildService = new MibpApiConnectionChildService(signalrService);
    this.sampleChildService = new MibpApiSampleChildService(signalrService);
    this.eventHubChildService = new MibpApiEventHub(signalrService);
  }
  public get Connection(): MibpApiConnectionChildService {
    return this.connectionChildService;
  }
  public get Sample(): MibpApiSampleChildService {
    return this.sampleChildService;
  }
  public get EventHub(): MibpApiEventHub {
    return this.eventHubChildService;
  }
}

