/* tslint:disable */
/* eslint-disable */
export enum DownloadedManualErrorCode {
  None = 0,
  UnknownError = 1,
  DocumotoExportFailed = 10,
  DocumotoReturnedNoRequestId = 11,
  DocumotoReturnedErrorStatus = 12,
  DocumotoReturnedUnknownStatus = 13,
  CreatePdfFailed = 20,
  ManualContainNoPdfs = 21,
  WebJobTerminated = 40,
  WebJobFailed = 50,
  NoPackageManualsCouldBeDownloaded = 60,
  ErrorCreatingPackage = 70,
  MediaMissingInDocumoto = 100
}
