<div class="treeview-controls">
  <div class="treeview-controls__search" *ngIf="enablePartsManualSearch">
    <div>
      <form [formGroup]="searchPartsManualForm" (ngSubmit)="submitSearchPartsManual()" class="hide-print">
      <input formControlName="query" type="search" placeholder="Search for chapters, pages and items">
      <button type="submit" class="my-button my-button--primary my-button--fill"><mibp-resource-string
          key="ResourceString_Search"></mibp-resource-string></button>                
      </form>
    </div>    
    <div>
      <mibp-loader *ngIf="isSearchLoading" type="spinner"></mibp-loader>
      <span *ngIf="foldersWithSearchHits && foldersWithSearchHits.length > 0 && !isSearchLoading">
        <mibp-resource-string key="PartsManual_Search_ResultText" [macros]="{locationCount: locationCountMacro}"></mibp-resource-string>
      </span>
      <span *ngIf="foldersWithSearchHits && foldersWithSearchHits.length < 1 && !isSearchLoading">
        <mibp-resource-string key="PartsManual_Search_NoResultText"></mibp-resource-string>
      </span>
      <span *ngIf="searchError && !isSearchLoading" class="error">
        <mibp-resource-string key="PartsManual_Search_ErrorText"></mibp-resource-string>
      </span>
    </div>
  </div>
  <div class="treeview-controls__expandcollapse">
    <button class="my-button my-button--secondary my-button--small my-button--fill" (click)="expandAll()">
      <span translate="no" class="material-icon material-icon--fill">add</span>
      <mibp-resource-string key="PartsManual_TreeView_ExpandAll"></mibp-resource-string> 
    </button>
    <button class="my-button my-button--secondary my-button--small my-button--fill" (click)="collapseAll()">
      <span translate="no" class="material-icon material-icon--fill">remove</span>
      <mibp-resource-string key="PartsManual_TreeView_CollapseAll"></mibp-resource-string> 
    </button>
  </div>
</div>

<div class="treeview">
  <div [style.opacity]="loadingSelectedFolder ? 0.5 : 1">
    <mibp-loader *ngIf="isLoadingTree"></mibp-loader>
  
    <ng-container [ngTemplateOutlet]="treeTemplate" [ngTemplateOutletContext]="{folders: tree, depth: 0, parentexpanded: false}"></ng-container>
  </div>
  
  <ng-template let-folders="folders" let-depth="depth" let-parentexpanded="parentexpanded" #treeTemplate>
    <ul class="folders" [style.marginLeft]="depth > 0 ? '33px' : null ">
      <li *ngFor="let folder of folders"
        class="folder"
        [class.folder--selected]="selectedPage?.id == folder.id"
        [class.folder--search-hit]="folder.isSearchHit"
        [attr.aria-expanded]="folder.isExpanded ? true : false"
        [attr.data-folder]="folder.id"
        [hidden]="depth > 0 && !parentexpanded"
        [ngClass]="{ 'folder--page': folder.type == folderTypes.Page || folder.childCount == 0, 'folder--chapter': folder.type == folderTypes.Chapter && folder.childCount > 0 }">
        <a href="javascript:void(0)" (click)="toggleFolder(folder)"><span class="plus-minus" *ngIf="folder.type == folderTypes.Chapter && folder.childCount > 0">{{ folder.isExpanded ? '-' : '+' }}</span></a>
        <a class="foldername" href="javascript:void(0)" (click)="selectFolder(folder)">  
          {{folder.name}}
          {{folder.assemblyNumber}}
          {{folder.revision}}
        </a>  
          <ng-template [ngIf]="folder.childFolders?.length > 0">
            <ng-container [ngTemplateOutlet]="treeTemplate" [ngTemplateOutletContext]="{folders: folder.childFolders, depth: depth +1, parentexpanded:folder.isExpanded}"></ng-container>
          </ng-template>
      </li>
    </ul>
  </ng-template>
</div>
