import { Injectable } from "@angular/core";
import { OperationsApiController } from "root/mibp-openapi-gen/controllers";
import { ToastService } from "../toast-service/toast.service";
import { firstValueFrom } from "rxjs";
import { ToastType } from "../toast-service/toast.enum";

export interface TerminateJobResult {
  wasTerminated: boolean;
}

type JobType =  'DocumotoPollForMediaChangesJob' |
                'DocumotoMediaSyncJob' |
                'DocumotoFetchMediaContentJob' |
                'DownloadManualJob' |
                'DocumotoProcessBillOfMaterialContentJob';

@Injectable({
  providedIn: 'root'
})
export class MibpJobsService {

  constructor(private operationsApi: OperationsApiController, private toast: ToastService) {}

  public async terminateJob(databaseEntityName: JobType, id: number): Promise<TerminateJobResult> {

    try {

      const result = await firstValueFrom(this.operationsApi.terminateRunningJob({
        databaseEntityTypeName: databaseEntityName,
        jobId: id
      }));

      if (result.notImplemented) {
        this.toast.showTextWithTitle(`Termination of type ${databaseEntityName} is not implemented`, 'Not implemented');
        return;
      }

      const messages = [
        result.databaseUpdatedSuccessfully ? 'Database updated' : null,
        result.databaseWasUpdated && !result.databaseUpdatedSuccessfully ? 'Could not update database' : null,
        result.terminateUrlWasCalled ? 'Terminate URL was invoked' : null
      ].filter(Boolean);

      this.toast.showText(messages.join('<br>'), {
        isHtml: true,
        type: ToastType.Info
      });


      return {
        wasTerminated: result.databaseUpdatedSuccessfully || result.terminateUrlWasCalled
      };

    } catch (e) {

      this.toast.showTextWithTitle('Could not terminate job', 'Error', {type: ToastType.Error});

      return {
        wasTerminated: false
      };
    }

  }


}
