import { allPermissionPolicies } from "root/all-permission-policies";
import { MySandvikNavigationItem } from "../navigation-types";

export const NAVITEMS_ADMINISTRATION: MySandvikNavigationItem = {
  resourceKey: 'Administration_Title',
  sandvikIconName: 'settings_account_box',
  showIfAnyChildren: true,
  route: {
    exactMatch: true,
    path: ['settings']
  },
  children: [
    {
      resourceKey: 'Settings_Users_External_title',
      permissionPolicy: allPermissionPolicies.canManageExernalUsers,
      uniqueId: 'external',
      route: {
        path: ['users', 'external']
      },
      children: [
        {
          resourceKey: 'Settings_UserDetails_Title',
          uniqueId: 'external/userdetails',
          children: [
            {route: {path: [':id']}, resourceKey: 'Settings_Users_edit_Tab', uniqueId: 'external/userdetails/edit'},
            {route: {path: ['permissions']}, resourceKey: 'Settings_Users_permissions_Tab', uniqueId: 'external/userdetails/permissions'},
            {route: {path: ['history']}, resourceKey: 'Global_History', uniqueId: 'external/userdetails/history',
              children: [{route: {path: [':id']}, resourceKey: 'Global_Details', uniqueId: 'external/userdetails/history/details'}]},
            {route: {path: [':id']}, resourceKey: 'Settings_User_Punchout', uniqueId: 'external/userdetails/punchout'},

          ]
        }
      ]
    },
    {
      resourceKey: 'Settings_Users_Internal_title',
      permissionPolicy: allPermissionPolicies.canManageInternalUsers,
      uniqueId: 'internal',
      route: {
        path: ['users', 'internal']
      },
      children: [
        {
          resourceKey: 'Settings_UserDetails_Title',
          uniqueId: 'internal/userdetails',
          children: [
            {route: {path: [':id']}, resourceKey: 'Settings_Users_edit_Tab', uniqueId: 'internal/userdetails/edit'},
            {route: {path: ['permissions']}, resourceKey: 'Settings_Users_permissions_Tab', uniqueId: 'internal/userdetails/permissions'},
            {route: {path: ['history']}, resourceKey: 'Global_History', uniqueId: 'internal/userdetails/history',
              children: [{route: {path: [':id']}, resourceKey: 'Global_Details', uniqueId: 'internal/userdetails/history/details'}]
            }
          ]
        }
      ]
    },
    {
      resourceKey: 'Settings_B2CAdmin_Title',
      permissionPolicy: allPermissionPolicies.canManageB2cUsers,
      uniqueId: 'b2c',
      route: {
        path: ['b2c']
      },
      children: [
        {
          route: {path: ['create']},
          resourceKey: 'Global_CreateB2CUser',
          uniqueId: 'b2c/create',
        }
      ]
    },

    // {
    //   resourceKey: 'Settings_Roles_title',
    //   permissionPolicy: allPermissionPolicies.canManageRoles,
    //   route: {
    //     path: ['roles']
    //   },
    //   children: [
    //     { route: {path: ['add']}, resourceKey: 'Settings_Roles_Add_Title' },
    //     { route: {path: ['edit/:id']}, resourceKey: 'Settings_Roles_Edit_Title' }
    //   ]
    // },
    {
      resourceKey: 'Settings_Roles_title',
      permissionPolicy: allPermissionPolicies.canManageRoles,
      route: {
        path: ['roles']
      },
	    uniqueId: 'roledetails',
      children: [
        { route: {path: ['add']}, resourceKey: 'Settings_Roles_Add_Title' },
        {route: {path: [':id/edit']}, resourceKey: 'Settings_Roles_Edit_Title',uniqueId: 'roledetails/edit'},
		    {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'roledetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'roledetails/history/details'}]
        },
      ]
    },
    {
      text: 'Manuals',
      permissionPolicy: {isSupportTeamMember: true},
      uniqueId: 'admin/manuals',
      route: {
        path: ['manuals']
      },
      children: [
        {route: {path: ['media',':identifier']},  text: 'Media', uniqueId: 'admin/manuals/media'},
      ]
    },
    {
      resourceKey: 'Settings_Features_Title',
      permissionPolicy: { isFeatureAdministrator: true },
      uniqueId: 'features',
      route: {
        path: ['features']
      },
      children: [
        {route: {path: [':identifier/edit']}, resourceKey: 'Global_Edit',uniqueId: 'featuredetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'featuredetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'featuredetails/history/details'}]
        },
      ]
    },
    {
      resourceKey: 'General_Changelog_Title',
      permissionPolicy: allPermissionPolicies.canManageChangelog,
      uniqueId: 'admin/changelog',
      route: {
        path: ['changelog']
      },
      children: [
        {
          route: { path: ['changelog/change']},
          permissionPolicy: allPermissionPolicies.canManageChangelog,
          resourceKey: 'Settings_ChangeLog_AddChange',
          uniqueId: 'admin/changelog/change/add'
        },
        {
          route: { path: ['change/edit']},
          permissionPolicy: allPermissionPolicies.canManageChangelog,
          resourceKey: 'Settings_ChangeLog_EditChange',
          uniqueId: 'admin/changelog/changedetails/edit'
        },
        { route: {path: ['history']},
          permissionPolicy: allPermissionPolicies.canManageChangelog,
          resourceKey: 'Settings_ChangeLog_ChangeHistory',
          uniqueId: 'admin/changelog/changedetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'admin/changelog/changedetails/history/details'}]
        },
        {
          route: { path: ['changelog/release']},
          permissionPolicy: allPermissionPolicies.canManageChangelog,
          resourceKey: 'Settings_ChangeLog_AddRelease',
          uniqueId: 'admin/changelog/release/add'
        },
        {
          route: { path: ['release/edit']},
          permissionPolicy: allPermissionPolicies.canManageChangelog,
          resourceKey: 'Settings_ChangeLog_EditRelease',
          uniqueId: 'admin/changelog/releasedetails/edit'
        },
        { route: {path: ['history']},
          resourceKey: 'Settings_ChangeLog_ReleaseHistory',
          uniqueId: 'admin/changelog/releasedetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'admin/changelog/releasedetails/history/details'}]
        },

      ]
    },
    // {
    //   resourceKey: 'Global_Companies',
    //   permissionPolicy: { features: [MySandvikFeatures.AdminCompanies] },
    //   route: {
    //     path: ['companies']
    //   },
    //   children: [
    //     {route: {path: ['edit']}, resourceKey: 'Settings_Companies_Edit_Title'}
    //   ]
    // }
    {
      resourceKey: 'Settings_Customers_title',
      permissionPolicy: allPermissionPolicies.canManageCustomers,
      route: {
        path: ['customers']
      },
      children: [
        {route: {path: ['edit']}, resourceKey: 'Settings_Customers_Edit_Title'}
      ]
    },


    {
      resourceKey: 'Settings_Organizations_title',
      permissionPolicy: allPermissionPolicies.canManageOrganizations,
      route: {
        path: ['organizations']
      },
      uniqueId: 'organizationdetails',
      children: [
        {route: {path: [':id']}, resourceKey: 'Settings_Organizations_Edit_Title',uniqueId: 'organizationdetails/edit'},
        {route: {path: ['add']}, resourceKey: 'Settings_Organizations_Add_Title'},
        {route: {path: ['history']}, resourceKey: 'Global_History', uniqueId: 'organizationdetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details', uniqueId: 'organizationdetails/history/details'}]
        },
        {route: {path: ['scopingstatus']}, resourceKey: 'Global_Organization_ScopingStatus',uniqueId: 'organizationdetails/scopingstatus'},
      ]
    },
    {
      resourceKey: 'Settings_Misc_title',
      permissionPolicy: allPermissionPolicies.canManageTranslations,
      route: {
        path: ['misc']
      },
      children: [
        {route: {path: ['translation']}, resourceKey: 'Settings-Admin-Translation-PageTitle'}
      ]
    },



    {
      resourceKey: 'Restricted_Items',
      permissionPolicy: allPermissionPolicies.canManageRestrictedItems,
      route: {
        path: ['restricteditems']
      },
      uniqueId: 'restricteditemdetails',
      children: [
        {route: {path: ['add']}, resourceKey: 'Add_Restricted_Item'},
        {route: {path: [':id/edit']}, resourceKey: 'Edit_Restricted_Item',uniqueId: 'restricteditemdetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'restricteditemdetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'restricteditemdetails/history/details'}]
        },
      ]
    },
    {
      resourceKey: 'Admin_Punchout_cXML_Heading',
      permissionPolicy: allPermissionPolicies.canManagePunchoutCxml,
      route: {
        path: ['punchoutxmladdress']
      },
      uniqueId: 'punchoutxmladdressdetails',
      children: [
        {route: {path: ['add']}, resourceKey: 'Global_Add_Address'},
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'punchoutxmladdressdetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'punchoutxmladdressdetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'punchoutxmladdressdetails/history/details'}]
        },
      ]
    },

    {
      resourceKey: 'Up_sell',
      permissionPolicy: allPermissionPolicies.canManageUpSell,
      route: {
        path: ['upsell']
      },
          uniqueId: 'upselldetails',
      children: [
        { route: {path: ['add']}, resourceKey: 'Global_Add' },
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'upselldetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'upselldetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'upselldetails/history/details'}]
        }
      ]
    },
    {
      resourceKey: 'Cross-sell',
      permissionPolicy: allPermissionPolicies.canManageCrossSell,
      route: {
        path: ['cross-sell']
      },
          uniqueId: 'cross-selldetails',
      children: [
        { route: {path: ['add']}, resourceKey: 'Global_Add' },
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'cross-selldetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'cross-selldetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'cross-selldetails/history/details'}]
        }
      ]
    },

    {
      text: 'Support Tools',
      permissionPolicy: allPermissionPolicies.isSupportTeamMember,
      route: {
        path: ['support-tools']
      }
    },
    {
      resourceKey: 'Global_Promotions',
      permissionPolicy: allPermissionPolicies.canManagePromotions,
      route: {
        path: ['promotions'],
      },
      uniqueId: 'promotiondetails',
      children: [
        { route: {path: ['add']}, resourceKey: 'Global_Add' },
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'promotiondetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'promotiondetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'promotiondetails/history/details'}]
        }
      ]
    },
    {
      resourceKey: 'Settings_MessageCenter_title',
      permissionPolicy: allPermissionPolicies.canManageMessageCenter,
      route: {
        path: ['messagecenter']
      },
      uniqueId: 'messagecenterdetails',
      children: [
        {route: {path: ['add']}, resourceKey: 'Global_Add'},
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'messagecenterdetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'messagecenterdetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'messagecenterdetails/history/details'}]
        },
      ]
    },
    {
      resourceKey: 'Global_Terms_And_Conditions',
      permissionPolicy: allPermissionPolicies.canManageTermsAndConditions,
      route: {
        path: ['termsandconditions']
      },
      uniqueId: 'termsandconditiondetails',
      children: [
        {route: {path: ['add']}, resourceKey: 'Global_Add'},
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'termsandconditiondetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'termsandconditiondetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'termsandconditiondetails/history/details'}]
        },
      ]
    },
    {
      resourceKey: 'Settings_Reports_title',
      permissionPolicy: allPermissionPolicies.canViewInternalReporting,
      route: {
        path: ['reports']
      },
      children:[
        {
          resourceKey: 'Settings_Reports_title',
          uniqueId: 'reportsdetail',
          children:[
            {route: {path: ['customerreport']}, resourceKey: 'Settings_Customers_title', uniqueId: 'reports/customerreport'},
            {route: {path: ['userreport']}, resourceKey: 'Settings_Users_title', uniqueId: 'reports/userreport'},
          ]

        }
      ]

    },
    {
      resourceKey: 'Settings_Product_Enrichment_Title',
      permissionPolicy: allPermissionPolicies.canManageProductEnrichment,
      route: {
        path: ['productenrichment']
      },
      uniqueId: 'productenrichmentdetails',
      children: [
        {route: {path: ['add']}, resourceKey: 'Global_Add'},
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'productenrichmentdetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'productenrichmentdetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'productenrichmentdetails/history/details'}]
        },
      ]
    },
    {
      resourceKey: 'ProductNotification_AdminPageTitle',
      permissionPolicy: allPermissionPolicies.canManageProductNotifications,
      route: {
        path: ['productnotification']
      },
      uniqueId: 'productnotificationdetails',
      children: [
        {route: {path: ['add']}, resourceKey: 'Global_Add'},
        {route: {path: [':id/edit']}, resourceKey: 'Global_Edit',uniqueId: 'productnotificationdetails/edit'},
        {
          route: {
            path: ['history']
          },
          resourceKey: 'Global_History',
          uniqueId: 'productnotificationdetails/history',
          children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'productnotificationdetails/history/details'}]
        },
      ]
    },
    {
      resourceKey: 'NewsArticle_Heading',
      permissionPolicy: allPermissionPolicies.canManagerNewsArticles,
      uniqueId: 'admin/newsarticle',
      route: {
        path: ['newsarticle']
      },
      children: [
        {route: {path: ['add']}, resourceKey: 'Global_Add', uniqueId: 'admin/newsarticle/add'},
        {route: {path: [':id']}, resourceKey: 'Global_Edit', uniqueId: 'admin/newsarticle/edit'}
      ]
    },
    {
      resourceKey: 'MyFleet_ProductUpgrade_Title',
      permissionPolicy: allPermissionPolicies.canManageMachineUpgrade,
      uniqueId: 'admin/upgrades',
      route: {
        path: ['upgrades']
      },
      children: [
        {route: {path: ['add']}, resourceKey: 'Global_Add', uniqueId: 'admin/upgrades/add'},
        {route: {path: [':id']}, resourceKey: 'Global_Edit', uniqueId: 'admin/upgrades/edit'},
        {route: {path: ['history']},resourceKey: 'Global_History', uniqueId: 'upgrades/history',
         children: [{route: {path: [':id']}, resourceKey: 'Global_Details',uniqueId: 'upgrades/history/details'}]
      }
      ]
    }
  ]
};
