/* tslint:disable */
/* eslint-disable */
export enum FileType {
  NotSet = 0,
  Pdf = 10,
  Mdz = 20,
  Excel = 30,
  OpenXmlExcel = 40,
  PlainText = 50,
  Jpeg = 60,
  Png = 70,
  WordOpenXml = 80,
  Word = 90,
  Postscript = 100,
  TIFF = 110,
  Zip = 120
}
