import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { BillOfMaterialApiController } from "root/mibp-openapi-gen/controllers";
import { AddItemToCartSource } from "root/mibp-openapi-gen/models";
import { BomPartVm } from './../../../mibp-openapi-gen/models/bom-part-vm';
import { CartService, BroadcastService, LocalizationService, GlobalConfigService, PermissionService, MibpLogger, LogService } from 'root/services';
import { skip, Subscription, firstValueFrom } from 'rxjs';
import { PartsManualIllustrationImage } from "../parts-manual.types";
import { ApiErrorHandlerService } from 'root/services/api-error-handler/api-error-handler';
import { HotPointExtended, PartManualsIllustration } from "../parts-manual-illustration/parts-manual-illustration.component";
import { MediaFolderAttachmentType, MediaFolderFileVersion, MediaFolderVm } from "root/mibp-openapi-gen/models";
import { MibpHttpApi } from './../../../services/mibp-http-api/mibp-http-api.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BomAttachmentVm } from './../../../mibp-openapi-gen/models/bom-attachment-vm';
import { ContactUsService } from './../../../services/contact-us/contact-us.service';
import { ContactUsTopic } from "root/components/contact-dialog/contact-us-form/contact-us-form.types";
import { PartsManualsService } from './../parts-manual.service';
import { SupportCaseItemEnquiryType } from "root/components/contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface";
import { allPermissionPolicies } from "root/all-permission-policies";
import { MibpSessionService } from "root/services/mibp-session/mibp-session.service";
import { MySandvikFeatures } from "root/services/permission";

interface ExtendedBomPartVm extends BomPartVm {
  addingToCart?: boolean;
  isSearchHit?: boolean;
}

interface ChildListItem {
  id: number;
  name: string;
  hasPdf: boolean;
  thumbnailImage: SafeUrl;
}


@Component({
  selector: 'mibp-parts-manual-folder-details',
  styleUrls: ['./parts-manual-folder-details.component.scss'],
  templateUrl: './parts-manual-folder-details.component.html',
  providers: [PartsManualsService]
})
export class MibpPartsManualFolderDetailsComponent implements OnChanges, OnInit, OnDestroy {

  @Input() mediaFolderId: number;
  @Input() mediaIdentifier: string;
  @Input() mediaId: number;
  @Input() isFolderInSearchResult = false;
  @Input() searchQuery: string;
  @Output() itemSelected = new EventEmitter();

  imageIsMissing = false;
  parts: ExtendedBomPartVm[];
  currentImageBase64Url?: string;
  selectedImage?: PartsManualIllustrationImage;
  isLoadingPage = false;
  justListChildren = false;
  illustrations: PartManualsIllustration[];
  isLoadingParts = false;
  windowIsMediumOrSmaller = false;

  pdfUrl: string;
  pdfName: string;
  pdfSize: number;
  downloadPdfUrl: string;

  actAsSub: Subscription;
  responsiveSub: Subscription;

  childList: ChildListItem[];
  whereUsedCount: number;
  queryParamSub: Subscription;
  selectedNode: number;
  log: MibpLogger;

  canAddToCart = false;

  toggleViewModeButtonText: string;
  useViewModeVertical = true;

  constructor(private bomApi: BillOfMaterialApiController,
    private broadcast: BroadcastService,
    private cartService: CartService,
    private httpApi: MibpHttpApi,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private localizationService: LocalizationService,
    private errorHandler: ApiErrorHandlerService,
    private contactUsService: ContactUsService,
    private partsManualService: PartsManualsService,
    private globalConfig: GlobalConfigService,
    private permissionService: PermissionService,
    private session: MibpSessionService,
    log: LogService,
    private localization: LocalizationService) {
    this.log = log.withPrefix('parts-manual-folder-details');
  }

  ngOnInit(): void {
    this.actAsSub = this.broadcast.deliverySequence.pipe(skip(1)).subscribe({
      next: () => this.updateVariablesFromActiveDeliverySequence()
    });
    this.updateVariablesFromActiveDeliverySequence();
    this.queryParamSub = this.route.queryParams.subscribe((params) => this.onQuerystringFilterUpdate(params)); 
    this.setToggleViewModeButtonText();

    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe(result => {
      const previousSizeWasMediumOrSmaller = this.windowIsMediumOrSmaller;
      this.windowIsMediumOrSmaller = result.lteq('m');
      this.useViewModeVertical = this.windowIsMediumOrSmaller ? false : previousSizeWasMediumOrSmaller ? true : this.useViewModeVertical;
      this.setToggleViewModeButtonText();
    });
  }

  setToggleViewModeButtonText() {
    this.toggleViewModeButtonText = this.useViewModeVertical ? 
    this.localizationService.get("PartsManual_ToggleView_Vertical") : this.localizationService.get("PartsManual_ToggleView_Horizontal");
  }

  onQuerystringFilterUpdate(params: Params){
    const nodeParamString = params['node'];
    if(nodeParamString){
      this.selectedNode = nodeParamString;
      const elemnt = document.getElementById('node_'+ this.selectedNode);
      setTimeout(() => {
        if (elemnt) {
          elemnt.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
      });
    }
    else{
      this.selectedNode = 0;
    }
  }

  updateVariablesFromActiveDeliverySequence(): void {
    const canSeeCart = this.permissionService.test(allPermissionPolicies.canSeeCart);
    const isActingAs = this.session.isActingAs();
    const hasPersistentCart = this.session.hasFeature(MySandvikFeatures.ShopPersistentCart);
    this.canAddToCart = canSeeCart && (isActingAs || hasPersistentCart);
  }


  openProductEnquiryDialog(productCode: string, quantity: number): void {
    if(this.globalConfig.enableNewContactUs){
      this.contactUsService.openItemEnquiryContactUs(productCode, quantity, SupportCaseItemEnquiryType.ProductAvailability);
    }
    else{
      const message = this.localizationService.format(
        this.localizationService.get('Carts_AvailabilityForProductMessage'),
        { code: productCode, qty: quantity.toString() }
      );
      this.contactUsService.openContactUs(ContactUsTopic.Enquiry, message);
    }
  }

  ngOnDestroy(): void {
    this.actAsSub?.unsubscribe();
    this.responsiveSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if (changes.mediaFolderId && changes.mediaFolderId.currentValue != changes.mediaFolderId.previousValue) {
      if (changes.mediaFolderId.currentValue && this.mediaIdentifier) {        
        this.illustrations = null;
        this.loadPage();
      }
    }
  }

  getBackgroundImageUrl(): string {
    return `url("${this.currentImageBase64Url}")`;
  }

  addToCart(part: ExtendedBomPartVm): void {
    part.addingToCart = true;
    this.cartService.addToCart([
      {
        productCode: part.partNumber,
        quantity: part.quantity == 0 ? 1 : part.quantity
      }
    ], AddItemToCartSource.PartsManual)
      .finally(() => {
        part.addingToCart = false;
      });
  }

  downloadPdf() {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = this.downloadPdfUrl;
    a.click();
    // window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  goToFolder(child: ChildListItem): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        folder: child.id
      }
    });
    this.itemSelected.emit();
  }

  whereUsedSelected(){
    this.itemSelected.emit();
  }


  async loadChildren(): Promise<void> {
    const folders = await firstValueFrom(this.bomApi.getMediaFolders({
      mediaIdentifier: this.mediaIdentifier,
      parentFolderId: this.mediaFolderId,
      includeAttachments: true
    }));

    this.partsManualService.prepareFolders(folders);

    this.childList = folders.map(folder => {
      const firstThumbnail = folder?.attachments?.find(a => a.type === MediaFolderAttachmentType.Image && a.version == MediaFolderFileVersion.ThumbnailImage );

      return {
        id: folder.id,
        name: folder.name + (folder.assemblyNumber ? ` ${folder.assemblyNumber}` : '') + (folder.revision ? ` ${folder.revision}` : ''),
        hasPdf: folder.attachments?.find(a => a.type == MediaFolderAttachmentType.PDF) ? true : false,
        thumbnailImage: firstThumbnail ? this.sanitizer.bypassSecurityTrustUrl(this.httpApi.resolveUriWithJwtToken(`/billofmaterial/images/${firstThumbnail.id}/raw`)) : null
      } as ChildListItem;

    });
  }


  private async onAttachmentsLoaded(attachments: BomAttachmentVm[]): Promise<void> {
    this.imageIsMissing = attachments.length == 0;

    const pdf = attachments.find(a => a.type == MediaFolderAttachmentType.PDF);
    if (pdf) {
      this.pdfUrl  = this.httpApi.resolveUriWithJwtToken(`/billofmaterial/pdf/${pdf.id}/raw`);
      this.downloadPdfUrl = this.httpApi.resolveUriWithJwtToken(`/billofmaterial/pdf/${pdf.id}/download`);
      this.pdfName = pdf.name;
      this.isLoadingPage = false;
      this.pdfSize = pdf.bytes;
      return;
    }

    // Make sure we have the images in the correct order
    attachments.sort((a,b) => {
      if (a.displayOrder == b.displayOrder) {
        return 0;
      }
      return a.displayOrder > b.displayOrder ? 1 : -1;
    });


    const hotPointData = await firstValueFrom(this.bomApi.getHotpointsInfo({ folderId: this.mediaFolderId})).catch(ex=>{
      this.log.error(ex, 'error fetching hotpoint info');
    });

    // Create a list of the images to pass in to illustration component
    const theImages: PartManualsIllustration[] = attachments
      .filter(a => a.version == MediaFolderFileVersion.OriginalFile)
      .map(attachment => {
        return {
          name: attachment.name,
          images: attachments.filter(f => f.name == attachment.name)
        } as PartManualsIllustration;
      });

    if (theImages.length > 0) {
      theImages.forEach(image=>{
        if(hotPointData){
          image.hotPoints = hotPointData.sheets?.find(s => s.displayOrder === image.images[0].displayOrder)?.hotpoints?.map(hp=><HotPointExtended>{
            x: hp.x,
            y: hp.y,
            radius: hp.radius,
            item: hp.item,
            isSelected: false
          }) || [];
        }
      });
      this.illustrations = theImages;
    }

  }

  private onPartsLoaded(parts: BomPartVm[]): void {
    this.parts = parts;
  }

  async loadPage(): Promise<void> {

    // Show loader and clear data
    this.isLoadingPage = true;
    this.illustrations = [];
    this.childList = null;
    this.pdfUrl = null;
    this.currentImageBase64Url = null;
    this.parts = null;
    this.selectedImage = null;

    let folderInfo: MediaFolderVm;

    try {
      folderInfo = await firstValueFrom(this.bomApi.getFolderInfo({folderId: this.mediaFolderId}));
    } catch (e) {
      this.errorHandler.parseError(e).showNotice();
    }

    this.justListChildren = !folderInfo.hasAttachments && !folderInfo.hasParts;
    if (this.justListChildren) {
      await this.loadChildren();
      this.isLoadingPage = false;
      return;
    }

    try {
      const [attachments, parts] = await Promise.all([
        folderInfo.hasAttachments ? await firstValueFrom(this.bomApi.getFolderAttachments({ folderId: this.mediaFolderId })) : await Promise.resolve([]),
        folderInfo.hasParts ? await firstValueFrom(this.bomApi.getPartsInFolder({folderId: this.mediaFolderId, mediaIdentifier: this.mediaIdentifier})) : await Promise.resolve([])
      ].filter(arrayItem => arrayItem != null));

      await this.onAttachmentsLoaded(attachments);
      this.onPartsLoaded(parts);

      this.isLoadingPage = false;
      this.isLoadingParts = false;

    } catch (e) {
      this.errorHandler.parseError(e).showNotice();
    }
  }

  toggleViewMode() {
    this.useViewModeVertical = !this.useViewModeVertical;
    this.setToggleViewModeButtonText();
  }

  isMatchWithSearchQuery(part: ExtendedBomPartVm) : boolean {
    if (!this.searchQuery || this.searchQuery.length < 1) {
      return;
    }

    return part.name?.toLowerCase().includes(this.searchQuery) || 
    part.partNumber?.toLowerCase().includes(this.searchQuery) ||
    part.description?.toLowerCase().includes(this.searchQuery);
  }

  navigateToProductDetailsPage(productCode: string){

    this.router.navigate([this.localization.getLang(), 'shop', 'products',`${productCode}`, 'information']);
  }

}
