/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acknowledgePendingMediaSyncRequest } from '../fn/media/acknowledge-pending-media-sync-request';
import { AcknowledgePendingMediaSyncRequest$Params } from '../fn/media/acknowledge-pending-media-sync-request';
import { addDocumotoPollForMediaChangesJobs } from '../fn/media/add-documoto-poll-for-media-changes-jobs';
import { AddDocumotoPollForMediaChangesJobs$Params } from '../fn/media/add-documoto-poll-for-media-changes-jobs';
import { addDocumotoPollForMediaChangesJobs$Plain } from '../fn/media/add-documoto-poll-for-media-changes-jobs-plain';
import { AddDocumotoPollForMediaChangesJobs$Plain$Params } from '../fn/media/add-documoto-poll-for-media-changes-jobs-plain';
import { addMediaSyncRequest } from '../fn/media/add-media-sync-request';
import { AddMediaSyncRequest$Params } from '../fn/media/add-media-sync-request';
import { addMediaSyncRequest$Plain } from '../fn/media/add-media-sync-request-plain';
import { AddMediaSyncRequest$Plain$Params } from '../fn/media/add-media-sync-request-plain';
import { addMediaToRecentlyViewed } from '../fn/media/add-media-to-recently-viewed';
import { AddMediaToRecentlyViewed$Params } from '../fn/media/add-media-to-recently-viewed';
import { addMediaToRecentlyViewed$Plain } from '../fn/media/add-media-to-recently-viewed-plain';
import { AddMediaToRecentlyViewed$Plain$Params } from '../fn/media/add-media-to-recently-viewed-plain';
import { authenticateDocumotoLibraryWidget } from '../fn/media/authenticate-documoto-library-widget';
import { AuthenticateDocumotoLibraryWidget$Params } from '../fn/media/authenticate-documoto-library-widget';
import { authenticateDocumotoLibraryWidget$Plain } from '../fn/media/authenticate-documoto-library-widget-plain';
import { AuthenticateDocumotoLibraryWidget$Plain$Params } from '../fn/media/authenticate-documoto-library-widget-plain';
import { authenticateDocumotoWidget } from '../fn/media/authenticate-documoto-widget';
import { AuthenticateDocumotoWidget$Params } from '../fn/media/authenticate-documoto-widget';
import { authenticateDocumotoWidget$Plain } from '../fn/media/authenticate-documoto-widget-plain';
import { AuthenticateDocumotoWidget$Plain$Params } from '../fn/media/authenticate-documoto-widget-plain';
import { deleteUserDownloadedManual } from '../fn/media/delete-user-downloaded-manual';
import { DeleteUserDownloadedManual$Params } from '../fn/media/delete-user-downloaded-manual';
import { deleteUserDownloadedManual$Plain } from '../fn/media/delete-user-downloaded-manual-plain';
import { DeleteUserDownloadedManual$Plain$Params } from '../fn/media/delete-user-downloaded-manual-plain';
import { DocumotoDownloadManualJobResultVm } from '../models/documoto-download-manual-job-result-vm';
import { DocumotoFetchMediaContentListItemVmPagedResponse } from '../models/documoto-fetch-media-content-list-item-vm-paged-response';
import { DocumotoMediaSyncJobDetailsVm } from '../models/documoto-media-sync-job-details-vm';
import { DocumotoMediaSyncJobListItemVmPagedResponse } from '../models/documoto-media-sync-job-list-item-vm-paged-response';
import { DocumotoPollForMediaChangesJobListItemVmPagedResponse } from '../models/documoto-poll-for-media-changes-job-list-item-vm-paged-response';
import { DocumotoProcessBillOfMaterialContentListItemVmPagedResponse } from '../models/documoto-process-bill-of-material-content-list-item-vm-paged-response';
import { DocumotoWidgetAuthResponse } from '../models/documoto-widget-auth-response';
import { DownloadedManualStatusVm } from '../models/downloaded-manual-status-vm';
import { DownloadedMediaDetailsVm } from '../models/downloaded-media-details-vm';
import { downloadMedia } from '../fn/media/download-media';
import { DownloadMedia$Params } from '../fn/media/download-media';
import { downloadMedia$Plain } from '../fn/media/download-media-plain';
import { DownloadMedia$Plain$Params } from '../fn/media/download-media-plain';
import { downloadMediaPackage } from '../fn/media/download-media-package';
import { DownloadMediaPackage$Params } from '../fn/media/download-media-package';
import { downloadMediaPackage$Plain } from '../fn/media/download-media-package-plain';
import { DownloadMediaPackage$Plain$Params } from '../fn/media/download-media-package-plain';
import { downloadMediaWithoutEquipment } from '../fn/media/download-media-without-equipment';
import { DownloadMediaWithoutEquipment$Params } from '../fn/media/download-media-without-equipment';
import { downloadMediaWithoutEquipment$Plain } from '../fn/media/download-media-without-equipment-plain';
import { DownloadMediaWithoutEquipment$Plain$Params } from '../fn/media/download-media-without-equipment-plain';
import { EquipmentDownloadedManualPackagesResponseVm } from '../models/equipment-downloaded-manual-packages-response-vm';
import { EquipmentMediaFiltersVm } from '../models/equipment-media-filters-vm';
import { getDocumotoMediaSyncJob } from '../fn/media/get-documoto-media-sync-job';
import { GetDocumotoMediaSyncJob$Params } from '../fn/media/get-documoto-media-sync-job';
import { getDocumotoMediaSyncJob$Plain } from '../fn/media/get-documoto-media-sync-job-plain';
import { GetDocumotoMediaSyncJob$Plain$Params } from '../fn/media/get-documoto-media-sync-job-plain';
import { getEquipmentMediaFilters } from '../fn/media/get-equipment-media-filters';
import { GetEquipmentMediaFilters$Params } from '../fn/media/get-equipment-media-filters';
import { getEquipmentMediaFilters$Plain } from '../fn/media/get-equipment-media-filters-plain';
import { GetEquipmentMediaFilters$Plain$Params } from '../fn/media/get-equipment-media-filters-plain';
import { getMediaAdminDetailsromDocumoto } from '../fn/media/get-media-admin-detailsrom-documoto';
import { GetMediaAdminDetailsromDocumoto$Params } from '../fn/media/get-media-admin-detailsrom-documoto';
import { getMediaAdminDetailsromDocumoto$Plain } from '../fn/media/get-media-admin-detailsrom-documoto-plain';
import { GetMediaAdminDetailsromDocumoto$Plain$Params } from '../fn/media/get-media-admin-detailsrom-documoto-plain';
import { getMediaDataForDocumotoManual } from '../fn/media/get-media-data-for-documoto-manual';
import { GetMediaDataForDocumotoManual$Params } from '../fn/media/get-media-data-for-documoto-manual';
import { getMediaDataForDocumotoManual$Plain } from '../fn/media/get-media-data-for-documoto-manual-plain';
import { GetMediaDataForDocumotoManual$Plain$Params } from '../fn/media/get-media-data-for-documoto-manual-plain';
import { getMediaDetailsByIdentifier } from '../fn/media/get-media-details-by-identifier';
import { GetMediaDetailsByIdentifier$Params } from '../fn/media/get-media-details-by-identifier';
import { getMediaDetailsByIdentifier$Plain } from '../fn/media/get-media-details-by-identifier-plain';
import { GetMediaDetailsByIdentifier$Plain$Params } from '../fn/media/get-media-details-by-identifier-plain';
import { getMediaDownloadStatus } from '../fn/media/get-media-download-status';
import { GetMediaDownloadStatus$Params } from '../fn/media/get-media-download-status';
import { getMediaDownloadStatus$Plain } from '../fn/media/get-media-download-status-plain';
import { GetMediaDownloadStatus$Plain$Params } from '../fn/media/get-media-download-status-plain';
import { getMediaInpackage } from '../fn/media/get-media-inpackage';
import { GetMediaInpackage$Params } from '../fn/media/get-media-inpackage';
import { getMediaInpackage$Plain } from '../fn/media/get-media-inpackage-plain';
import { GetMediaInpackage$Plain$Params } from '../fn/media/get-media-inpackage-plain';
import { GetMediaInPackageResponseVm } from '../models/get-media-in-package-response-vm';
import { getMediaNavigationTree } from '../fn/media/get-media-navigation-tree';
import { GetMediaNavigationTree$Params } from '../fn/media/get-media-navigation-tree';
import { getMediaNavigationTree$Plain } from '../fn/media/get-media-navigation-tree-plain';
import { GetMediaNavigationTree$Plain$Params } from '../fn/media/get-media-navigation-tree-plain';
import { getMediaSyncStatus } from '../fn/media/get-media-sync-status';
import { GetMediaSyncStatus$Params } from '../fn/media/get-media-sync-status';
import { getMediaSyncStatus$Plain } from '../fn/media/get-media-sync-status-plain';
import { GetMediaSyncStatus$Plain$Params } from '../fn/media/get-media-sync-status-plain';
import { getRecentlyViewedMedia } from '../fn/media/get-recently-viewed-media';
import { GetRecentlyViewedMedia$Params } from '../fn/media/get-recently-viewed-media';
import { getRecentlyViewedMedia$Plain } from '../fn/media/get-recently-viewed-media-plain';
import { GetRecentlyViewedMedia$Plain$Params } from '../fn/media/get-recently-viewed-media-plain';
import { getRelatedMedia } from '../fn/media/get-related-media';
import { GetRelatedMedia$Params } from '../fn/media/get-related-media';
import { getRelatedMedia$Plain } from '../fn/media/get-related-media-plain';
import { GetRelatedMedia$Plain$Params } from '../fn/media/get-related-media-plain';
import { GetRelatedMediaResponseVmPagedResponse } from '../models/get-related-media-response-vm-paged-response';
import { getUserDownloadedManual } from '../fn/media/get-user-downloaded-manual';
import { GetUserDownloadedManual$Params } from '../fn/media/get-user-downloaded-manual';
import { getUserDownloadedManual$Plain } from '../fn/media/get-user-downloaded-manual-plain';
import { GetUserDownloadedManual$Plain$Params } from '../fn/media/get-user-downloaded-manual-plain';
import { getUserPackagesForEquipment } from '../fn/media/get-user-packages-for-equipment';
import { GetUserPackagesForEquipment$Params } from '../fn/media/get-user-packages-for-equipment';
import { getUserPackagesForEquipment$Plain } from '../fn/media/get-user-packages-for-equipment-plain';
import { GetUserPackagesForEquipment$Plain$Params } from '../fn/media/get-user-packages-for-equipment-plain';
import { listAllMediaEquipment } from '../fn/media/list-all-media-equipment';
import { ListAllMediaEquipment$Params } from '../fn/media/list-all-media-equipment';
import { listAllMediaEquipment$Plain } from '../fn/media/list-all-media-equipment-plain';
import { ListAllMediaEquipment$Plain$Params } from '../fn/media/list-all-media-equipment-plain';
import { listAllMediaEquipmentByIdentifier } from '../fn/media/list-all-media-equipment-by-identifier';
import { ListAllMediaEquipmentByIdentifier$Params } from '../fn/media/list-all-media-equipment-by-identifier';
import { listAllMediaEquipmentByIdentifier$Plain } from '../fn/media/list-all-media-equipment-by-identifier-plain';
import { ListAllMediaEquipmentByIdentifier$Plain$Params } from '../fn/media/list-all-media-equipment-by-identifier-plain';
import { listDocumotoFetchMediaContentJobs } from '../fn/media/list-documoto-fetch-media-content-jobs';
import { ListDocumotoFetchMediaContentJobs$Params } from '../fn/media/list-documoto-fetch-media-content-jobs';
import { listDocumotoFetchMediaContentJobs$Plain } from '../fn/media/list-documoto-fetch-media-content-jobs-plain';
import { ListDocumotoFetchMediaContentJobs$Plain$Params } from '../fn/media/list-documoto-fetch-media-content-jobs-plain';
import { listDocumotoMediaSyncJobs } from '../fn/media/list-documoto-media-sync-jobs';
import { ListDocumotoMediaSyncJobs$Params } from '../fn/media/list-documoto-media-sync-jobs';
import { listDocumotoMediaSyncJobs$Plain } from '../fn/media/list-documoto-media-sync-jobs-plain';
import { ListDocumotoMediaSyncJobs$Plain$Params } from '../fn/media/list-documoto-media-sync-jobs-plain';
import { listDocumotoPollForMediaChangesJobs } from '../fn/media/list-documoto-poll-for-media-changes-jobs';
import { ListDocumotoPollForMediaChangesJobs$Params } from '../fn/media/list-documoto-poll-for-media-changes-jobs';
import { listDocumotoPollForMediaChangesJobs$Plain } from '../fn/media/list-documoto-poll-for-media-changes-jobs-plain';
import { ListDocumotoPollForMediaChangesJobs$Plain$Params } from '../fn/media/list-documoto-poll-for-media-changes-jobs-plain';
import { listDocumotoProcessBillOfMaterialContentJobs } from '../fn/media/list-documoto-process-bill-of-material-content-jobs';
import { ListDocumotoProcessBillOfMaterialContentJobs$Params } from '../fn/media/list-documoto-process-bill-of-material-content-jobs';
import { listDocumotoProcessBillOfMaterialContentJobs$Plain } from '../fn/media/list-documoto-process-bill-of-material-content-jobs-plain';
import { ListDocumotoProcessBillOfMaterialContentJobs$Plain$Params } from '../fn/media/list-documoto-process-bill-of-material-content-jobs-plain';
import { listDownloadManualsJob } from '../fn/media/list-download-manuals-job';
import { ListDownloadManualsJob$Params } from '../fn/media/list-download-manuals-job';
import { listDownloadManualsJob$Plain } from '../fn/media/list-download-manuals-job-plain';
import { ListDownloadManualsJob$Plain$Params } from '../fn/media/list-download-manuals-job-plain';
import { listMediasForEquipment } from '../fn/media/list-medias-for-equipment';
import { ListMediasForEquipment$Params } from '../fn/media/list-medias-for-equipment';
import { listMediasForEquipment$Plain } from '../fn/media/list-medias-for-equipment-plain';
import { ListMediasForEquipment$Plain$Params } from '../fn/media/list-medias-for-equipment-plain';
import { listMediaSyncRequests } from '../fn/media/list-media-sync-requests';
import { ListMediaSyncRequests$Params } from '../fn/media/list-media-sync-requests';
import { listMediaSyncRequests$Plain } from '../fn/media/list-media-sync-requests-plain';
import { ListMediaSyncRequests$Plain$Params } from '../fn/media/list-media-sync-requests-plain';
import { listMediaSyncRequestStatistics } from '../fn/media/list-media-sync-request-statistics';
import { ListMediaSyncRequestStatistics$Params } from '../fn/media/list-media-sync-request-statistics';
import { listMediaSyncRequestStatistics$Plain } from '../fn/media/list-media-sync-request-statistics-plain';
import { ListMediaSyncRequestStatistics$Plain$Params } from '../fn/media/list-media-sync-request-statistics-plain';
import { listUserDownloadedManuals } from '../fn/media/list-user-downloaded-manuals';
import { ListUserDownloadedManuals$Params } from '../fn/media/list-user-downloaded-manuals';
import { listUserDownloadedManuals$Plain } from '../fn/media/list-user-downloaded-manuals-plain';
import { ListUserDownloadedManuals$Plain$Params } from '../fn/media/list-user-downloaded-manuals-plain';
import { logDocumotoWidgetIntegrationMessage } from '../fn/media/log-documoto-widget-integration-message';
import { LogDocumotoWidgetIntegrationMessage$Params } from '../fn/media/log-documoto-widget-integration-message';
import { MediaAdminDetailsVm } from '../models/media-admin-details-vm';
import { MediaAdminDocumotoDetailsVm } from '../models/media-admin-documoto-details-vm';
import { MediaDataForDocumotoManualViewModel } from '../models/media-data-for-documoto-manual-view-model';
import { MediaEquipmentVmPagedResponse } from '../models/media-equipment-vm-paged-response';
import { MediaNavigationFolderVm } from '../models/media-navigation-folder-vm';
import { MediaSyncRequestStatisticsVm } from '../models/media-sync-request-statistics-vm';
import { MediaSyncRequestVmPagedResponse } from '../models/media-sync-request-vm-paged-response';
import { MediaSyncStatusVm } from '../models/media-sync-status-vm';
import { MediaViewModelPagedResponse } from '../models/media-view-model-paged-response';
import { MibpGridResponse } from '../models/mibp-grid-response';
import { PartsManualSearchResult } from '../models/parts-manual-search-result';
import { requestDownloadOfExistingManualPackage } from '../fn/media/request-download-of-existing-manual-package';
import { RequestDownloadOfExistingManualPackage$Params } from '../fn/media/request-download-of-existing-manual-package';
import { requestDownloadOfExistingManualPackage$Plain } from '../fn/media/request-download-of-existing-manual-package-plain';
import { RequestDownloadOfExistingManualPackage$Plain$Params } from '../fn/media/request-download-of-existing-manual-package-plain';
import { requestManualDownload } from '../fn/media/request-manual-download';
import { RequestManualDownload$Params } from '../fn/media/request-manual-download';
import { requestManualDownload$Plain } from '../fn/media/request-manual-download-plain';
import { RequestManualDownload$Plain$Params } from '../fn/media/request-manual-download-plain';
import { requestManualPackageDownload } from '../fn/media/request-manual-package-download';
import { RequestManualPackageDownload$Params } from '../fn/media/request-manual-package-download';
import { requestManualPackageDownload$Plain } from '../fn/media/request-manual-package-download-plain';
import { RequestManualPackageDownload$Plain$Params } from '../fn/media/request-manual-package-download-plain';
import { search } from '../fn/media/search';
import { Search$Params } from '../fn/media/search';
import { search$Plain } from '../fn/media/search-plain';
import { Search$Plain$Params } from '../fn/media/search-plain';
import { searchGrid } from '../fn/media/search-grid';
import { SearchGrid$Params } from '../fn/media/search-grid';
import { searchGrid$Plain } from '../fn/media/search-grid-plain';
import { SearchGrid$Plain$Params } from '../fn/media/search-grid-plain';
import { SearchMediaResultItemVmPagedResponse } from '../models/search-media-result-item-vm-paged-response';
import { SearchMediaResultItemVmSearchResponse } from '../models/search-media-result-item-vm-search-response';
import { searchPartsManual } from '../fn/media/search-parts-manual';
import { SearchPartsManual$Params } from '../fn/media/search-parts-manual';
import { searchPartsManual$Plain } from '../fn/media/search-parts-manual-plain';
import { SearchPartsManual$Plain$Params } from '../fn/media/search-parts-manual-plain';
import { UserDownloadedManualsInfoVm } from '../models/user-downloaded-manuals-info-vm';

@Injectable({ providedIn: 'root' })
export class MediaApiController extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mediaControllerListMediasForEquipmentequipmentIdSkipTakeShelfIdMediaNameLanguageId()` */
  static readonly MediaControllerListMediasForEquipmentequipmentIdSkipTakeShelfIdMediaNameLanguageIdPath = '/media/equipments';

  /**
   * ListMediasForEquipment for getting List of Medias for Equipments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMediasForEquipment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediasForEquipment$Plain$Response(params?: ListMediasForEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return listMediasForEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * ListMediasForEquipment for getting List of Medias for Equipments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMediasForEquipment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediasForEquipment$Plain(params?: ListMediasForEquipment$Plain$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.listMediasForEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /**
   * ListMediasForEquipment for getting List of Medias for Equipments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMediasForEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediasForEquipment$Response(params?: ListMediasForEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return listMediasForEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * ListMediasForEquipment for getting List of Medias for Equipments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMediasForEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediasForEquipment(params?: ListMediasForEquipment$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.listMediasForEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetEquipmentMediaFiltersequipmentId()` */
  static readonly MediaControllerGetEquipmentMediaFiltersequipmentIdPath = '/media/equipmentmediafilters';

  /**
   * GetEquipmentMediaFilters for getting available filters for equipment medias.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMediaFilters$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMediaFilters$Plain$Response(params?: GetEquipmentMediaFilters$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentMediaFiltersVm>> {
    return getEquipmentMediaFilters$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * GetEquipmentMediaFilters for getting available filters for equipment medias.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMediaFilters$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMediaFilters$Plain(params?: GetEquipmentMediaFilters$Plain$Params, context?: HttpContext): Observable<EquipmentMediaFiltersVm> {
    return this.getEquipmentMediaFilters$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentMediaFiltersVm>): EquipmentMediaFiltersVm => r.body)
    );
  }

  /**
   * GetEquipmentMediaFilters for getting available filters for equipment medias.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentMediaFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMediaFilters$Response(params?: GetEquipmentMediaFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentMediaFiltersVm>> {
    return getEquipmentMediaFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * GetEquipmentMediaFilters for getting available filters for equipment medias.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEquipmentMediaFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentMediaFilters(params?: GetEquipmentMediaFilters$Params, context?: HttpContext): Observable<EquipmentMediaFiltersVm> {
    return this.getEquipmentMediaFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentMediaFiltersVm>): EquipmentMediaFiltersVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerAuthenticateDocumotoWidgetmediaIdentifier()` */
  static readonly MediaControllerAuthenticateDocumotoWidgetmediaIdentifierPath = '/media/documoto-widget/{mediaIdentifier}/auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateDocumotoWidget$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoWidget$Plain$Response(params: AuthenticateDocumotoWidget$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoWidgetAuthResponse>> {
    return authenticateDocumotoWidget$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticateDocumotoWidget$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoWidget$Plain(params: AuthenticateDocumotoWidget$Plain$Params, context?: HttpContext): Observable<DocumotoWidgetAuthResponse> {
    return this.authenticateDocumotoWidget$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoWidgetAuthResponse>): DocumotoWidgetAuthResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateDocumotoWidget()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoWidget$Response(params: AuthenticateDocumotoWidget$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoWidgetAuthResponse>> {
    return authenticateDocumotoWidget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticateDocumotoWidget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoWidget(params: AuthenticateDocumotoWidget$Params, context?: HttpContext): Observable<DocumotoWidgetAuthResponse> {
    return this.authenticateDocumotoWidget$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoWidgetAuthResponse>): DocumotoWidgetAuthResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerAuthenticateDocumotoLibraryWidget()` */
  static readonly MediaControllerAuthenticateDocumotoLibraryWidgetPath = '/media/documoto-widget/auth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateDocumotoLibraryWidget$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoLibraryWidget$Plain$Response(params?: AuthenticateDocumotoLibraryWidget$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoWidgetAuthResponse>> {
    return authenticateDocumotoLibraryWidget$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticateDocumotoLibraryWidget$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoLibraryWidget$Plain(params?: AuthenticateDocumotoLibraryWidget$Plain$Params, context?: HttpContext): Observable<DocumotoWidgetAuthResponse> {
    return this.authenticateDocumotoLibraryWidget$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoWidgetAuthResponse>): DocumotoWidgetAuthResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateDocumotoLibraryWidget()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoLibraryWidget$Response(params?: AuthenticateDocumotoLibraryWidget$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoWidgetAuthResponse>> {
    return authenticateDocumotoLibraryWidget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticateDocumotoLibraryWidget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateDocumotoLibraryWidget(params?: AuthenticateDocumotoLibraryWidget$Params, context?: HttpContext): Observable<DocumotoWidgetAuthResponse> {
    return this.authenticateDocumotoLibraryWidget$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoWidgetAuthResponse>): DocumotoWidgetAuthResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerLogDocumotoWidgetIntegrationMessagemediaIdentifierResult()` */
  static readonly MediaControllerLogDocumotoWidgetIntegrationMessagemediaIdentifierResultPath = '/media/documoto-widget/{mediaIdentifier}/log';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logDocumotoWidgetIntegrationMessage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logDocumotoWidgetIntegrationMessage$Response(params: LogDocumotoWidgetIntegrationMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return logDocumotoWidgetIntegrationMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logDocumotoWidgetIntegrationMessage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logDocumotoWidgetIntegrationMessage(params: LogDocumotoWidgetIntegrationMessage$Params, context?: HttpContext): Observable<void> {
    return this.logDocumotoWidgetIntegrationMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mediaControllerListUserDownloadedManualsskipTakeQueryStatusesSortBySortOrder()` */
  static readonly MediaControllerListUserDownloadedManualsskipTakeQueryStatusesSortBySortOrderPath = '/media/downloaded-manuals/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listUserDownloadedManuals$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listUserDownloadedManuals$Plain$Response(params?: ListUserDownloadedManuals$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return listUserDownloadedManuals$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listUserDownloadedManuals$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listUserDownloadedManuals$Plain(params?: ListUserDownloadedManuals$Plain$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.listUserDownloadedManuals$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listUserDownloadedManuals()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listUserDownloadedManuals$Response(params?: ListUserDownloadedManuals$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return listUserDownloadedManuals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listUserDownloadedManuals$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listUserDownloadedManuals(params?: ListUserDownloadedManuals$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.listUserDownloadedManuals$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetUserDownloadedManualmediaId()` */
  static readonly MediaControllerGetUserDownloadedManualmediaIdPath = '/media/downloaded-manuals/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserDownloadedManual$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDownloadedManual$Plain$Response(params: GetUserDownloadedManual$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadedMediaDetailsVm>> {
    return getUserDownloadedManual$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserDownloadedManual$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDownloadedManual$Plain(params: GetUserDownloadedManual$Plain$Params, context?: HttpContext): Observable<DownloadedMediaDetailsVm> {
    return this.getUserDownloadedManual$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadedMediaDetailsVm>): DownloadedMediaDetailsVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserDownloadedManual()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDownloadedManual$Response(params: GetUserDownloadedManual$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadedMediaDetailsVm>> {
    return getUserDownloadedManual(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserDownloadedManual$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserDownloadedManual(params: GetUserDownloadedManual$Params, context?: HttpContext): Observable<DownloadedMediaDetailsVm> {
    return this.getUserDownloadedManual$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadedMediaDetailsVm>): DownloadedMediaDetailsVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetUserPackagesForEquipmentequipmentId()` */
  static readonly MediaControllerGetUserPackagesForEquipmentequipmentIdPath = '/media/downloaded-manuals/equipment-packages/{equipmentId}';

  /**
   * Get a list of media packages that are available for the equipment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPackagesForEquipment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPackagesForEquipment$Plain$Response(params: GetUserPackagesForEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDownloadedManualPackagesResponseVm>> {
    return getUserPackagesForEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of media packages that are available for the equipment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPackagesForEquipment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPackagesForEquipment$Plain(params: GetUserPackagesForEquipment$Plain$Params, context?: HttpContext): Observable<EquipmentDownloadedManualPackagesResponseVm> {
    return this.getUserPackagesForEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDownloadedManualPackagesResponseVm>): EquipmentDownloadedManualPackagesResponseVm => r.body)
    );
  }

  /**
   * Get a list of media packages that are available for the equipment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserPackagesForEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPackagesForEquipment$Response(params: GetUserPackagesForEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<EquipmentDownloadedManualPackagesResponseVm>> {
    return getUserPackagesForEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of media packages that are available for the equipment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserPackagesForEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserPackagesForEquipment(params: GetUserPackagesForEquipment$Params, context?: HttpContext): Observable<EquipmentDownloadedManualPackagesResponseVm> {
    return this.getUserPackagesForEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<EquipmentDownloadedManualPackagesResponseVm>): EquipmentDownloadedManualPackagesResponseVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerRequestManualPackageDownloadrequest()` */
  static readonly MediaControllerRequestManualPackageDownloadrequestPath = '/media/request-manual-package';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestManualPackageDownload$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestManualPackageDownload$Plain$Response(params?: RequestManualPackageDownload$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return requestManualPackageDownload$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestManualPackageDownload$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestManualPackageDownload$Plain(params?: RequestManualPackageDownload$Plain$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.requestManualPackageDownload$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestManualPackageDownload()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestManualPackageDownload$Response(params?: RequestManualPackageDownload$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return requestManualPackageDownload(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestManualPackageDownload$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestManualPackageDownload(params?: RequestManualPackageDownload$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.requestManualPackageDownload$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerRequestDownloadOfExistingManualPackagedownloadedManualPackageId()` */
  static readonly MediaControllerRequestDownloadOfExistingManualPackagedownloadedManualPackageIdPath = '/media/request-manual-package/{downloadedManualPackageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestDownloadOfExistingManualPackage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestDownloadOfExistingManualPackage$Plain$Response(params: RequestDownloadOfExistingManualPackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return requestDownloadOfExistingManualPackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestDownloadOfExistingManualPackage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestDownloadOfExistingManualPackage$Plain(params: RequestDownloadOfExistingManualPackage$Plain$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.requestDownloadOfExistingManualPackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestDownloadOfExistingManualPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestDownloadOfExistingManualPackage$Response(params: RequestDownloadOfExistingManualPackage$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return requestDownloadOfExistingManualPackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestDownloadOfExistingManualPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestDownloadOfExistingManualPackage(params: RequestDownloadOfExistingManualPackage$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.requestDownloadOfExistingManualPackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerRequestManualDownloadmediaIdEquipmentId()` */
  static readonly MediaControllerRequestManualDownloadmediaIdEquipmentIdPath = '/media/request-manual';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestManualDownload$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestManualDownload$Plain$Response(params?: RequestManualDownload$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return requestManualDownload$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestManualDownload$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestManualDownload$Plain(params?: RequestManualDownload$Plain$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.requestManualDownload$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestManualDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestManualDownload$Response(params?: RequestManualDownload$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDownloadedManualsInfoVm>> {
    return requestManualDownload(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestManualDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestManualDownload(params?: RequestManualDownload$Params, context?: HttpContext): Observable<UserDownloadedManualsInfoVm> {
    return this.requestManualDownload$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDownloadedManualsInfoVm>): UserDownloadedManualsInfoVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerDownloadMediamediaIdEquipmentIdIsEmbeddedView()` */
  static readonly MediaControllerDownloadMediamediaIdEquipmentIdIsEmbeddedViewPath = '/media/{mediaId}/{equipmentId}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadMedia$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMedia$Plain$Response(params: DownloadMedia$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return downloadMedia$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadMedia$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMedia$Plain(params: DownloadMedia$Plain$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.downloadMedia$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMedia$Response(params: DownloadMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return downloadMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMedia(params: DownloadMedia$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.downloadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerDownloadMediaWithoutEquipmentmediaId()` */
  static readonly MediaControllerDownloadMediaWithoutEquipmentmediaIdPath = '/media/{mediaId}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadMediaWithoutEquipment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaWithoutEquipment$Plain$Response(params: DownloadMediaWithoutEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return downloadMediaWithoutEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadMediaWithoutEquipment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaWithoutEquipment$Plain(params: DownloadMediaWithoutEquipment$Plain$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.downloadMediaWithoutEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadMediaWithoutEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaWithoutEquipment$Response(params: DownloadMediaWithoutEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return downloadMediaWithoutEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadMediaWithoutEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaWithoutEquipment(params: DownloadMediaWithoutEquipment$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.downloadMediaWithoutEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerDownloadMediaPackagedownloadedManualPackageId()` */
  static readonly MediaControllerDownloadMediaPackagedownloadedManualPackageIdPath = '/media/download-package/{downloadedManualPackageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadMediaPackage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaPackage$Plain$Response(params: DownloadMediaPackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return downloadMediaPackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadMediaPackage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaPackage$Plain(params: DownloadMediaPackage$Plain$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.downloadMediaPackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadMediaPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaPackage$Response(params: DownloadMediaPackage$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return downloadMediaPackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadMediaPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadMediaPackage(params: DownloadMediaPackage$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.downloadMediaPackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetMediaInpackagedownloadedManualPackageId()` */
  static readonly MediaControllerGetMediaInpackagedownloadedManualPackageIdPath = '/media/download-package/{downloadedManualPackageId}/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaInpackage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaInpackage$Plain$Response(params: GetMediaInpackage$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMediaInPackageResponseVm>> {
    return getMediaInpackage$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaInpackage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaInpackage$Plain(params: GetMediaInpackage$Plain$Params, context?: HttpContext): Observable<GetMediaInPackageResponseVm> {
    return this.getMediaInpackage$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMediaInPackageResponseVm>): GetMediaInPackageResponseVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaInpackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaInpackage$Response(params: GetMediaInpackage$Params, context?: HttpContext): Observable<StrictHttpResponse<GetMediaInPackageResponseVm>> {
    return getMediaInpackage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaInpackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaInpackage(params: GetMediaInpackage$Params, context?: HttpContext): Observable<GetMediaInPackageResponseVm> {
    return this.getMediaInpackage$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetMediaInPackageResponseVm>): GetMediaInPackageResponseVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerDeleteUserDownloadedManualuserDownloadedManualId()` */
  static readonly MediaControllerDeleteUserDownloadedManualuserDownloadedManualIdPath = '/media/user-downloaded-manual/{userDownloadedManualId}';

  /**
   * Will soft delete this user download so it will no longer be visible for the user in the list.
   * If user downloads this same manual again later, then the soft delete will be reverted.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserDownloadedManual$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDownloadedManual$Plain$Response(params: DeleteUserDownloadedManual$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return deleteUserDownloadedManual$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Will soft delete this user download so it will no longer be visible for the user in the list.
   * If user downloads this same manual again later, then the soft delete will be reverted.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserDownloadedManual$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDownloadedManual$Plain(params: DeleteUserDownloadedManual$Plain$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.deleteUserDownloadedManual$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /**
   * Will soft delete this user download so it will no longer be visible for the user in the list.
   * If user downloads this same manual again later, then the soft delete will be reverted.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserDownloadedManual()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDownloadedManual$Response(params: DeleteUserDownloadedManual$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaViewModelPagedResponse>> {
    return deleteUserDownloadedManual(this.http, this.rootUrl, params, context);
  }

  /**
   * Will soft delete this user download so it will no longer be visible for the user in the list.
   * If user downloads this same manual again later, then the soft delete will be reverted.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUserDownloadedManual$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserDownloadedManual(params: DeleteUserDownloadedManual$Params, context?: HttpContext): Observable<MediaViewModelPagedResponse> {
    return this.deleteUserDownloadedManual$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaViewModelPagedResponse>): MediaViewModelPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerSearchsearchRequest()` */
  static readonly MediaControllerSearchsearchRequestPath = '/media/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain$Response(params?: Search$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchMediaResultItemVmSearchResponse>> {
    return search$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Plain(params?: Search$Plain$Params, context?: HttpContext): Observable<SearchMediaResultItemVmSearchResponse> {
    return this.search$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchMediaResultItemVmSearchResponse>): SearchMediaResultItemVmSearchResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchMediaResultItemVmSearchResponse>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<SearchMediaResultItemVmSearchResponse> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchMediaResultItemVmSearchResponse>): SearchMediaResultItemVmSearchResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetMediaDataForDocumotoManualmediaIdentifierEquipmentId()` */
  static readonly MediaControllerGetMediaDataForDocumotoManualmediaIdentifierEquipmentIdPath = '/media/{mediaIdentifier}/{equipmentId}/mediadatafordocumtoto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaDataForDocumotoManual$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDataForDocumotoManual$Plain$Response(params: GetMediaDataForDocumotoManual$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaDataForDocumotoManualViewModel>> {
    return getMediaDataForDocumotoManual$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaDataForDocumotoManual$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDataForDocumotoManual$Plain(params: GetMediaDataForDocumotoManual$Plain$Params, context?: HttpContext): Observable<MediaDataForDocumotoManualViewModel> {
    return this.getMediaDataForDocumotoManual$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaDataForDocumotoManualViewModel>): MediaDataForDocumotoManualViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaDataForDocumotoManual()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDataForDocumotoManual$Response(params: GetMediaDataForDocumotoManual$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaDataForDocumotoManualViewModel>> {
    return getMediaDataForDocumotoManual(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaDataForDocumotoManual$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDataForDocumotoManual(params: GetMediaDataForDocumotoManual$Params, context?: HttpContext): Observable<MediaDataForDocumotoManualViewModel> {
    return this.getMediaDataForDocumotoManual$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaDataForDocumotoManualViewModel>): MediaDataForDocumotoManualViewModel => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetRelatedMediarequest()` */
  static readonly MediaControllerGetRelatedMediarequestPath = '/media/relatedmedia';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRelatedMedia$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRelatedMedia$Plain$Response(params?: GetRelatedMedia$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRelatedMediaResponseVmPagedResponse>> {
    return getRelatedMedia$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRelatedMedia$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRelatedMedia$Plain(params?: GetRelatedMedia$Plain$Params, context?: HttpContext): Observable<GetRelatedMediaResponseVmPagedResponse> {
    return this.getRelatedMedia$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetRelatedMediaResponseVmPagedResponse>): GetRelatedMediaResponseVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRelatedMedia()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRelatedMedia$Response(params?: GetRelatedMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<GetRelatedMediaResponseVmPagedResponse>> {
    return getRelatedMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRelatedMedia$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRelatedMedia(params?: GetRelatedMedia$Params, context?: HttpContext): Observable<GetRelatedMediaResponseVmPagedResponse> {
    return this.getRelatedMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetRelatedMediaResponseVmPagedResponse>): GetRelatedMediaResponseVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetRecentlyViewedMedia()` */
  static readonly MediaControllerGetRecentlyViewedMediaPath = '/media/recentlyviewed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentlyViewedMedia$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentlyViewedMedia$Plain$Response(params?: GetRecentlyViewedMedia$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchMediaResultItemVmPagedResponse>> {
    return getRecentlyViewedMedia$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecentlyViewedMedia$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentlyViewedMedia$Plain(params?: GetRecentlyViewedMedia$Plain$Params, context?: HttpContext): Observable<SearchMediaResultItemVmPagedResponse> {
    return this.getRecentlyViewedMedia$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchMediaResultItemVmPagedResponse>): SearchMediaResultItemVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRecentlyViewedMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentlyViewedMedia$Response(params?: GetRecentlyViewedMedia$Params, context?: HttpContext): Observable<StrictHttpResponse<SearchMediaResultItemVmPagedResponse>> {
    return getRecentlyViewedMedia(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRecentlyViewedMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRecentlyViewedMedia(params?: GetRecentlyViewedMedia$Params, context?: HttpContext): Observable<SearchMediaResultItemVmPagedResponse> {
    return this.getRecentlyViewedMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<SearchMediaResultItemVmPagedResponse>): SearchMediaResultItemVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerAddMediaToRecentlyViewedequipmentIdMediaIdentifier()` */
  static readonly MediaControllerAddMediaToRecentlyViewedequipmentIdMediaIdentifierPath = '/media/{equipmentId}/{mediaIdentifier}/addtorecentlyviewed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMediaToRecentlyViewed$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addMediaToRecentlyViewed$Plain$Response(params: AddMediaToRecentlyViewed$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaDataForDocumotoManualViewModel>> {
    return addMediaToRecentlyViewed$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMediaToRecentlyViewed$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addMediaToRecentlyViewed$Plain(params: AddMediaToRecentlyViewed$Plain$Params, context?: HttpContext): Observable<MediaDataForDocumotoManualViewModel> {
    return this.addMediaToRecentlyViewed$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaDataForDocumotoManualViewModel>): MediaDataForDocumotoManualViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMediaToRecentlyViewed()` instead.
   *
   * This method doesn't expect any request body.
   */
  addMediaToRecentlyViewed$Response(params: AddMediaToRecentlyViewed$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaDataForDocumotoManualViewModel>> {
    return addMediaToRecentlyViewed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMediaToRecentlyViewed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addMediaToRecentlyViewed(params: AddMediaToRecentlyViewed$Params, context?: HttpContext): Observable<MediaDataForDocumotoManualViewModel> {
    return this.addMediaToRecentlyViewed$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaDataForDocumotoManualViewModel>): MediaDataForDocumotoManualViewModel => r.body)
    );
  }

  /** Path part for operation `mediaControllerListMediaSyncRequestsskipTakeQueryFromTo()` */
  static readonly MediaControllerListMediaSyncRequestsskipTakeQueryFromToPath = '/media/sync-requests';

  /**
   * List the detected changes for Media that has been added from different sources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMediaSyncRequests$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequests$Plain$Response(params?: ListMediaSyncRequests$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncRequestVmPagedResponse>> {
    return listMediaSyncRequests$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * List the detected changes for Media that has been added from different sources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMediaSyncRequests$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequests$Plain(params?: ListMediaSyncRequests$Plain$Params, context?: HttpContext): Observable<MediaSyncRequestVmPagedResponse> {
    return this.listMediaSyncRequests$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncRequestVmPagedResponse>): MediaSyncRequestVmPagedResponse => r.body)
    );
  }

  /**
   * List the detected changes for Media that has been added from different sources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMediaSyncRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequests$Response(params?: ListMediaSyncRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncRequestVmPagedResponse>> {
    return listMediaSyncRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * List the detected changes for Media that has been added from different sources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMediaSyncRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequests(params?: ListMediaSyncRequests$Params, context?: HttpContext): Observable<MediaSyncRequestVmPagedResponse> {
    return this.listMediaSyncRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncRequestVmPagedResponse>): MediaSyncRequestVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerAddMediaSyncRequestrequest()` */
  static readonly MediaControllerAddMediaSyncRequestrequestPath = '/media/sync-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMediaSyncRequest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMediaSyncRequest$Plain$Response(params?: AddMediaSyncRequest$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncRequestVmPagedResponse>> {
    return addMediaSyncRequest$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMediaSyncRequest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMediaSyncRequest$Plain(params?: AddMediaSyncRequest$Plain$Params, context?: HttpContext): Observable<MediaSyncRequestVmPagedResponse> {
    return this.addMediaSyncRequest$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncRequestVmPagedResponse>): MediaSyncRequestVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMediaSyncRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMediaSyncRequest$Response(params?: AddMediaSyncRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncRequestVmPagedResponse>> {
    return addMediaSyncRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMediaSyncRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMediaSyncRequest(params?: AddMediaSyncRequest$Params, context?: HttpContext): Observable<MediaSyncRequestVmPagedResponse> {
    return this.addMediaSyncRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncRequestVmPagedResponse>): MediaSyncRequestVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerAcknowledgePendingMediaSyncRequest()` */
  static readonly MediaControllerAcknowledgePendingMediaSyncRequestPath = '/media/sync-requests/acknowledge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acknowledgePendingMediaSyncRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  acknowledgePendingMediaSyncRequest$Response(params?: AcknowledgePendingMediaSyncRequest$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return acknowledgePendingMediaSyncRequest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acknowledgePendingMediaSyncRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acknowledgePendingMediaSyncRequest(params?: AcknowledgePendingMediaSyncRequest$Params, context?: HttpContext): Observable<void> {
    return this.acknowledgePendingMediaSyncRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mediaControllerListMediaSyncRequestStatisticsfromTo()` */
  static readonly MediaControllerListMediaSyncRequestStatisticsfromToPath = '/media/sync-requests/statistics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMediaSyncRequestStatistics$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequestStatistics$Plain$Response(params?: ListMediaSyncRequestStatistics$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncRequestStatisticsVm>> {
    return listMediaSyncRequestStatistics$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMediaSyncRequestStatistics$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequestStatistics$Plain(params?: ListMediaSyncRequestStatistics$Plain$Params, context?: HttpContext): Observable<MediaSyncRequestStatisticsVm> {
    return this.listMediaSyncRequestStatistics$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncRequestStatisticsVm>): MediaSyncRequestStatisticsVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listMediaSyncRequestStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequestStatistics$Response(params?: ListMediaSyncRequestStatistics$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncRequestStatisticsVm>> {
    return listMediaSyncRequestStatistics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listMediaSyncRequestStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listMediaSyncRequestStatistics(params?: ListMediaSyncRequestStatistics$Params, context?: HttpContext): Observable<MediaSyncRequestStatisticsVm> {
    return this.listMediaSyncRequestStatistics$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncRequestStatisticsVm>): MediaSyncRequestStatisticsVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetMediaSyncStatusidentifier()` */
  static readonly MediaControllerGetMediaSyncStatusidentifierPath = '/media/sync-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaSyncStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaSyncStatus$Plain$Response(params?: GetMediaSyncStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncStatusVm>> {
    return getMediaSyncStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaSyncStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaSyncStatus$Plain(params?: GetMediaSyncStatus$Plain$Params, context?: HttpContext): Observable<MediaSyncStatusVm> {
    return this.getMediaSyncStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncStatusVm>): MediaSyncStatusVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaSyncStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaSyncStatus$Response(params?: GetMediaSyncStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaSyncStatusVm>> {
    return getMediaSyncStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaSyncStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaSyncStatus(params?: GetMediaSyncStatus$Params, context?: HttpContext): Observable<MediaSyncStatusVm> {
    return this.getMediaSyncStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaSyncStatusVm>): MediaSyncStatusVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerListDocumotoMediaSyncJobssearch()` */
  static readonly MediaControllerListDocumotoMediaSyncJobssearchPath = '/media/jobs/documoto-media-sync/search';

  /**
   * Get a list of Documoto Media Sync jobs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoMediaSyncJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoMediaSyncJobs$Plain$Response(params?: ListDocumotoMediaSyncJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoMediaSyncJobListItemVmPagedResponse>> {
    return listDocumotoMediaSyncJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of Documoto Media Sync jobs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoMediaSyncJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoMediaSyncJobs$Plain(params?: ListDocumotoMediaSyncJobs$Plain$Params, context?: HttpContext): Observable<DocumotoMediaSyncJobListItemVmPagedResponse> {
    return this.listDocumotoMediaSyncJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoMediaSyncJobListItemVmPagedResponse>): DocumotoMediaSyncJobListItemVmPagedResponse => r.body)
    );
  }

  /**
   * Get a list of Documoto Media Sync jobs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoMediaSyncJobs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoMediaSyncJobs$Response(params?: ListDocumotoMediaSyncJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoMediaSyncJobListItemVmPagedResponse>> {
    return listDocumotoMediaSyncJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of Documoto Media Sync jobs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoMediaSyncJobs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoMediaSyncJobs(params?: ListDocumotoMediaSyncJobs$Params, context?: HttpContext): Observable<DocumotoMediaSyncJobListItemVmPagedResponse> {
    return this.listDocumotoMediaSyncJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoMediaSyncJobListItemVmPagedResponse>): DocumotoMediaSyncJobListItemVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetDocumotoMediaSyncJobid()` */
  static readonly MediaControllerGetDocumotoMediaSyncJobidPath = '/media/jobs/documoto-media-sync/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumotoMediaSyncJob$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMediaSyncJob$Plain$Response(params: GetDocumotoMediaSyncJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoMediaSyncJobDetailsVm>> {
    return getDocumotoMediaSyncJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumotoMediaSyncJob$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMediaSyncJob$Plain(params: GetDocumotoMediaSyncJob$Plain$Params, context?: HttpContext): Observable<DocumotoMediaSyncJobDetailsVm> {
    return this.getDocumotoMediaSyncJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoMediaSyncJobDetailsVm>): DocumotoMediaSyncJobDetailsVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumotoMediaSyncJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMediaSyncJob$Response(params: GetDocumotoMediaSyncJob$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoMediaSyncJobDetailsVm>> {
    return getDocumotoMediaSyncJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumotoMediaSyncJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumotoMediaSyncJob(params: GetDocumotoMediaSyncJob$Params, context?: HttpContext): Observable<DocumotoMediaSyncJobDetailsVm> {
    return this.getDocumotoMediaSyncJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoMediaSyncJobDetailsVm>): DocumotoMediaSyncJobDetailsVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerListDocumotoPollForMediaChangesJobssearch()` */
  static readonly MediaControllerListDocumotoPollForMediaChangesJobssearchPath = '/media/jobs/documoto-poll-for-media-changes/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoPollForMediaChangesJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoPollForMediaChangesJobs$Plain$Response(params?: ListDocumotoPollForMediaChangesJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>> {
    return listDocumotoPollForMediaChangesJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoPollForMediaChangesJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoPollForMediaChangesJobs$Plain(params?: ListDocumotoPollForMediaChangesJobs$Plain$Params, context?: HttpContext): Observable<DocumotoPollForMediaChangesJobListItemVmPagedResponse> {
    return this.listDocumotoPollForMediaChangesJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>): DocumotoPollForMediaChangesJobListItemVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoPollForMediaChangesJobs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoPollForMediaChangesJobs$Response(params?: ListDocumotoPollForMediaChangesJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>> {
    return listDocumotoPollForMediaChangesJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoPollForMediaChangesJobs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoPollForMediaChangesJobs(params?: ListDocumotoPollForMediaChangesJobs$Params, context?: HttpContext): Observable<DocumotoPollForMediaChangesJobListItemVmPagedResponse> {
    return this.listDocumotoPollForMediaChangesJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>): DocumotoPollForMediaChangesJobListItemVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerListDocumotoFetchMediaContentJobssearch()` */
  static readonly MediaControllerListDocumotoFetchMediaContentJobssearchPath = '/media/jobs/documoto-fetch-media-content/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoFetchMediaContentJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoFetchMediaContentJobs$Plain$Response(params?: ListDocumotoFetchMediaContentJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoFetchMediaContentListItemVmPagedResponse>> {
    return listDocumotoFetchMediaContentJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoFetchMediaContentJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoFetchMediaContentJobs$Plain(params?: ListDocumotoFetchMediaContentJobs$Plain$Params, context?: HttpContext): Observable<DocumotoFetchMediaContentListItemVmPagedResponse> {
    return this.listDocumotoFetchMediaContentJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoFetchMediaContentListItemVmPagedResponse>): DocumotoFetchMediaContentListItemVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoFetchMediaContentJobs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoFetchMediaContentJobs$Response(params?: ListDocumotoFetchMediaContentJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoFetchMediaContentListItemVmPagedResponse>> {
    return listDocumotoFetchMediaContentJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoFetchMediaContentJobs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoFetchMediaContentJobs(params?: ListDocumotoFetchMediaContentJobs$Params, context?: HttpContext): Observable<DocumotoFetchMediaContentListItemVmPagedResponse> {
    return this.listDocumotoFetchMediaContentJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoFetchMediaContentListItemVmPagedResponse>): DocumotoFetchMediaContentListItemVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerListDocumotoProcessBillOfMaterialContentJobssearch()` */
  static readonly MediaControllerListDocumotoProcessBillOfMaterialContentJobssearchPath = '/media/jobs/documoto-process-bill-of-material-content/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoProcessBillOfMaterialContentJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoProcessBillOfMaterialContentJobs$Plain$Response(params?: ListDocumotoProcessBillOfMaterialContentJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoProcessBillOfMaterialContentListItemVmPagedResponse>> {
    return listDocumotoProcessBillOfMaterialContentJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoProcessBillOfMaterialContentJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoProcessBillOfMaterialContentJobs$Plain(params?: ListDocumotoProcessBillOfMaterialContentJobs$Plain$Params, context?: HttpContext): Observable<DocumotoProcessBillOfMaterialContentListItemVmPagedResponse> {
    return this.listDocumotoProcessBillOfMaterialContentJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoProcessBillOfMaterialContentListItemVmPagedResponse>): DocumotoProcessBillOfMaterialContentListItemVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDocumotoProcessBillOfMaterialContentJobs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoProcessBillOfMaterialContentJobs$Response(params?: ListDocumotoProcessBillOfMaterialContentJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoProcessBillOfMaterialContentListItemVmPagedResponse>> {
    return listDocumotoProcessBillOfMaterialContentJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDocumotoProcessBillOfMaterialContentJobs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDocumotoProcessBillOfMaterialContentJobs(params?: ListDocumotoProcessBillOfMaterialContentJobs$Params, context?: HttpContext): Observable<DocumotoProcessBillOfMaterialContentListItemVmPagedResponse> {
    return this.listDocumotoProcessBillOfMaterialContentJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoProcessBillOfMaterialContentListItemVmPagedResponse>): DocumotoProcessBillOfMaterialContentListItemVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerAddDocumotoPollForMediaChangesJobsdateRange()` */
  static readonly MediaControllerAddDocumotoPollForMediaChangesJobsdateRangePath = '/media/jobs/documoto-poll-for-media-changes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDocumotoPollForMediaChangesJobs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDocumotoPollForMediaChangesJobs$Plain$Response(params?: AddDocumotoPollForMediaChangesJobs$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>> {
    return addDocumotoPollForMediaChangesJobs$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDocumotoPollForMediaChangesJobs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDocumotoPollForMediaChangesJobs$Plain(params?: AddDocumotoPollForMediaChangesJobs$Plain$Params, context?: HttpContext): Observable<DocumotoPollForMediaChangesJobListItemVmPagedResponse> {
    return this.addDocumotoPollForMediaChangesJobs$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>): DocumotoPollForMediaChangesJobListItemVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDocumotoPollForMediaChangesJobs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDocumotoPollForMediaChangesJobs$Response(params?: AddDocumotoPollForMediaChangesJobs$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>> {
    return addDocumotoPollForMediaChangesJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDocumotoPollForMediaChangesJobs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDocumotoPollForMediaChangesJobs(params?: AddDocumotoPollForMediaChangesJobs$Params, context?: HttpContext): Observable<DocumotoPollForMediaChangesJobListItemVmPagedResponse> {
    return this.addDocumotoPollForMediaChangesJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoPollForMediaChangesJobListItemVmPagedResponse>): DocumotoPollForMediaChangesJobListItemVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetMediaDetailsByIdentifieridentifier()` */
  static readonly MediaControllerGetMediaDetailsByIdentifieridentifierPath = '/media/media/admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaDetailsByIdentifier$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDetailsByIdentifier$Plain$Response(params?: GetMediaDetailsByIdentifier$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaAdminDetailsVm>> {
    return getMediaDetailsByIdentifier$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaDetailsByIdentifier$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDetailsByIdentifier$Plain(params?: GetMediaDetailsByIdentifier$Plain$Params, context?: HttpContext): Observable<MediaAdminDetailsVm> {
    return this.getMediaDetailsByIdentifier$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaAdminDetailsVm>): MediaAdminDetailsVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaDetailsByIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDetailsByIdentifier$Response(params?: GetMediaDetailsByIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaAdminDetailsVm>> {
    return getMediaDetailsByIdentifier(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaDetailsByIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDetailsByIdentifier(params?: GetMediaDetailsByIdentifier$Params, context?: HttpContext): Observable<MediaAdminDetailsVm> {
    return this.getMediaDetailsByIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaAdminDetailsVm>): MediaAdminDetailsVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetMediaAdminDetailsromDocumotoidentifier()` */
  static readonly MediaControllerGetMediaAdminDetailsromDocumotoidentifierPath = '/media/media/documoto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaAdminDetailsromDocumoto$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaAdminDetailsromDocumoto$Plain$Response(params?: GetMediaAdminDetailsromDocumoto$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaAdminDocumotoDetailsVm>> {
    return getMediaAdminDetailsromDocumoto$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaAdminDetailsromDocumoto$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaAdminDetailsromDocumoto$Plain(params?: GetMediaAdminDetailsromDocumoto$Plain$Params, context?: HttpContext): Observable<MediaAdminDocumotoDetailsVm> {
    return this.getMediaAdminDetailsromDocumoto$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaAdminDocumotoDetailsVm>): MediaAdminDocumotoDetailsVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaAdminDetailsromDocumoto()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaAdminDetailsromDocumoto$Response(params?: GetMediaAdminDetailsromDocumoto$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaAdminDocumotoDetailsVm>> {
    return getMediaAdminDetailsromDocumoto(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaAdminDetailsromDocumoto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaAdminDetailsromDocumoto(params?: GetMediaAdminDetailsromDocumoto$Params, context?: HttpContext): Observable<MediaAdminDocumotoDetailsVm> {
    return this.getMediaAdminDetailsromDocumoto$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaAdminDocumotoDetailsVm>): MediaAdminDocumotoDetailsVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerSearchGridoptions()` */
  static readonly MediaControllerSearchGridoptionsPath = '/media/searchgrid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain$Response(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Plain(params?: SearchGrid$Plain$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchGrid()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid$Response(params?: SearchGrid$Params, context?: HttpContext): Observable<StrictHttpResponse<MibpGridResponse>> {
    return searchGrid(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchGrid$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchGrid(params?: SearchGrid$Params, context?: HttpContext): Observable<MibpGridResponse> {
    return this.searchGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<MibpGridResponse>): MibpGridResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetMediaNavigationTreemediaIdentifier()` */
  static readonly MediaControllerGetMediaNavigationTreemediaIdentifierPath = '/media/media/{mediaIdentifier}/navtree';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaNavigationTree$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaNavigationTree$Plain$Response(params: GetMediaNavigationTree$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediaNavigationFolderVm>>> {
    return getMediaNavigationTree$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaNavigationTree$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaNavigationTree$Plain(params: GetMediaNavigationTree$Plain$Params, context?: HttpContext): Observable<Array<MediaNavigationFolderVm>> {
    return this.getMediaNavigationTree$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MediaNavigationFolderVm>>): Array<MediaNavigationFolderVm> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaNavigationTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaNavigationTree$Response(params: GetMediaNavigationTree$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediaNavigationFolderVm>>> {
    return getMediaNavigationTree(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaNavigationTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaNavigationTree(params: GetMediaNavigationTree$Params, context?: HttpContext): Observable<Array<MediaNavigationFolderVm>> {
    return this.getMediaNavigationTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MediaNavigationFolderVm>>): Array<MediaNavigationFolderVm> => r.body)
    );
  }

  /** Path part for operation `mediaControllerSearchPartsManualqueryMediaIdentifier()` */
  static readonly MediaControllerSearchPartsManualqueryMediaIdentifierPath = '/media/media/{mediaIdentifier}/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPartsManual$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPartsManual$Plain$Response(params: SearchPartsManual$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<PartsManualSearchResult>> {
    return searchPartsManual$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPartsManual$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPartsManual$Plain(params: SearchPartsManual$Plain$Params, context?: HttpContext): Observable<PartsManualSearchResult> {
    return this.searchPartsManual$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartsManualSearchResult>): PartsManualSearchResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPartsManual()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPartsManual$Response(params: SearchPartsManual$Params, context?: HttpContext): Observable<StrictHttpResponse<PartsManualSearchResult>> {
    return searchPartsManual(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPartsManual$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPartsManual(params: SearchPartsManual$Params, context?: HttpContext): Observable<PartsManualSearchResult> {
    return this.searchPartsManual$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartsManualSearchResult>): PartsManualSearchResult => r.body)
    );
  }

  /** Path part for operation `mediaControllerListDownloadManualsJobrequest()` */
  static readonly MediaControllerListDownloadManualsJobrequestPath = '/media/documoto/jobs/downloaded-manuals/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDownloadManualsJob$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDownloadManualsJob$Plain$Response(params?: ListDownloadManualsJob$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoDownloadManualJobResultVm>> {
    return listDownloadManualsJob$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDownloadManualsJob$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDownloadManualsJob$Plain(params?: ListDownloadManualsJob$Plain$Params, context?: HttpContext): Observable<DocumotoDownloadManualJobResultVm> {
    return this.listDownloadManualsJob$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoDownloadManualJobResultVm>): DocumotoDownloadManualJobResultVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listDownloadManualsJob()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDownloadManualsJob$Response(params?: ListDownloadManualsJob$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumotoDownloadManualJobResultVm>> {
    return listDownloadManualsJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listDownloadManualsJob$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  listDownloadManualsJob(params?: ListDownloadManualsJob$Params, context?: HttpContext): Observable<DocumotoDownloadManualJobResultVm> {
    return this.listDownloadManualsJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumotoDownloadManualJobResultVm>): DocumotoDownloadManualJobResultVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerGetMediaDownloadStatusmediaId()` */
  static readonly MediaControllerGetMediaDownloadStatusmediaIdPath = '/media/{mediaId}/download-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaDownloadStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDownloadStatus$Plain$Response(params: GetMediaDownloadStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadedManualStatusVm>> {
    return getMediaDownloadStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaDownloadStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDownloadStatus$Plain(params: GetMediaDownloadStatus$Plain$Params, context?: HttpContext): Observable<DownloadedManualStatusVm> {
    return this.getMediaDownloadStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadedManualStatusVm>): DownloadedManualStatusVm => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaDownloadStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDownloadStatus$Response(params: GetMediaDownloadStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<DownloadedManualStatusVm>> {
    return getMediaDownloadStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediaDownloadStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaDownloadStatus(params: GetMediaDownloadStatus$Params, context?: HttpContext): Observable<DownloadedManualStatusVm> {
    return this.getMediaDownloadStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<DownloadedManualStatusVm>): DownloadedManualStatusVm => r.body)
    );
  }

  /** Path part for operation `mediaControllerListAllMediaEquipmentmediaIdSkipTakeQuery()` */
  static readonly MediaControllerListAllMediaEquipmentmediaIdSkipTakeQueryPath = '/media/{mediaId}/equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllMediaEquipment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipment$Plain$Response(params: ListAllMediaEquipment$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaEquipmentVmPagedResponse>> {
    return listAllMediaEquipment$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllMediaEquipment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipment$Plain(params: ListAllMediaEquipment$Plain$Params, context?: HttpContext): Observable<MediaEquipmentVmPagedResponse> {
    return this.listAllMediaEquipment$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaEquipmentVmPagedResponse>): MediaEquipmentVmPagedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllMediaEquipment()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipment$Response(params: ListAllMediaEquipment$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaEquipmentVmPagedResponse>> {
    return listAllMediaEquipment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllMediaEquipment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipment(params: ListAllMediaEquipment$Params, context?: HttpContext): Observable<MediaEquipmentVmPagedResponse> {
    return this.listAllMediaEquipment$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaEquipmentVmPagedResponse>): MediaEquipmentVmPagedResponse => r.body)
    );
  }

  /** Path part for operation `mediaControllerListAllMediaEquipmentByIdentifiermediaIdentifierSkipTakeQuery()` */
  static readonly MediaControllerListAllMediaEquipmentByIdentifiermediaIdentifierSkipTakeQueryPath = '/media/equipment';

  /**
   * List equipment for the media by identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllMediaEquipmentByIdentifier$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipmentByIdentifier$Plain$Response(params?: ListAllMediaEquipmentByIdentifier$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaEquipmentVmPagedResponse>> {
    return listAllMediaEquipmentByIdentifier$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * List equipment for the media by identifier.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllMediaEquipmentByIdentifier$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipmentByIdentifier$Plain(params?: ListAllMediaEquipmentByIdentifier$Plain$Params, context?: HttpContext): Observable<MediaEquipmentVmPagedResponse> {
    return this.listAllMediaEquipmentByIdentifier$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaEquipmentVmPagedResponse>): MediaEquipmentVmPagedResponse => r.body)
    );
  }

  /**
   * List equipment for the media by identifier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAllMediaEquipmentByIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipmentByIdentifier$Response(params?: ListAllMediaEquipmentByIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaEquipmentVmPagedResponse>> {
    return listAllMediaEquipmentByIdentifier(this.http, this.rootUrl, params, context);
  }

  /**
   * List equipment for the media by identifier.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAllMediaEquipmentByIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAllMediaEquipmentByIdentifier(params?: ListAllMediaEquipmentByIdentifier$Params, context?: HttpContext): Observable<MediaEquipmentVmPagedResponse> {
    return this.listAllMediaEquipmentByIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaEquipmentVmPagedResponse>): MediaEquipmentVmPagedResponse => r.body)
    );
  }

}
