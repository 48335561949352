/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MediaEquipmentVmPagedResponse } from '../../models/media-equipment-vm-paged-response';

export interface ListAllMediaEquipmentByIdentifier$Params {
  mediaIdentifier?: string;
  skip?: number;
  take?: number;
  query?: string;
}

export function listAllMediaEquipmentByIdentifier(http: HttpClient, rootUrl: string, params?: ListAllMediaEquipmentByIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<MediaEquipmentVmPagedResponse>> {
  const rb = new RequestBuilder(rootUrl, listAllMediaEquipmentByIdentifier.PATH, 'post');
  if (params) {
    rb.query('mediaIdentifier', params.mediaIdentifier, {});
    rb.query('skip', params.skip, {});
    rb.query('take', params.take, {});
    rb.query('query', params.query, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MediaEquipmentVmPagedResponse>;
    })
  );
}

listAllMediaEquipmentByIdentifier.PATH = '/media/equipment';
