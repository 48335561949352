import { CartsApiController, PriceAndAvailabilityApiController } from 'root/mibp-openapi-gen/controllers';
import { BroadcastService, CartService, ContactUsService, DialogService, FrontendContextService, GlobalConfigService, LocalizationService, LogService, MibpLogger } from 'root/services';
import { OnInit, ElementRef, ViewChild, Component, OnDestroy } from "@angular/core";
import { Subscription, filter, firstValueFrom, fromEvent, map, skip, take } from 'rxjs';
import { ActiveCartInformationVm, Cart, PagedOptions, PriceAndAvailabilitySource, ProductExpectedDateRequestDto } from 'root/mibp-openapi-gen/models';
import { ProductListService } from 'root/modules/home/modules/cart/services';
import { ProductListItem } from 'root/modules/home/modules/cart/components';
import { Router } from '@angular/router';
import { MibpSessionService } from 'root/services/mibp-session/mibp-session.service';
import { ButtonColors } from '../button/button.enum';
import { MibpDateTimeService } from 'root/services/datetime/datetime.service';
import { MySandvikFeatures } from 'root/services/permission';
import { SupportCaseItemEnquiryType } from '../contact-dialog/new-contact-us-form/contact-us-form-item-enquiry/contact-us-item-enquiry-form.interface';
import { ContactUsTopic } from '../contact-dialog/contact-us-form/contact-us-form.types';

@Component({
  selector: 'mibp-cart-quickview',
  templateUrl: './cart-quickview.component.html',
  styleUrls: ['./cart-quickview.component.scss']
})

export class CartQuickviewComponent implements OnInit, OnDestroy {
  quickviewSub: Subscription;
  clickSub: Subscription;
  isOpen = false;
  isLoading = false;
  cart: ActiveCartInformationVm = null;
  currentPageItems: ProductListItem[];
  isActingAs = false;
  cartSubscription: Subscription;
  responsiveSub: Subscription;
  actAsSub?: Subscription;
  showMobileLayout = false;
  useexpectedDeliveryDateAndAvailability: boolean;
  currencyCode: string;
  subtotal: number;
  deleteIndex: number;
  isError = false;
  private log: MibpLogger;
  protected contactUsItemEquiryFormEnabled = false;
  private stopUsingResources: () => void;
  private contactUsMessageRequestPrice: string;
  canSeeProductPrices: boolean;

  @ViewChild("cartQuickviewElement", { read: ElementRef }) cartQuickviewElement: ElementRef;

  constructor(
    private broadcast:BroadcastService,
    private cartsController:CartsApiController,
    private productListService: ProductListService,
    private cartService: CartService,
    private frontEndContext: FrontendContextService,
    private logger: LogService,
    private router: Router,
    private contactUsService: ContactUsService,
    private context: FrontendContextService,
    private sessionService: MibpSessionService,
    private dialogService: DialogService,
    private localizationService: LocalizationService,
    private globalConfig: GlobalConfigService,
    private datetimeService: MibpDateTimeService,
    private priceAndAvailabilityApiController: PriceAndAvailabilityApiController){

    this.log = logger.withPrefix('cart-quickview.component');
  }

  ngOnInit(): void {
    this.contactUsItemEquiryFormEnabled = this.context.testPermission({ features: [MySandvikFeatures.ContactUsFormItemEnquiry] });
    this.isActingAs = !!this.broadcast.snapshot.deliverySequence;
    this.useexpectedDeliveryDateAndAvailability = this.frontEndContext.isExpectedDeliveryDateEnabled();
    this.currencyCode = this.sessionService.activeDeliverySequence?.currencyCode + ' ' || ' ';

    this.quickviewSub = this.broadcast.cartQuickviewOpen.subscribe(isOpen => this.onOpenChange(isOpen));
    this.cartSubscription =  this.broadcast.cartEvent.subscribe(e => { this.loadCartItems();});
    this.responsiveSub = this.broadcast.responsiveBreakpoint.subscribe((screen) => { this.showMobileLayout = screen.lteq('s'); });
    this.actAsSub = this.broadcast.deliverySequence.pipe(skip(1)).subscribe(() => {
      this.isActingAs = !!this.broadcast.snapshot.deliverySequence;
      this.currencyCode = this.sessionService.activeDeliverySequence?.currencyCode + ' ' || ' ';
    });

    this.stopUsingResources = this.localizationService.using(['Carts_PriceForProductMessage'],
      values => {
        this.contactUsMessageRequestPrice = values[0];
      }
    );

    this.getPriceAndAvailabilitisOnRegularIntervalForCart();
    this.setupClickOnCartWhenQuickViewIsClosed();
  }

  protected openPriceContactUs(productCode: string, quantity: number): void {
    if(this.globalConfig.enableNewContactUs && this.contactUsItemEquiryFormEnabled){
      this.contactUsService.openItemEnquiryContactUs(productCode, quantity, SupportCaseItemEnquiryType.ItemPrice);
    }
    else{
      const message = `${this.contactUsMessageRequestPrice} ${productCode}`;
      this.contactUsService.openContactUs(ContactUsTopic.RequestPrice, message);
    }
  }

  private getPriceAndAvailabilitisOnRegularIntervalForCart(): void {
    const intervalms = this.globalConfig.availabilityCacheDuration.getTotalMilliseconds();
    setInterval(() => {
      const products = this.currentPageItems?.map(i => {
        return {quantity:i.quantity,
          // When superseded from database we send in the replacement product code to P&A
          // Otherwise we send in the original product code so we load superseded info from P&A
          productCode: i.supersededFromDb && i.superseededByProductCodeDbValue ? i.superseededByProductCodeDbValue : i.product.code
        } as ProductExpectedDateRequestDto;
      });
      if(products.length > 0){
        this.productListService.getPriceAndAvailabilitiesOfProducts(products, this.currentPageItems, PriceAndAvailabilitySource.CartQuickView, this.cart?.id.toString(),false);
      }
    }, intervalms);//every 15 mins
  }

  ngOnDestroy(): void {
    this.quickviewSub?.unsubscribe();
    this.clickSub?.unsubscribe();
    this.cartSubscription?.unsubscribe();
  }

  loadCartItems(): void {
    this.isLoading = true;
    this.isError = false;
    this.currentPageItems = [];
    this.subtotal = 0;
    this.useexpectedDeliveryDateAndAvailability = this.frontEndContext.isExpectedDeliveryDateEnabled();
    this.currencyCode = this.sessionService.activeDeliverySequence?.currencyCode + ' ' || ' ';

    firstValueFrom(this.cartsController.getActive()).then(
      cart => {
        this.cart = cart;
        if (cart !== null) {
          if(this.cart?.id){

            firstValueFrom(this.cartsController.getItemsIncludingUpsellCrossell({cartId:this.cart.id, body:<PagedOptions>{
              take: 10,
              skip: 0 ,
              fetchRecent: true
            }})).then(result => {
              this.currentPageItems = this.productListService.mapFromCartItems(result.items);

              const products =this.currentPageItems.map(i => {
                return {quantity:i.quantity,
                  // When superseded from database we send in the replacement product code to P&A
                  // Otherwise we send in the original product code so we load superseded info from P&A
                  productCode: i.supersededFromDb && i.superseededByProductCodeDbValue ? i.superseededByProductCodeDbValue : i.product.code
                } as ProductExpectedDateRequestDto;
              });
              this.productListService.getPriceAndAvailabilitiesOfProducts(products, this.currentPageItems, PriceAndAvailabilitySource.CartQuickView, this.cart?.id.toString(),false);
            }).catch(err=>{
              this.isLoading = false;
              this.log.error('Error while loading cartitems - getItemsIncludingUpsellCrossell', err);
            });

            if(this.context.testPermission({ features: [MySandvikFeatures.ShopShowProductPrices] })){
              firstValueFrom(this.priceAndAvailabilityApiController.getTotalCartPriceData({cartId: this.cart?.id.toString()}))
                .then(cartTotals => {
                  if(this.sessionService.activeDeliverySequence !== null){
                    this.subtotal = cartTotals.subtotal;
                    this.isLoading = false;
                  }
                }).catch(e => {
                  this.isLoading = false;
                  this.log.error(`Error fetching total cart price`, e);
                });
            }
          }
        } else {
          this.isLoading = false;
        }
      }).catch(
      error => {
        this.isLoading = false;
        this.isError = true;
        this.log.error("Error fetching active cart", error);
      }
    );
  }


  onOpenChange(isOpen: boolean) {
    this.canSeeProductPrices = this.context.testPermission({ features: [MySandvikFeatures.ShopShowProductPrices] });
    if (isOpen && this.isOpen != isOpen) {
      this.closeOnClickOutside();
    } else {
      this.setupClickOnCartWhenQuickViewIsClosed();
    }

    this.isOpen = isOpen;
  }

  hideCartQuickView() {
    this.isOpen = false;
    this.broadcast.setCartQuickviewOpen(false);
    this.clickSub?.unsubscribe();
  }

  /**
   * For pages where quickview never opens, we need a click event listener
   * to see if the user clicks the mibp-header-cart to go to the cart
   **/
  private setupClickOnCartWhenQuickViewIsClosed(): void {
    this.clickSub?.unsubscribe();
    this.clickSub = fromEvent<MouseEvent>(window, 'mousedown')
      .pipe(
        map(event => event.target as HTMLElement),
        filter(clickedElement => clickedElement && !!clickedElement.closest('MIBP-HEADER-CART'))
      ).subscribe(() => this.gotoCart());
  }

  private closeOnClickOutside(): void {
    this.clickSub?.unsubscribe();
    this.clickSub = fromEvent<MouseEvent>(window, 'mousedown')
      .subscribe(event => {
        const clickTarget = event.target as HTMLElement;

        if (!this.cartQuickviewElement?.nativeElement?.contains(clickTarget)) {//if clicked outside of quickview popup
          if(!clickTarget.closest('.my-mobile-menu-statusbar__cart'))//skip for click on mobile cart icon
          {
            this.hideCartQuickView();
            const cartIconElement = clickTarget.closest('MIBP-HEADER-CART') as HTMLElement;
            if(cartIconElement?.tagName == "MIBP-HEADER-CART"){
              this.gotoCart();
            }
          }
        }
      });
  }

  private onDeleteCartItem(event, index){
    this.deleteIndex = index;
    //this.dialogService.prompt('Carts_ConfirmRemoveItem_Text',  'yes#Global_Dialog_Yes', 'no#Global_Dialog_No')
    this.dialogService.promptWithButtons('Carts_ConfirmRemoveItem_Text', [
      { resourceKey: 'Global_Dialog_No', id: 'no', color: ButtonColors.Secondary},
      { resourceKey: 'Global_Dialog_Yes', id: 'yes', color: ButtonColors.Primary}
    ], null, true).then(button => {
      if (button === 'yes') {
        const deletedItem = this.currentPageItems.splice(this.deleteIndex, 1);
        firstValueFrom(this.cartsController.deleteCartItem({cartId: this.cart.id.toString(), itemId:deletedItem[0].itemId.toString()}))
          .then(() => {
            this.refreshCart();
          }).catch(err => {
            this.log.error("Failed to delete item from cart.", err);
            this.isLoading = false;
          });
      }
    });
  }

  refreshCart(){
    this.broadcast.setCartEvent({refreshActiveCartCount:true});
    this.cartService.calculateSuggestedKits();
    this.cartService.duplicatePromotionProductSubject.next(null);
    this.cartService.refreshCartItems();
  }

  navigateToProductDetails(productCode: string)
  {
    this.hideCartQuickView();
    this.frontEndContext.Navigation.navigateToProductDetailsPage(productCode);
  }

  gotoCart(){
    const url = this.frontEndContext.Navigation.ensureUrlLanguage(`/shop/cart`);
    this.router.navigateByUrl(url);
  }
}
